import { __assign, __awaiter, __extends, __generator, __rest } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider, connect } from 'react-redux';
import { createHashHistory, createMemoryHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { StaticRouter } from 'react-router';
import { createFunctionProxy, createWorkerHandlerMiddleware, consumeEvents, } from './lib';
import { getAllSettings, isResponsiveEditor, } from 'shared/selectors/app-settings';
import { updateWindowSize } from 'widget/redux/client/actions/window-size';
import { WidgetPropsProvider } from 'widget/containers/widget-props';
import { setInitialAppSettings } from 'widget/redux/client/actions/app-settings';
import { withStypesWrap } from '@wix/wix-vod-shared/dist/src/common/utils/styles-processing/container';
import cssVars from 'shared/styles/core.styl';
import { subscribeGlobalStyles } from '../../../build/webpack-environments/loaders/styles/myCssLoader/runtime.js';
import { configureAxios, createClient } from 'shared/configure-axios';
import { setHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { logBi } from 'shared/worker/actions/bi';
// import { createClientStore } from './clientStore';
import { experimentsStore } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
var parseStyleParams = require('@wix/wix-vod-shared/dist/src/widget/data/style-params/parse').parseStyleParams;
function withSSRDebug(Comp) {
    if (__DEV__ &&
        typeof location !== 'undefined' &&
        location.search.toLowerCase().includes('debugssrfast')) {
        return /** @class */ (function (_super) {
            __extends(SSRWidgetComponent, _super);
            function SSRWidgetComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.state = {
                    content: '',
                };
                return _this;
            }
            SSRWidgetComponent.prototype.componentDidMount = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var res, content;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetch('https://localhost:3333/ssr', {
                                    method: 'POST',
                                    body: JSON.stringify(this.props),
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })];
                            case 1:
                                res = _a.sent();
                                return [4 /*yield*/, res.text()];
                            case 2:
                                content = _a.sent();
                                this.setState({ content: content });
                                return [2 /*return*/];
                        }
                    });
                });
            };
            SSRWidgetComponent.prototype.render = function () {
                return (React.createElement("div", { dangerouslySetInnerHTML: {
                        __html: this.state.content,
                    } }));
            };
            return SSRWidgetComponent;
        }(React.Component));
    }
    return Comp;
}
var IsomorphicRouter = /** @class */ (function (_super) {
    __extends(IsomorphicRouter, _super);
    function IsomorphicRouter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsomorphicRouter.prototype.render = function () {
        if (__SERVER__) {
            return (React.createElement(StaticRouter, { location: this.props.url }, this.props.children));
        }
        return (React.createElement(ConnectedRouter, { history: this.props.history }, this.props.children));
    };
    return IsomorphicRouter;
}(React.Component));
function createIsomorphicHistory(_a) {
    var url = _a.url;
    if (__SERVER__ || __OOI__) {
        return createMemoryHistory({ initialEntries: [url] });
    }
    else {
        return createHashHistory();
    }
}
var withErrorBoundary = function (Comp) {
    return /** @class */ (function (_super) {
        __extends(BoundaryWrapComponent, _super);
        function BoundaryWrapComponent() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = { error: false };
            return _this;
        }
        BoundaryWrapComponent.prototype.componentDidCatch = function (err, errorInfo) {
            this.props.captureException(err, { extra: { reactInfo: errorInfo } });
            this.setState({ error: true });
        };
        BoundaryWrapComponent.prototype.render = function () {
            if (this.state.error) {
                return null;
            }
            return React.createElement(Comp, __assign({}, this.props));
        };
        return BoundaryWrapComponent;
    }(React.Component));
};
export function widgetWrapper(_a) {
    var createStore = _a.createStore, Component = _a.Component;
    var ComponentWithStyles = withStypesWrap(Component, {
        subscribeGlobalStyles: subscribeGlobalStyles,
        cssVars: cssVars,
    });
    var withSettings = connect(function (state) { return ({
        styleParams: getAllSettings(state),
    }); });
    var StyledComponent = withSettings(function (_a) {
        var styleParams = _a.styleParams, props = __rest(_a, ["styleParams"]);
        var mergedProps = __assign(__assign({}, props), { host: __assign(__assign({}, props.host), { style: __assign(__assign({}, props.host.style), { styleParams: styleParams || props.style.styleParams }), isScrollExperimentOpen: true }) });
        return React.createElement(ComponentWithStyles, __assign({}, mergedProps));
    });
    var StoreWrapper = /** @class */ (function (_super) {
        __extends(StoreWrapper, _super);
        function StoreWrapper(props) {
            var _this = _super.call(this, props) || this;
            _this.logWidgetLoaded = function () {
                _this.store.dispatch(logBi('widget.loaded', {
                    channelID: '00000000-0000-0000-0000-000000000000',
                }));
            };
            var functionsProxy = (_this.functionsProxy = createFunctionProxy());
            var isBackend = props.renderingEnv === 'backend';
            _this.resolve = _.noop;
            if (isBackend) {
                _this.promise = new Promise(function (_resolve) {
                    _this.resolve = _resolve;
                });
            }
            var dispatch = function (action) {
                return props.dispatchEv(functionsProxy.serializeAction(action));
            };
            var dispatchOnPageReady = function (action) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.promise];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, dispatch(action)];
                    }
                });
            }); };
            var middlewares = [
                createWorkerHandlerMiddleware(isBackend ? dispatchOnPageReady : dispatch),
            ];
            var _a = _this.props, configData = _a.configData, appState = _a.appState, host = _a.host, experiments = _a.experiments;
            var _b = createClient(), client = _b.client, setInstance = _b.setInstance;
            _this.setInstance = setInstance;
            _this.history = createIsomorphicHistory({ url: configData.url });
            // this.store = createClientStore({
            //   appState: props.appState,
            //   functionsProxy,
            //   dispatchEv: props.dispatchEv,
            // });
            experimentsStore.set(experiments);
            _this.store = createStore({
                client: client,
                middlewares: middlewares,
                initialState: appState,
                history: _this.history,
            });
            if (__OOI__) {
                if (isBackend) {
                    _this.promise.then(_this.logWidgetLoaded);
                }
                else {
                    _this.logWidgetLoaded();
                }
            }
            _this.configureClient();
            // const style = {
            //   ...host.style,
            //   styleParams: appState.appSettings || host.style.styleParams,
            // };
            _this.store.dispatch(setInitialAppSettings(parseStyleParams(host.style, isResponsiveEditor(appState))));
            _this.updateWindowSize();
            return _this;
        }
        StoreWrapper.prototype.getDimensions = function (props) {
            return props.dimensions || props.host.dimensions;
        };
        StoreWrapper.prototype.configureClient = function () {
            configureAxios(this.props.configData);
            this.setInstance(this.props.configData.instance);
        };
        StoreWrapper.prototype.componentDidUpdate = function (prevProps) {
            if (prevProps.renderingEnv === 'backend' &&
                this.props.renderingEnv === 'browser') {
                this.resolve();
            }
            if (!_.isEqual(prevProps.configData, this.props.configData)) {
                this.configureClient();
                this.store.dispatch(setHydratedData({
                    instance: this.props.configData.instance,
                }));
            }
            if (!_.isEqual(this.getDimensions(prevProps), this.getDimensions(this.props))) {
                this.updateWindowSize();
            }
        };
        StoreWrapper.prototype.updateWindowSize = function () {
            var dimensions = this.getDimensions(this.props);
            var width = 0;
            var height = 0;
            if (dimensions) {
                width = dimensions.width || width;
                height = dimensions.height || height;
                if (typeof document !== 'undefined') {
                    width = width || document.body.clientWidth; // isFullWidth
                }
            }
            else {
                if (typeof document !== 'undefined') {
                    width = document.body.clientWidth;
                    height = document.body.clientHeight;
                }
            }
            this.store.dispatch(updateWindowSize({ width: width, height: height }));
        };
        StoreWrapper.prototype.UNSAFE_componentWillReceiveProps = function (newProps) {
            // this.store.dispatch({
            //   type: '@ORIG_DISPATCH/setState',
            //   payload: newProps.appState,
            // });
            var _this = this;
            consumeEvents(newProps, function (event) {
                _this.functionsProxy.callFunction(event);
            });
        };
        StoreWrapper.prototype.render = function () {
            if (__DEBUG__) {
                console.log('widget props', this.props);
            }
            var store = this.store;
            return (React.createElement(ReduxProvider, { store: store },
                React.createElement(WidgetPropsProvider, { value: this.props },
                    React.createElement(IsomorphicRouter, { url: "/", history: this.history },
                        React.createElement(StyledComponent, __assign({}, this.props))))));
        };
        StoreWrapper.propTypes = {
            hydratedSource: PropTypes.object,
            pubSubEvents: PropTypes.object,
            style: PropTypes.object,
        };
        return StoreWrapper;
    }(React.Component));
    return withSSRDebug(withErrorBoundary(StoreWrapper));
}
