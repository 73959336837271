import { createSelector } from 'reselect';
import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { prepareVideoCards } from '@wix/wix-vod-shared/dist/src/widget/utils/video-cards';
export var isVideoCardsEnabled = createSelector(getCurrentSiteUser, getChannelForWidget, function (state, videoItem) { return videoItem; }, function (currentSiteUser, channelData, videoItem) {
    return videoItem &&
        !isExternalVideo(videoItem) &&
        canAccessFullVideo(channelData, videoItem, currentSiteUser);
});
export var getVideoCardsList = function (state) { return state.videoCards; };
export var getWidgetVideoCards = function (_a) {
    var thumbnailSize = _a.thumbnailSize, imageHost = _a.imageHost;
    return createSelector(getVideoCardsList, getChannelForWidget, function (videoCards, channelData) {
        return prepareVideoCards({
            channelId: channelData.id,
            videoCards: videoCards,
            thumbnailSize: thumbnailSize,
            imageHost: imageHost,
        });
    });
};
