import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import ChannelCover from 'widget/containers/channel-cover/channel-cover';
import { withStyles } from 'shared/utils/withStyles';
import styles from '../overlay.styl';
var OverlayChannelCover = /** @class */ (function (_super) {
    __extends(OverlayChannelCover, _super);
    function OverlayChannelCover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OverlayChannelCover.prototype.getCoverUrl = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem;
        return getChannelCoverUrl(channelData) || getVideoCoverUrl(videoItem);
    };
    OverlayChannelCover.prototype.render = function () {
        var _a = this.props, width = _a.width, height = _a.height, className = _a.className, channelData = _a.channelData;
        return (React.createElement("div", { className: classnames(styles.cover, className) },
            React.createElement(ChannelCover, { posterUrl: this.getCoverUrl(), mediaInfo: channelData.mediaInfo, channelId: channelData.id, channelTitle: channelData.title, showChannelCover: true, width: width, height: height })));
    };
    OverlayChannelCover.propTypes = {
        channelData: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        videoItem: PropTypes.object,
        className: PropTypes.string,
        withFallbackColor: PropTypes.bool,
    };
    OverlayChannelCover.defaultProps = {
        withFallbackColor: false,
    };
    OverlayChannelCover = __decorate([
        withStyles(styles)
    ], OverlayChannelCover);
    return OverlayChannelCover;
}(React.Component));
export default OverlayChannelCover;
