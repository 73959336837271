import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getAvailableVideoType, getFullAccessActionName, canPlayFullOrPreview, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import getTranslationData from './translation-data';
import { getPlayButtonHandler } from 'shared/utils/get-play-button-handler';
export var createGetPlayButtonConfig = function () {
    return createSelector(getChannelForWidget, getCurrentSiteUser, function (state, ownProps) { return ownProps.videoItem; }, function (state, ownProps) { return ownProps.onPlayRequest; }, function (state, ownProps) { return ownProps.onPlayMemberOnlyRequest; }, function (channel, user, video, onPlayRequest, onPlayMemberOnlyRequest) {
        var availableVideoType = getAvailableVideoType(channel, video, user);
        var fullAccessAction = getFullAccessActionName(channel, video, user);
        var callback = getPlayButtonHandler(onPlayRequest, onPlayMemberOnlyRequest, canPlayFullOrPreview(channel, video, user), fullAccessAction);
        var translationData = getTranslationData(availableVideoType, fullAccessAction);
        if (translationData) {
            return {
                callback: callback,
                translationData: translationData,
            };
        }
        return null;
    });
};
