import { __assign, __extends } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WixSDK from 'shared/utils/wix-sdk';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { updateWindowSize } from 'widget/redux/client/actions/window-size';
export default (function (Target) {
    var mapStateToProps = function (state) { return ({
        isEditor: viewModeSelectors.isEditorMode(state),
        windowSize: state.windowSize,
    }); };
    var WithApplyForcedHeight = /** @class */ (function (_super) {
        __extends(WithApplyForcedHeight, _super);
        function WithApplyForcedHeight() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        WithApplyForcedHeight.prototype.componentDidMount = function () {
            this.setHeight();
        };
        WithApplyForcedHeight.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
            if (nextProps.forcedHeight &&
                nextProps.forcedHeight !== this.props.forcedHeight) {
                this.setHeight(nextProps.forcedHeight);
            }
        };
        WithApplyForcedHeight.prototype.setHeight = function (forcedHeight) {
            if (forcedHeight === void 0) { forcedHeight = this.props.forcedHeight; }
            var _a = this.props, isEditor = _a.isEditor, updateWindowSize = _a.updateWindowSize, windowSize = _a.windowSize;
            if (isEditor && forcedHeight) {
                updateWindowSize({ height: forcedHeight, width: windowSize.width });
                WixSDK.setHeight(forcedHeight);
            }
        };
        WithApplyForcedHeight.prototype.render = function () {
            return this.props.forcedHeight ? null : React.createElement(Target, __assign({}, this.props));
        };
        WithApplyForcedHeight.propTypes = {
            forcedHeight: PropTypes.number,
            isEditor: PropTypes.bool.isRequired,
        };
        return WithApplyForcedHeight;
    }(React.Component));
    return connect(mapStateToProps, { setWidgetHeight: setWidgetHeight, updateWindowSize: updateWindowSize })(WithApplyForcedHeight);
});
