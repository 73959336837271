import { getAuthHeader } from '@wix/wix-vod-shared/dist/src/common/utils/get-auth-header';
import { hosts } from '@wix/wix-vod-shared/dist/src/common/settings/settings';
export var catalogServiceConfig = {
    baseURL: hosts.catalog,
    withCredentials: true,
    xsrfHeaderName: null,
    xsrfCookieName: null,
    getHeaders: getAuthHeader,
};
export var publicCatalogServiceConfig = {
    baseURL: hosts.publicCatalog,
    withCredentials: true,
    getHeaders: getAuthHeader,
};
