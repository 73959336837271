import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { DEFAULT_TRANSLATIONS as CHANNEL_TRANSLATIONS } from 'shared/components/overlay-texts/channel/play-button-text';
export default function getTranslationData(availableVideoType, fullAccessAction) {
    if (availableVideoType) {
        return {
            props: CHANNEL_TRANSLATIONS[availableVideoType],
        };
    }
    else if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
        return {
            props: 'widget.overlay-buttons.start-watching',
        };
    }
    return {
        props: 'widget.overlay-buttons.start-watching',
    };
}
