import { __assign, __extends } from "tslib";
import React from 'react';
import getDisplayName from 'shared/utils/get-component-display-name';
export default function decorate(Wrapper) {
    return function (DecoratedComponent) {
        var _a;
        return _a = /** @class */ (function (_super) {
                __extends(class_1, _super);
                function class_1() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                class_1.prototype.render = function () {
                    return (React.createElement(Wrapper, __assign({}, this.props),
                        React.createElement(DecoratedComponent, __assign({}, this.props))));
                };
                return class_1;
            }(React.Component)),
            _a.displayName = getDisplayName(Wrapper) + "(" + getDisplayName(DecoratedComponent) + ")",
            _a;
    };
}
