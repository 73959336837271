import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/close';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import Input from '@wix/wix-vod-shared/dist/src/widget/ui-components/input/input';
import { withStyles } from 'shared/utils/withStyles';
import styles from './custom-size.styl';
var CustomSize = /** @class */ (function (_super) {
    __extends(CustomSize, _super);
    function CustomSize(props) {
        var _this = _super.call(this, props) || this;
        _this.onCustomWidthChanged = function (e) {
            _this.setState({
                width: e.target.value,
            });
        };
        _this.onCustomHeightChanged = function (e) {
            _this.setState({
                height: e.target.value,
            });
        };
        _this.onWidthBlur = function () {
            if (!_this.state.width) {
                return;
            }
            var width = Number(_this.state.width);
            if (width !== _this.props.width && !_.isNaN(width)) {
                _this.props.onWidthBlur(width);
            }
        };
        _this.onHeightBlur = function () {
            if (!_this.state.height) {
                return;
            }
            var height = Number(_this.state.height);
            if (height !== _this.props.height && !_.isNaN(height)) {
                _this.props.onHeightBlur(height);
            }
        };
        _this.onSubmit = function (e) {
            e.preventDefault();
            _this.onWidthBlur();
            _this.onHeightBlur();
        };
        var width = props.width, height = props.height;
        _this.state = {
            width: width,
            height: height,
        };
        return _this;
    }
    CustomSize.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var _a = this.state, width = _a.width, height = _a.height;
        if (String(nextProps.width) !== width) {
            this.setState({ width: nextProps.width });
        }
        if (String(nextProps.height) !== height) {
            this.setState({ height: nextProps.height });
        }
    };
    CustomSize.prototype.render = function () {
        var _a;
        var _b = this.props, dataHook = _b.dataHook, onFocus = _b.onFocus, inactive = _b.inactive;
        var _c = this.state, width = _c.width, height = _c.height;
        var inputClassList = classNames(styles['text-input'], (_a = {},
            _a[styles.inactive] = inactive,
            _a));
        return (React.createElement("form", { "data-hook": dataHook, className: styles['custom-sizes'], onSubmit: this.onSubmit },
            React.createElement(Input, { className: inputClassList, dataHook: "custom-width", tabIndex: "0", value: width, onChange: this.onCustomWidthChanged, onFocus: onFocus, onBlur: this.onWidthBlur, ariaLabel: i18n.t('widget.accessibility.widget-width', { width: width }) }),
            React.createElement(CloseIcon, { className: styles.separator }),
            React.createElement(Input, { className: inputClassList, dataHook: "custom-height", tabIndex: "0", value: height, onChange: this.onCustomHeightChanged, onFocus: onFocus, onBlur: this.onHeightBlur, ariaLabel: i18n.t('widget.accessibility.widget-height', { height: height }) }),
            React.createElement("input", { type: "submit", className: styles.submit })));
    };
    CustomSize.propTypes = {
        dataHook: PropTypes.string,
        className: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onWidthBlur: PropTypes.func,
        onHeightBlur: PropTypes.func,
        onFocus: PropTypes.func,
        inactive: PropTypes.bool,
    };
    CustomSize = __decorate([
        withStyles(styles)
    ], CustomSize);
    return CustomSize;
}(React.Component));
export default CustomSize;
