import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import pubsub from 'shared/utils/pubsub';
import HeaderMobile from './header/header-mobile';
import { PrimaryButton, SecondaryButton, } from 'shared/components/button/button';
import PropTypes from 'prop-types';
import FixedInIFrame from 'mobile-overlay/components/fixed-in-iframe/fixed-in-iframe';
import events from 'shared/constants/events';
import { withStyles } from 'shared/utils/withStyles';
import styles from './modal.styl';
import stylesMobile from './modal-mobile.styl';
import { DISMISSED } from 'shared/components/modal/utils';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { previewPrompt } from 'shared/components/preview-prompt/preview-prompt.content';
var modalContentTypeToContentMap = __assign({}, previewPrompt);
var closeOnOverlayClick = true;
function injectButtonComponentsIntoConfig(config, buttonComponents) {
    var buttons = config.buttons;
    buttons = _.mapValues(buttons, function (value, key) {
        if (value.Button) {
            return value;
        }
        return __assign(__assign({}, value), { Button: buttonComponents[key] });
    });
    return __assign(__assign({}, config), { buttons: buttons });
}
var MobileModal = /** @class */ (function (_super) {
    __extends(MobileModal, _super);
    function MobileModal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            open: false,
            closeOnOverlayClick: closeOnOverlayClick,
            title: null,
            closeButtonLabel: null,
            content: null,
        };
        _this.saveRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.openModal = function (config) {
            if (config.compId !== _this.props.compId) {
                return;
            }
            config = injectButtonComponentsIntoConfig(config, {
                primary: PrimaryButton,
                secondary: SecondaryButton,
            });
            if (config.contentType) {
                config.content = modalContentTypeToContentMap[config.contentType];
            }
            _this.setState(__assign({ Header: HeaderMobile, title: null, closeButtonLabel: null, content: null, closeOnOverlayClick: closeOnOverlayClick, className: stylesMobile['modal-mobile'], windowClassName: stylesMobile['modal-mobile-window'], contentClassName: stylesMobile['modal-mobile-content'], buttonsClassName: '', buttons: {}, resolve: _.noop, reject: _.noop, noCloseButton: false }, config));
            setTimeout(function () {
                _this.setState({
                    open: true,
                });
            }, 0);
        };
        _this.closeModal = function () {
            return new Promise(function (resolve) {
                _this.setState({ open: false }, function () {
                    setTimeout(function () {
                        _this.setState({ content: null }, resolve);
                    }, 200);
                });
            });
        };
        _this.handleBackdropClick = function (event) {
            var closeOnOverlayClick = _this.state.closeOnOverlayClick;
            if (!event.isDefaultPrevented() && closeOnOverlayClick) {
                _this.$modal.dismiss();
            }
        };
        _this.handleCloseButtonClick = function () {
            _this.$modal.dismiss();
        };
        _this.containerRef = null;
        return _this;
    }
    MobileModal.prototype.UNSAFE_componentWillMount = function () {
        this.openModalUnsubscribe = pubsub.subscribe(events.MODAL.OPEN, this.openModal);
        this.closeModalUnsubscribe = pubsub.subscribe(events.MODAL.CLOSE, this.closeModal);
    };
    MobileModal.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.state.open && !prevState.open) {
            // useSafeFocus
            var containerRefHeight = this.containerRef.style.height;
            this.containerRef.style.height = 0;
            this.containerRef.focus();
            this.containerRef.style.height = containerRefHeight;
        }
    };
    MobileModal.prototype.componentWillUnmount = function () {
        this.openModalUnsubscribe();
        this.closeModalUnsubscribe();
    };
    MobileModal.prototype.stopEventPropagation = function (event) {
        event.preventDefault();
    };
    Object.defineProperty(MobileModal.prototype, "$modal", {
        get: function () {
            var _this = this;
            var _a = this.state, resolve = _a.resolve, reject = _a.reject;
            return {
                resolve: function (data) {
                    resolve({ data: data, $modal: _this.$modal });
                },
                reject: function (reason) {
                    if (reason === void 0) { reason = DISMISSED; }
                    reject(reason);
                },
                close: function (data) {
                    _this.closeModal().then(function () { return resolve(data); });
                },
                dismiss: function (reason) {
                    if (reason === void 0) { reason = DISMISSED; }
                    _this.closeModal().then(function () { return reject(reason); });
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileModal.prototype, "buttons", {
        get: function () {
            var _this = this;
            var _a = this.state, buttons = _a.buttons, buttonsClassName = _a.buttonsClassName;
            if (!_.keys(buttons).length) {
                return null;
            }
            var keyToDataHookMap = {
                primary: 'confirmation-button',
                secondary: 'cancellation-button',
            };
            return (React.createElement("footer", { className: classnames(buttonsClassName, styles.buttons) }, _.map(buttons, function (_a, key) {
                var Button = _a.Button, label = _a.label, onClick = _a.onClick;
                return (React.createElement(Button, { key: key, dataHook: keyToDataHookMap[key], label: label, type: "button", onClick: memoizedPartial(onClick, _this.$modal), className: styles.button }));
            })));
        },
        enumerable: true,
        configurable: true
    });
    MobileModal.prototype.render = function () {
        var _a, _b;
        var _c = this.state, open = _c.open, content = _c.content, Header = _c.Header, title = _c.title, closeButtonLabel = _c.closeButtonLabel, className = _c.className, windowClassName = _c.windowClassName, contentClassName = _c.contentClassName, top = _c.top;
        if (!open && !content) {
            return null;
        }
        var rootClassName = classnames(className, styles.modal, (_a = {},
            _a[styles.open] = open && content,
            _a));
        return (React.createElement(FixedInIFrame, { portal: false, className: stylesMobile['fixed-iframe'] },
            React.createElement("div", { className: rootClassName, onClick: this.handleBackdropClick, role: "presentation" },
                React.createElement("section", { "data-hook": "modal-window", className: classnames(windowClassName, styles.window, (_b = {},
                        _b[styles['window-positioned']] = _.isNumber(top),
                        _b)), style: { top: top }, role: "dialog", "aria-modal": "true", "aria-label": title, tabIndex: -1, ref: this.saveRef, onClick: this.stopEventPropagation },
                    Header && (React.createElement(Header, { title: title, closeButtonLabel: closeButtonLabel, onButtonClick: this.handleCloseButtonClick, role: "presentation" })),
                    React.createElement("div", { "data-hook": "modal-content", className: classnames(contentClassName, styles.content), role: "presentation" }, _.isString(content)
                        ? content
                        : React.cloneElement(content, { $modal: this.$modal })),
                    this.buttons))));
    };
    MobileModal.displayName = 'Modal';
    MobileModal.propTypes = {
        title: PropTypes.node,
        closeButtonLabel: PropTypes.string,
        content: PropTypes.element,
        className: PropTypes.string,
        windowClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        buttonsClassName: PropTypes.string,
        closeOnOverlayClick: PropTypes.bool,
    };
    MobileModal.defaultProps = {
        closeOnOverlayClick: closeOnOverlayClick,
    };
    MobileModal = __decorate([
        connect(function (state) { return ({ compId: getCompId(state) }); }),
        withStyles(styles),
        withStyles(stylesMobile)
    ], MobileModal);
    return MobileModal;
}(React.Component));
export { MobileModal };
