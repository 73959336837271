import { __decorate, __extends } from "tslib";
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { canShowFullInfo } from 'shared/selectors/app-settings';
import { canShareVideo } from 'shared/selectors/video-share';
var PlayerBlocksVisibility = /** @class */ (function (_super) {
    __extends(PlayerBlocksVisibility, _super);
    function PlayerBlocksVisibility() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlayerBlocksVisibility.prototype.render = function () {
        var _a = this.props, canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton, children = _a.children;
        return children({
            canShowVideoInfoButton: canShowVideoInfoButton,
            canShowShareButton: canShowShareButton,
        });
    };
    PlayerBlocksVisibility.propTypes = {
        canShowVideoInfoButton: PropTypes.bool.isRequired,
        canShowShareButton: PropTypes.bool.isRequired,
        children: PropTypes.func.isRequired,
    };
    PlayerBlocksVisibility = __decorate([
        connect(function (state) { return ({
            canShowVideoInfoButton: canShowFullInfo(state),
            canShowShareButton: canShareVideo(state),
        }); })
    ], PlayerBlocksVisibility);
    return PlayerBlocksVisibility;
}(React.Component));
export default PlayerBlocksVisibility;
