import _ from 'lodash';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { demoChannel, demoChannelEditor } from 'shared/constants/demo-channel';
export function isVODChannel(channelData) {
    return channelData.channelType === CHANNEL_TYPES.CHANNEL;
}
export function isYouTubeFeed(channelData) {
    return channelData.channelType === CHANNEL_TYPES.YOUTUBE_FEED;
}
export function hasTags(channelData) {
    var tags = _.get(channelData, 'statsInfo.tags');
    return !_.isEmpty(tags);
}
export function hasVideos(channelData) {
    return channelData.videosCount > 0;
}
export function isDemoChannel(channelId) {
    return _.includes([demoChannel.id, demoChannelEditor.id], channelId);
}
