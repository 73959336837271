import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getAllSettings } from 'shared/selectors/app-settings';
import { canShowChannelCover } from 'widget/components/player-overlay/selectors';
import { notForPreview } from 'shared/utils/not-for-preview';
import { openFullScreenPurchaseOverlay } from 'widget/utils/open-overlay';
import { logPurchaseButtonClicked } from 'widget/components/player-overlay/bi';
import { createProxy } from 'shared/worker/lib';
var OPEN_PURCHASE = 'CLIENT.PLAYER_OVERLAY.OPEN_PURCHASE';
var openPurchaseAction = createAction(OPEN_PURCHASE);
export var openPurchase = createProxy('openPurchase', function (videoItem, showChannelCover) { return function (dispatch, getState) {
    var state = getState();
    var channelData = getChannelForWidget(state);
    var appSettings = getAllSettings(state);
    var isChannelCover = canShowChannelCover(state, { showChannelCover: showChannelCover });
    notForPreview(function () {
        dispatch(logPurchaseButtonClicked({
            appSettings: appSettings,
            videoItem: videoItem,
            channelData: channelData,
            isChannelCover: isChannelCover,
        }));
        dispatch(openFullScreenPurchaseOverlay(channelData.id, videoItem.id));
    })();
    dispatch(openPurchaseAction());
}; });
