import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import getScrollParent from 'shared/utils/get-scroll-parent';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { VODCSSModules } from 'shared/utils/wix-connect';
import invertSide from 'shared/utils/popout/invert-side';
import { byTriangleSide } from 'shared/utils/popout/calculate-position';
import { sides, popoutPositions } from 'shared/constants/popout';
import dropdownOptionsShape from 'shared/shapes/dropdown-options';
import Icon from 'shared/components/icon/icon';
import Popout, { showPopout } from 'shared/components/popout/popout';
import { WidgetScrollbarWrapper } from 'shared/components/scrollbar-wrapper/scrollbar-wrapper';
import KEY_CODES from '@wix/wix-vod-shared/dist/src/widget/constants/key-codes';
import { withStyles } from 'shared/utils/withStyles';
import styles from './dropdown.styl';
var Option = /** @class */ (function (_super) {
    __extends(Option, _super);
    function Option() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Option.prototype.render = function () {
        var _a = this.props, selected = _a.selected, className = _a.className, label = _a.label, height = _a.height, containerRef = _a.containerRef, onClick = _a.onClick, onKeyDown = _a.onKeyDown, isFocusable = _a.isFocusable;
        var classNames = classnames(styles.option, className, {
            selected: selected,
        });
        return (React.createElement("section", { className: classNames, ref: containerRef, "data-hook": "dropdown-option", title: label, onClick: onClick, onKeyDown: onKeyDown, style: { height: height }, role: "option", tabIndex: isFocusable ? 0 : -1 }, label));
    };
    Option.propTypes = {
        className: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        label: PropTypes.node.isRequired,
        value: PropTypes.any.isRequired,
        selected: PropTypes.bool.isRequired,
        isFocusable: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        containerRef: PropTypes.func,
    };
    return Option;
}(React.Component));
var BaseDropdown = /** @class */ (function (_super) {
    __extends(BaseDropdown, _super);
    function BaseDropdown() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isOpen: false,
            isPopoutHidden: true,
        };
        _this.wrapperRef = null;
        _this.selectedOptionRef = null;
        _this.triangleRef = null;
        _this.popoutRef = null;
        _this.saveRef = function (name, ref) {
            _this[name] = ref;
        };
        _this.close = function () {
            if (_this.state.isOpen) {
                _this.setOpenState(false);
            }
        };
        _this.toggle = function (event) {
            event.nativeEvent.stopImmediatePropagation();
            _this.setOpenState(!_this.state.isOpen);
        };
        _this.closeAndCallOnChange = function (value) {
            var onChange = _this.props.onChange;
            var didValueChange = value !== _this.props.value;
            _this.setOpenState(false);
            if (didValueChange) {
                onChange(value);
            }
        };
        _this.handleWrapperKeyDown = function (event) {
            _this.openFromKeyboard(event);
        };
        _this.handleOptionKeyDown = function (value, event) {
            _this.navigateByOptionsFromKeyboard(event);
            _this.selectOptionFromKeyboard(event, value);
            _this.closeFromKeyboard(event);
        };
        return _this;
    }
    BaseDropdown.prototype.componentDidUpdate = function () {
        this.updatePopoutPosition();
    };
    BaseDropdown.prototype.componentWillUnmount = function () {
        clearTimeout(this.timeout);
    };
    BaseDropdown.prototype.calculatePosition = function () {
        var _a = this.props, triangleSide = _a.popoutTriangleSide, popoutPosition = _a.popoutPosition;
        return byTriangleSide(this.triangleRef, this.popoutRef, {
            triangleSide: triangleSide,
            popoutPosition: popoutPosition,
        });
    };
    BaseDropdown.prototype.updatePopoutPosition = function () {
        if (!this.props.enablePopoutPositioningUpdate) {
            return;
        }
        var wrapperRect = this.wrapperRef.getBoundingClientRect();
        // getting scroll parent twice, becase first is not enough
        var scrollLeft = getScrollParent(getScrollParent(this.wrapperRef)).scrollLeft;
        var _a = this.calculatePosition(), left = _a.left, top = _a.top, offsetX = _a.offsetX;
        left -= scrollLeft + wrapperRect.left - offsetX;
        top -= wrapperRect.top;
        this.popoutRef.style.left = left + "px";
        this.popoutRef.style.top = top + "px";
    };
    BaseDropdown.prototype.setOpenState = function (isOpen) {
        var _this = this;
        if (isOpen) {
            this.setState({
                isOpen: isOpen,
                isPopoutHidden: false,
            });
            this.props.onOpen();
            showPopout(this.popoutRef);
        }
        else {
            this.setState({
                isOpen: isOpen,
            });
            this.timeout = setTimeout(function () {
                _this.setState({
                    isPopoutHidden: true,
                });
            }, 200);
        }
    };
    BaseDropdown.prototype.openFromKeyboard = function (event) {
        var _this = this;
        if (_.includes([KEY_CODES.DOWN, KEY_CODES.ENTER, KEY_CODES.SPACE], event.keyCode)) {
            event.stopPropagation();
            this.setOpenState(true);
            if (this.selectedOptionRef) {
                _.delay(function () { return _this.selectedOptionRef.focus(); });
            }
        }
    };
    BaseDropdown.prototype.closeFromKeyboard = function (event) {
        var _this = this;
        if (event.keyCode === KEY_CODES.ESC) {
            event.stopPropagation();
            this.close();
            if (this.wrapperRef) {
                _.delay(function () { return _this.wrapperRef.focus(); });
            }
        }
        if (event.keyCode === KEY_CODES.TAB) {
            event.stopPropagation();
            this.close();
        }
    };
    BaseDropdown.prototype.navigateByOptionsFromKeyboard = function (event) {
        if (event.keyCode === KEY_CODES.DOWN && event.target.nextElementSibling) {
            event.stopPropagation();
            event.target.nextElementSibling.focus();
        }
        if (event.keyCode === KEY_CODES.UP && event.target.previousElementSibling) {
            event.stopPropagation();
            event.target.previousElementSibling.focus();
        }
    };
    BaseDropdown.prototype.selectOptionFromKeyboard = function (event, value) {
        var _this = this;
        if (_.includes([KEY_CODES.ENTER, KEY_CODES.SPACE], event.keyCode)) {
            event.stopPropagation();
            this.closeAndCallOnChange(value);
            if (this.wrapperRef) {
                _.delay(function () { return _this.wrapperRef.focus(); });
            }
        }
    };
    BaseDropdown.prototype.renderSelectedValue = function () {
        var _a = this.props, options = _a.options, value = _a.value, placeholder = _a.placeholder;
        var selectedOption = _.find(options, function (option) {
            return _.isEqual(option.value, value);
        });
        if (selectedOption) {
            return (React.createElement("span", { className: styles.value, title: selectedOption.label }, selectedOption.label));
        }
        return (React.createElement("span", { className: styles.value },
            React.createElement("span", { className: styles.placeholder }, placeholder)));
    };
    BaseDropdown.prototype.renderOptions = function () {
        var _this = this;
        var _a = this.props, options = _a.options, value = _a.value, height = _a.height, optionClassName = _a.optionClassName, selectedOptionClassName = _a.selectedOptionClassName, maxOptionsVisible = _a.maxOptionsVisible, ScrollbarWrapper = _a.ScrollbarWrapper;
        var renderedOptions = _.map(options, function (option, i) {
            var _a;
            if (option.categoryLabel) {
                return (React.createElement("div", { key: "category-" + option.categoryLabel, className: styles.category }, option.categoryLabel));
            }
            var isSelected = _.isEqual(value, option.value);
            var classNames = classnames(optionClassName, (_a = {},
                _a[selectedOptionClassName] = selectedOptionClassName && isSelected,
                _a));
            return (React.createElement(Option, { key: i, label: option.label, value: option.value, selected: isSelected, className: classNames, height: height, containerRef: isSelected
                    ? memoizedPartial(_this.saveRef, 'selectedOptionRef')
                    : null, isFocusable: _this.state.isOpen, onKeyDown: memoizedPartial(_this.handleOptionKeyDown, option.value), onClick: memoizedPartial(_this.closeAndCallOnChange, option.value) }));
        });
        if (options.length > maxOptionsVisible) {
            return React.createElement(ScrollbarWrapper, null, renderedOptions);
        }
        return renderedOptions;
    };
    BaseDropdown.prototype.render = function () {
        var _a, _b, _c;
        var _d = this.props, rounded = _d.rounded, disabled = _d.disabled, popoutTriangleSide = _d.popoutTriangleSide, popoutPosition = _d.popoutPosition, options = _d.options, className = _d.className, optionsClassName = _d.optionsClassName, labelClassName = _d.labelClassName, triangleClassName = _d.triangleClassName, height = _d.height, maxOptionsVisible = _d.maxOptionsVisible, enablePopoutPositioningUpdate = _d.enablePopoutPositioningUpdate, dataHook = _d.dataHook, ariaLabel = _d.ariaLabel, isFocusable = _d.isFocusable;
        var _e = this.state, isOpen = _e.isOpen, isPopoutHidden = _e.isPopoutHidden;
        var classNames = classnames(styles.dropdown, className, (_a = {},
            _a[styles.open] = isOpen,
            _a[styles['hide-popout']] = isPopoutHidden,
            _a[styles.rounded] = rounded,
            _a[styles.disabled] = disabled,
            _a));
        var popoutHeight = options.length > maxOptionsVisible ? height * maxOptionsVisible : 0;
        var stickClassNames = enablePopoutPositioningUpdate
            ? {}
            : (_b = {},
                _b[styles['stick-to-left']] = popoutPosition === popoutPositions.LEFT,
                _b[styles['stick-to-center']] = popoutPosition === popoutPositions.CENTER,
                _b[styles['stick-to-right']] = popoutPosition === popoutPositions.RIGHT,
                _b[styles['stick-to-bottom']] = popoutPosition === popoutPositions.TOP,
                _b);
        var optionsClassNames = classnames(styles.options, optionsClassName, __assign((_c = {}, _c[styles.single] = options.length === 1, _c), stickClassNames));
        var tabIndex = !isFocusable || isOpen || !isPopoutHidden ? -1 : 0;
        return (React.createElement("section", { ref: memoizedPartial(this.saveRef, 'wrapperRef'), className: classNames, "data-hook": dataHook, onKeyDown: this.handleWrapperKeyDown, style: { height: height }, "aria-label": isPopoutHidden ? ariaLabel : null, "aria-hidden": !isOpen && !isPopoutHidden ? true : null, role: "listbox", tabIndex: tabIndex },
            React.createElement("div", { className: classnames(styles.label, labelClassName), "data-hook": "dropdown-label", style: { height: height }, onClick: this.toggle },
                this.renderSelectedValue(),
                React.createElement(Icon, { getRef: memoizedPartial(this.saveRef, 'triangleRef'), className: classnames(styles['triangle-down'], triangleClassName), name: "triangle-down" })),
            React.createElement(Popout, { getRef: memoizedPartial(this.saveRef, 'popoutRef'), className: optionsClassNames, innerClassName: styles['options-inner'], popoutSide: invertSide(popoutTriangleSide), height: popoutHeight, isActive: isOpen && !isPopoutHidden, onClickOutside: this.close }, this.renderOptions())));
    };
    BaseDropdown.propTypes = {
        value: PropTypes.any,
        options: dropdownOptionsShape,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        onOpen: PropTypes.func,
        rounded: PropTypes.bool,
        className: PropTypes.string,
        dataHook: PropTypes.string,
        ariaLabel: PropTypes.string,
        labelClassName: PropTypes.string,
        triangleClassName: PropTypes.string,
        optionsClassName: PropTypes.string,
        optionClassName: PropTypes.string,
        selectedOptionClassName: PropTypes.string,
        popoutTriangleSide: PropTypes.string,
        popoutPosition: PropTypes.string,
        height: PropTypes.number,
        disabled: PropTypes.bool,
        isFocusable: PropTypes.bool,
        maxOptionsVisible: PropTypes.number,
        enablePopoutPositioningUpdate: PropTypes.bool,
        ScrollbarWrapper: PropTypes.oneOf([WidgetScrollbarWrapper]),
    };
    BaseDropdown.defaultProps = {
        height: 32,
        maxOptionsVisible: 5,
        onChange: _.noop,
        onOpen: _.noop,
        rounded: false,
        disabled: false,
        isFocusable: true,
        popoutTriangleSide: sides.NONE,
        popoutPosition: popoutPositions.LEFT,
        enablePopoutPositioningUpdate: false,
        ScrollbarWrapper: WidgetScrollbarWrapper,
    };
    BaseDropdown = __decorate([
        withStyles(styles),
        VODCSSModules(styles)
    ], BaseDropdown);
    return BaseDropdown;
}(React.Component));
var WidgetDropdown = /** @class */ (function (_super) {
    __extends(WidgetDropdown, _super);
    function WidgetDropdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WidgetDropdown.prototype.render = function () {
        var props = _.omit(this.props, 'styles');
        return (React.createElement(BaseDropdown, __assign({}, props, { className: classnames(styles.widget, props.className), ScrollbarWrapper: WidgetScrollbarWrapper })));
    };
    WidgetDropdown = __decorate([
        VODCSSModules(styles)
    ], WidgetDropdown);
    return WidgetDropdown;
}(React.Component));
export { WidgetDropdown };
var StripWidgetDropdown = /** @class */ (function (_super) {
    __extends(StripWidgetDropdown, _super);
    function StripWidgetDropdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StripWidgetDropdown.prototype.render = function () {
        var props = _.omit(this.props, 'styles');
        return (React.createElement(BaseDropdown, __assign({}, props, { styleName: "widget strip", ScrollbarWrapper: WidgetScrollbarWrapper })));
    };
    StripWidgetDropdown = __decorate([
        VODCSSModules(styles)
    ], StripWidgetDropdown);
    return StripWidgetDropdown;
}(React.Component));
export { StripWidgetDropdown };
var BareDropdown = /** @class */ (function (_super) {
    __extends(BareDropdown, _super);
    function BareDropdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BareDropdown.prototype.render = function () {
        var props = _.omit(this.props, 'styles');
        return (React.createElement(BaseDropdown, __assign({}, props, { className: classnames(styles.bare, props.className), enablePopoutPositioningUpdate: true, popoutPosition: popoutPositions.CENTER, popoutTriangleSide: sides.TOP })));
    };
    BareDropdown = __decorate([
        VODCSSModules(styles)
    ], BareDropdown);
    return BareDropdown;
}(React.Component));
export { BareDropdown };
