import { createAction } from 'redux-actions';
import SEARCH_QUERY_FIELDS from 'shared/constants/search-query-fields';
export var SEARCH_ACTIONS = {
    UPDATE_SEARCH: 'CLIENT.SEARCH.UPDATE_SEARCH',
    RESET_SEARCH: 'CLIENT.SEARCH.RESET_SEARCH',
};
export var updateSearch = createAction(SEARCH_ACTIONS.UPDATE_SEARCH);
export var resetSearch = createAction(SEARCH_ACTIONS.RESET_SEARCH);
export var searchByString = function (value) {
    return updateSearch({
        searchQuery: value,
        searchQueryField: SEARCH_QUERY_FIELDS.QUERY,
    });
};
export var searchByTag = function (value) {
    return updateSearch({
        searchQuery: value,
        searchQueryField: SEARCH_QUERY_FIELDS.TAG,
    });
};
export var searchByCategory = function (value) {
    return updateSearch({
        searchQuery: value,
        searchQueryField: SEARCH_QUERY_FIELDS.CATEGORY,
    });
};
