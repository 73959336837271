import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getAllSettings, isRTL } from 'shared/selectors/app-settings';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import ContentSlider from '@wix/wix-vod-shared/dist/src/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/src/widget/ui-components/page-list/page-list';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import VideoListItem from 'widget/layouts/strip/components/videos/_video-list-item/video-list-item';
import { withStyles } from 'shared/utils/withStyles';
import styles from './videos.styl';
import { MAX_WIDGET_WIDTH } from 'widget/utils/videos-sizes/videos-sizes';
var Videos = /** @class */ (function (_super) {
    __extends(Videos, _super);
    function Videos() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderVideoItem = function (videoId, videoAtPageIndex, isVisiblePage) {
            var _a = _this.props, channelData = _a.channelData, videoByIds = _a.videoByIds, thumbnailSize = _a.thumbnailSize, appSettings = _a.appSettings, onThumbnailClick = _a.onThumbnailClick, onPlayRequest = _a.onPlayRequest, currentSiteUser = _a.currentSiteUser;
            return (React.createElement(VideoListItem, __assign({}, thumbnailSize, { channelData: channelData, videoItem: videoByIds[videoId], appSettings: appSettings, currentSiteUser: currentSiteUser, key: videoId, isFocusable: isVisiblePage, onClick: onThumbnailClick, onPlayRequest: onPlayRequest })));
        };
        return _this;
    }
    Videos.prototype.componentDidMount = function () {
        this.props.logWidgetSystem('videoList.show.rendered', {
            previousEventName: 'videoList.show.requested',
        });
    };
    Videos.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var currentVideosPageNumber = _a.currentVideosPageNumber;
        if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
            this.props.logWidgetSystem('videoList.changePage.rendered', {
                previousEventName: 'videoList.changePage.requested',
            });
        }
    };
    Videos.prototype.componentDidUpdate = function () {
        this.props.logWidgetSystem('videoList.searchByQuery.rendered', {
            previousEventName: 'videoList.searchByQuery.requested',
        });
        this.props.logWidgetSystem('videoList.searchByTag.rendered', {
            previousEventName: 'videoList.searchByTag.requested',
        });
        this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
            previousEventName: 'videoList.searchByCategory.requested',
        });
    };
    Videos.prototype.hasPage = function (pageNum) {
        var videoIdsByPageNumber = this.props.videoIdsByPageNumber;
        return Boolean(videoIdsByPageNumber[pageNum]);
    };
    Object.defineProperty(Videos.prototype, "isPrevPageVisible", {
        get: function () {
            var currentVideosPageNumber = this.props.currentVideosPageNumber;
            return this.hasPage(currentVideosPageNumber - 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Videos.prototype, "isNextPageVisible", {
        get: function () {
            var currentVideosPageNumber = this.props.currentVideosPageNumber;
            return this.hasPage(currentVideosPageNumber + 1);
        },
        enumerable: true,
        configurable: true
    });
    Videos.prototype.render = function () {
        var _a = this.props, currentVideosPageNumber = _a.currentVideosPageNumber, videoIdsByPageNumber = _a.videoIdsByPageNumber, containerWidth = _a.containerWidth, onSlideToPrev = _a.onSlideToPrev, onSlideToNext = _a.onSlideToNext, isRTL = _a.isRTL, numberOfRows = _a.numberOfRows, numberOfColumns = _a.numberOfColumns, styleId = _a.styleId;
        return (React.createElement("div", { styleName: "container" },
            React.createElement(ContentSlider, { width: containerWidth, currentPageNumber: currentVideosPageNumber, isRTL: isRTL },
                React.createElement(PageList, { gridId: "strip-videos", styleId: styleId, pageWidth: "100%", gridMinWidth: MAX_WIDGET_WIDTH, currentPageNumber: currentVideosPageNumber, itemsByPageNumber: videoIdsByPageNumber, renderItem: this.renderVideoItem, numberOfRows: numberOfRows, numberOfColumns: numberOfColumns, isRTL: isRTL })),
            React.createElement(Nav, { prevButtonClassName: styles['prev-button'], nextButtonClassName: styles['next-button'], prevButtonAriaLabel: i18n.t('widget.accessibility.prev-videos'), nextButtonAriaLabel: i18n.t('widget.accessibility.next-videos'), onPrevClick: onSlideToPrev, onNextClick: onSlideToNext, isPrevVisible: this.isPrevPageVisible, isNextVisible: this.isNextPageVisible, isRTL: isRTL })));
    };
    Videos.propTypes = {
        appSettings: PropTypes.object,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,
        containerWidth: PropTypes.number,
        videoByIds: PropTypes.object,
        videoIdsByPageNumber: PropTypes.array,
        thumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        onSlideToPrev: PropTypes.func,
        onSlideToNext: PropTypes.func,
        onPlayRequest: PropTypes.func,
        onThumbnailClick: PropTypes.func,
        numberOfRows: PropTypes.number,
        numberOfColumns: PropTypes.number,
        currentVideosPageNumber: PropTypes.number,
        isRTL: PropTypes.bool,
        styleId: PropTypes.string,
    };
    Videos = __decorate([
        connect(function (state) { return ({
            videoByIds: getVideosGroupedByIds(state),
            appSettings: getAllSettings(state),
            isRTL: isRTL(state),
            styleId: getCompId(state),
        }); }, { logWidgetSystem: logWidgetSystem }),
        withStyles(styles),
        VODCSSModules(styles)
    ], Videos);
    return Videos;
}(React.Component));
export default Videos;
