import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getVideoIds } from 'widget/redux/client/lazy-channel-videos/selectors';
import { getIsSearching } from 'shared/selectors/search';
export default (function (Target) {
    var WithPreviousVideos = /** @class */ (function (_super) {
        __extends(WithPreviousVideos, _super);
        function WithPreviousVideos(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                prevVideoIds: props.videoIds,
                prevVideoByIds: props.videoByIds,
            };
            return _this;
        }
        WithPreviousVideos.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
            var videoIds = _a.videoIds, videoByIds = _a.videoByIds;
            if (this.props.videoIds.length !== videoIds.length &&
                this.props.videoIds.length) {
                this.setState({ prevVideoIds: this.props.videoIds });
            }
            if (!_.isEqual(videoByIds, this.state.prevVideoByIds)) {
                this.setState({ prevVideoByIds: videoByIds });
            }
        };
        WithPreviousVideos.prototype.getVideoByIds = function () {
            var _a = this.props, isSearching = _a.isSearching, videoByIds = _a.videoByIds;
            return isSearching ? this.state.prevVideoByIds : videoByIds;
        };
        WithPreviousVideos.prototype.getVideoIds = function () {
            var _a = this.props, isSearching = _a.isSearching, videoIds = _a.videoIds;
            return isSearching ? this.state.prevVideoIds : videoIds;
        };
        WithPreviousVideos.prototype.render = function () {
            return (React.createElement(Target, __assign({}, this.props, { videoByIds: this.getVideoByIds(), videoIds: this.getVideoIds() })));
        };
        WithPreviousVideos.propTypes = {
            videoIds: PropTypes.array.isRequired,
            videoByIds: PropTypes.object.isRequired,
            isSearching: PropTypes.bool.isRequired,
        };
        WithPreviousVideos = __decorate([
            connect(function (state) { return ({
                videoIds: getVideoIds(state),
                videoByIds: getVideosGroupedByIds(state),
                isSearching: getIsSearching(state),
            }); })
        ], WithPreviousVideos);
        return WithPreviousVideos;
    }(React.Component));
    return WithPreviousVideos;
});
