var _a;
import { __assign, __read, __spread } from "tslib";
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { SELECTED_VIDEO_ACTIONS } from 'widget/redux/client/actions/select-video';
import { NAMES as CHANNEL_VIDEOS_LIST } from 'shared/redux/server/actions/channel/videos/list-public';
import { RESET_LAZY_CHANNEL_VIDEOS, SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX, SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX, PREPEND_LAZY_VIDEO_IDS, } from 'widget/redux/client/lazy-channel-videos/actions';
var defaultState = {
    prependedIds: [],
    ids: [],
    cursor: null,
    perPage: 0,
    isFetching: false,
    currentPageIndex: 0,
    currentVideoIndex: 0,
};
var updateIds = function (state, _a) {
    var payload = _a.payload;
    var idsWithoutPrepended = _.reject(payload.response.data.result, function (id) {
        return _.includes(state.prependedIds, id);
    });
    return __assign(__assign({}, state), { ids: __spread(state.ids, idsWithoutPrepended), cursor: payload.response.paging.cursor, isFetching: false });
};
var prependVideoIds = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { prependedIds: __spread(state.prependedIds, _.castArray(payload)) }));
};
var setFetching = function (isFetching) { return function (state) { return (__assign(__assign({}, state), { isFetching: isFetching })); }; };
var setPageIndex = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { currentPageIndex: payload }));
};
var setVideoIndex = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { currentVideoIndex: payload }));
};
var resetVideoIndex = function (state) { return (__assign(__assign({}, state), { currentVideoIndex: 0 })); };
var setVideoIndexFromVideoId = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { currentVideoIndex: Math.max(0, _.indexOf(state.ids, payload, 0)) }));
};
export default handleActions((_a = {},
    _a[CHANNEL_VIDEOS_LIST.START] = setFetching(true),
    _a[CHANNEL_VIDEOS_LIST.FAIL] = setFetching(false),
    _a[CHANNEL_VIDEOS_LIST.SUCCESS] = updateIds,
    _a[PREPEND_LAZY_VIDEO_IDS] = prependVideoIds,
    _a[SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX] = setPageIndex,
    _a[SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX] = setVideoIndex,
    _a[SELECTED_VIDEO_ACTIONS.SET] = setVideoIndexFromVideoId,
    _a[SELECTED_VIDEO_ACTIONS.RESET] = resetVideoIndex,
    _a[RESET_LAZY_CHANNEL_VIDEOS] = _.constant(defaultState),
    _a), defaultState);
