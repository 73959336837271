import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getFullAccessActionName } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import getTranslationData from './translation-data';
import { getBuyButtonHandler } from 'shared/utils/get-buy-button-handler';
export var createGetPaidAccessButtonConfig = function () {
    return createSelector(getChannelForWidget, getCurrentSiteUser, function (state, ownProps) { return ownProps.videoItem; }, function (state, ownProps) { return ownProps.onSubscriptionRequest; }, function (state, ownProps) { return ownProps.onPurchaseRequest; }, function (state, ownProps) { return ownProps.onRentRequest; }, function (channel, user, video, onSubscriptionRequest, onPurchaseRequest, onRentRequest) {
        var fullAccessAction = getFullAccessActionName(channel, video, user);
        var callback = getBuyButtonHandler(onSubscriptionRequest, onPurchaseRequest, onRentRequest, fullAccessAction);
        var translationData = getTranslationData(fullAccessAction, channel, video);
        return translationData
            ? {
                callback: callback,
                translationData: translationData,
            }
            : null;
    });
};
