import { getUid, getCompId, } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createProxy } from 'shared/worker/lib';
import { pubSubPublish } from 'shared/worker/actions/pub-sub/publish';
export var REFRESH_EVENT = 'refreshOnLoginEvents';
export var storeForReload = createProxy('storeForReload', function (data) { return function (dispatch, getState) {
    var state = getState();
    var uid = getUid(state);
    var rootCompId = getCompId(state);
    dispatch(pubSubPublish(REFRESH_EVENT, {
        uid: uid,
        stored: data,
        rootCompId: rootCompId,
    }, true));
}; });
