import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notForPreview } from 'shared/utils/not-for-preview';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isShareOverlayOpen } from 'widget/selectors/share-overlay';
import { openFullScreenShareOverlay } from 'shared/utils/open-overlay';
import { isInlineShareVisible } from 'widget/selectors/inline-share';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { closeShareOverlay } from 'widget/redux/client/actions/share-overlay';
import { showPlayerUI } from 'widget/redux/client/actions/player/ui';
var OpenFullscreenModalShare = /** @class */ (function (_super) {
    __extends(OpenFullscreenModalShare, _super);
    function OpenFullscreenModalShare() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openFullScreenShareOverlay = notForPreview(function (channelId, mainVideoId) {
            _this.props.openFullScreenShareOverlay(channelId, mainVideoId);
        });
        return _this;
    }
    OpenFullscreenModalShare.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var pauseVideo = _a.pauseVideo, isShareOpen = _a.isShareOpen, shouldOpenFullscreenModal = _a.shouldOpenFullscreenModal, channel = _a.channel, mainVideoId = _a.mainVideoId, closeShareOverlay = _a.closeShareOverlay, showPlayerUI = _a.showPlayerUI;
        if (shouldOpenFullscreenModal && isShareOpen && !this.props.isShareOpen) {
            pauseVideo();
            closeShareOverlay();
            showPlayerUI();
            this.openFullScreenShareOverlay(channel.id, mainVideoId);
        }
    };
    OpenFullscreenModalShare.prototype.render = function () {
        return null;
    };
    OpenFullscreenModalShare.propTypes = {
        pauseVideo: PropTypes.func.isRequired,
        closeShareOverlay: PropTypes.func.isRequired,
        showPlayerUI: PropTypes.func.isRequired,
        isShareOpen: PropTypes.bool.isRequired,
        shouldOpenFullscreenModal: PropTypes.bool.isRequired,
        mainVideoId: PropTypes.string,
        channel: PropTypes.object,
        itemWidth: PropTypes.number.isRequired,
    };
    OpenFullscreenModalShare = __decorate([
        connect(function (state, props) { return ({
            isShareOpen: isShareOverlayOpen(state),
            mainVideoId: getMainVideoId(state),
            channel: getChannelForWidget(state),
            shouldOpenFullscreenModal: !isInlineShareVisible(state, props),
        }); }, {
            pauseVideo: pauseVideo,
            closeShareOverlay: closeShareOverlay,
            showPlayerUI: showPlayerUI,
            openFullScreenShareOverlay: openFullScreenShareOverlay,
        })
    ], OpenFullscreenModalShare);
    return OpenFullscreenModalShare;
}(React.Component));
export default OpenFullscreenModalShare;
