import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { VODCSSModules } from 'shared/utils/wix-connect';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import MenuButton from 'widget/layouts/compact/components/menu-button/menu-button';
import Animate from '@wix/wix-vod-shared/dist/src/widget/ui-components/animate/animate';
import { withStyles } from 'shared/utils/withStyles';
import styles from './action-bar.styl';
var ActionBar = /** @class */ (function (_super) {
    __extends(ActionBar, _super);
    function ActionBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleButtonsClick = function (event) {
            event.stopPropagation();
            _this.props.onButtonsClick();
        };
        return _this;
    }
    ActionBar.prototype.renderButtons = function () {
        var _a = this.props, onActionsContainerClick = _a.onActionsContainerClick, children = _a.children;
        return (React.createElement("div", { onClick: onActionsContainerClick, className: styles['clickable-container'] },
            React.createElement("div", { styleName: "buttons", onClick: this.handleButtonsClick }, children)));
    };
    ActionBar.prototype.renderMenuButton = function () {
        var onMenuClick = this.props.onMenuClick;
        return (React.createElement("div", { className: styles['clickable-container'], onClick: onMenuClick },
            React.createElement(MenuButton, { styleName: "menu-button", ariaLabel: i18n.t('widget.accessibility.show-all-videos') })));
    };
    ActionBar.prototype.render = function () {
        var _a = this.props, areActionsVisible = _a.areActionsVisible, height = _a.height;
        return (React.createElement("div", { style: { height: height }, styleName: "actions" },
            React.createElement(Animate, { activeClassName: styles['actions-wrapper'], dataHook: "compact-actions", type: "fade-in", when: areActionsVisible }, this.renderButtons()),
            React.createElement(Animate, { type: "fade-in", activeClassName: styles['menu-button-wrapper'], dataHook: "menu-button-wrapper", when: !areActionsVisible }, this.renderMenuButton())));
    };
    ActionBar.propTypes = {
        children: PropTypes.any,
        height: PropTypes.number,
        areActionsVisible: PropTypes.bool,
        onActionsContainerClick: PropTypes.func,
        onMenuClick: PropTypes.func,
        onButtonsClick: PropTypes.func,
    };
    ActionBar = __decorate([
        withStyles(styles),
        VODCSSModules(styles)
    ], ActionBar);
    return ActionBar;
}(React.Component));
export default ActionBar;
