import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ContainerQuery } from 'react-container-query';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import ContentSlider from '@wix/wix-vod-shared/dist/src/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/src/widget/ui-components/page-list/page-list';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import VideoThumbnailOverlay from 'widget/layouts/compact/components/video-thumbnail-overlay/video-thumbnail-overlay';
import { withStyles } from 'shared/utils/withStyles';
import styles from './videos.styl';
var containerQuery = {
    'videos-container-min-800': {
        minWidth: 800,
    },
    'videos-container-max-799': {
        maxWidth: 799,
        minWidth: 600,
    },
    'videos-container-max-599': {
        maxWidth: 599,
        minWidth: 550,
    },
    'videos-container-max-549': {
        maxWidth: 549,
        minWidth: 450,
    },
    'videos-container-max-449': {
        maxWidth: 449,
    },
};
var Videos = /** @class */ (function (_super) {
    __extends(Videos, _super);
    function Videos() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderVideoItem = function (videoId, videoAtPageIndex, isVisiblePage) {
            var _a = _this.props, currentSiteUser = _a.currentSiteUser, channelData = _a.channelData, videoByIds = _a.videoByIds, thumbnailSize = _a.thumbnailSize, OverlayActionsCallbacks = _a.OverlayActionsCallbacks, onBeforeClick = _a.onBeforeClick, onBeforePlayRequested = _a.onBeforePlayRequested, onPlayRequestedBi = _a.onPlayRequestedBi;
            var videoItem = videoByIds[videoId];
            return (React.createElement(OverlayActionsCallbacks, { channelId: channelData.id, videoId: videoItem.id, videoSource: videoItem.videoSource, onBeforeClick: onBeforeClick, onBeforePlayRequested: onBeforePlayRequested, onPlayRequestedBi: _.partial(onPlayRequestedBi, videoItem), key: videoId },
                React.createElement(VideoThumbnailOverlay, __assign({}, thumbnailSize, { currentSiteUser: currentSiteUser, channelData: channelData, videoItem: videoItem, isContentFocusable: isVisiblePage }))));
        };
        return _this;
    }
    Videos.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var currentVideosPageNumber = _a.currentVideosPageNumber;
        if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
            this.props.logWidgetSystem('videoList.changePage.rendered', {
                previousEventName: 'videoList.changePage.requested',
            });
        }
    };
    Videos.prototype.componentDidUpdate = function () {
        this.props.logWidgetSystem('videoList.show.rendered', {
            previousEventName: 'videoList.show.requested',
        });
    };
    Videos.prototype.hasPage = function (pageNum, next) {
        var _a = this.props, videoIdsByPageNumber = _a.videoIdsByPageNumber, videosCount = _a.channelData.videosCount;
        var requestedPage = pageNum + next;
        if (pageNum === 0 && next > 0) {
            var videosPassed = videoIdsByPageNumber[0]
                ? videoIdsByPageNumber[0].length * requestedPage
                : 0;
            return (Boolean(videoIdsByPageNumber[requestedPage]) ||
                videosPassed < videosCount);
        }
        return Boolean(videoIdsByPageNumber[requestedPage]);
    };
    Object.defineProperty(Videos.prototype, "isPrevPageVisible", {
        get: function () {
            var currentVideosPageNumber = this.props.currentVideosPageNumber;
            return this.hasPage(currentVideosPageNumber, -1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Videos.prototype, "isNextPageVisible", {
        get: function () {
            var currentVideosPageNumber = this.props.currentVideosPageNumber;
            return this.hasPage(currentVideosPageNumber, 1);
        },
        enumerable: true,
        configurable: true
    });
    Videos.prototype.render = function () {
        var _this = this;
        var _a = this.props, currentVideosPageNumber = _a.currentVideosPageNumber, videoIdsByPageNumber = _a.videoIdsByPageNumber, containerWidth = _a.containerWidth, onSlideToPrev = _a.onSlideToPrev, onSlideToNext = _a.onSlideToNext, isVisible = _a.isVisible, isRTL = _a.isRTL;
        return (React.createElement(ContainerQuery, { query: containerQuery }, function (containerClassNames) { return (React.createElement("div", { className: classNames(styles.container, containerClassNames) },
            React.createElement(ContentSlider, { width: containerWidth, currentPageNumber: currentVideosPageNumber, isRTL: isRTL },
                React.createElement(PageList, { pageWidth: containerWidth, currentPageNumber: currentVideosPageNumber, itemsByPageNumber: videoIdsByPageNumber, renderItem: _this.renderVideoItem })),
            isVisible && (React.createElement(Nav, { prevButtonClassName: styles['prev-button'], nextButtonClassName: styles['next-button'], onPrevClick: onSlideToPrev, onNextClick: onSlideToNext, prevButtonAriaLabel: i18n.t('widget.accessibility.prev-videos'), nextButtonAriaLabel: i18n.t('widget.accessibility.next-videos'), isPrevVisible: _this.isPrevPageVisible, isNextVisible: _this.isNextPageVisible, isRTL: isRTL })))); }));
    };
    Videos.propTypes = {
        OverlayActionsCallbacks: PropTypes.func,
        currentSiteUser: PropTypes.object,
        channelData: PropTypes.object,
        containerWidth: PropTypes.number,
        videoByIds: PropTypes.object,
        videoIdsByPageNumber: PropTypes.array,
        isVisible: PropTypes.bool,
        isRTL: PropTypes.bool,
        thumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        onSlideToPrev: PropTypes.func,
        onSlideToNext: PropTypes.func,
        onBeforeClick: PropTypes.func,
        onBeforePlayRequested: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,
        currentVideosPageNumber: PropTypes.number,
    };
    Videos = __decorate([
        connect(function (state) { return ({
            currentSiteUser: getCurrentSiteUser(state),
            videoByIds: getVideosGroupedByIds(state),
        }); }, { logWidgetSystem: logWidgetSystem }),
        withStyles(styles)
    ], Videos);
    return Videos;
}(React.Component));
export default Videos;
