import _ from 'lodash';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { APP_SETTINGS_VERSION_LATEST } from '@wix/wix-vod-constants/dist/app-settings/versions';
import { isDemoChannel } from 'shared/utils/channel-helpers';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
var createIdGetter = function (key) { return function (source) {
    var value = _.get(source, key);
    var id = value ? value.replace(/\'/g, '') : value;
    return isGraphAPIEnabled() ? dashify(id) : id;
}; };
export var getChannelId = createIdGetter('fonts.channelId');
export var getVideoId = createIdGetter('fonts.videoId');
export var isLatestVersion = function (appSettings) {
    return _.get(appSettings, 'numbers.settingsVersion') === APP_SETTINGS_VERSION_LATEST;
};
export var isNewInstallation = function (appSettings) {
    var channelId = getChannelId(appSettings);
    return !channelId || isDemoChannel(channelId);
};
