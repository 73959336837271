export default { "ar": { "locale": "ar", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2); if (ord)
            return "other"; return n == 0 ? "zero" : n == 1 ? "one" : n == 2 ? "two" : n100 >= 3 && n100 <= 10 ? "few" : n100 >= 11 && n100 <= 99 ? "many" : "other"; } }, "bg": { "locale": "bg", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return n == 1 ? "one" : "other"; } }, "cs": { "locale": "cs", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], v0 = !s[1]; if (ord)
            return "other"; return n == 1 && v0 ? "one" : i >= 2 && i <= 4 && v0 ? "few" : !v0 ? "many" : "other"; } }, "da": { "locale": "da", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], t0 = Number(s[0]) == n; if (ord)
            return "other"; return n == 1 || !t0 && (i == 0 || i == 1) ? "one" : "other"; } }, "de": { "locale": "de", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1]; if (ord)
            return "other"; return n == 1 && v0 ? "one" : "other"; } }, "el": { "locale": "el", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return n == 1 ? "one" : "other"; } }, "en": { "locale": "en", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2); if (ord)
            return n10 == 1 && n100 != 11 ? "one" : n10 == 2 && n100 != 12 ? "two" : n10 == 3 && n100 != 13 ? "few" : "other"; return n == 1 && v0 ? "one" : "other"; } }, "es": { "locale": "es", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return n == 1 ? "one" : "other"; } }, "fi": { "locale": "fi", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1]; if (ord)
            return "other"; return n == 1 && v0 ? "one" : "other"; } }, "fr": { "locale": "fr", "pluralRuleFunction": function (n, ord) { if (ord)
            return n == 1 ? "one" : "other"; return n >= 0 && n < 2 ? "one" : "other"; } }, "he": { "locale": "he", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1); if (ord)
            return "other"; return n == 1 && v0 ? "one" : i == 2 && v0 ? "two" : v0 && (n < 0 || n > 10) && t0 && n10 == 0 ? "many" : "other"; } }, "hi": { "locale": "hi", "pluralRuleFunction": function (n, ord) { if (ord)
            return n == 1 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : n == 6 ? "many" : "other"; return n >= 0 && n <= 1 ? "one" : "other"; } }, "hu": { "locale": "hu", "pluralRuleFunction": function (n, ord) { if (ord)
            return n == 1 || n == 5 ? "one" : "other"; return n == 1 ? "one" : "other"; } }, "id": { "locale": "id", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return "other"; } }, "it": { "locale": "it", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1]; if (ord)
            return n == 11 || n == 8 || n == 80 || n == 800 ? "many" : "other"; return n == 1 && v0 ? "one" : "other"; } }, "ja": { "locale": "ja", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return "other"; } }, "ko": { "locale": "ko", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return "other"; } }, "lt": { "locale": "lt", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), f = s[1] || "", t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2); if (ord)
            return "other"; return n10 == 1 && (n100 < 11 || n100 > 19) ? "one" : n10 >= 2 && n10 <= 9 && (n100 < 11 || n100 > 19) ? "few" : f != 0 ? "many" : "other"; } }, "ms": { "locale": "ms", "pluralRuleFunction": function (n, ord) { if (ord)
            return n == 1 ? "one" : "other"; return "other"; } }, "nl": { "locale": "nl", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1]; if (ord)
            return "other"; return n == 1 && v0 ? "one" : "other"; } }, "no": { "locale": "no", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return n == 1 ? "one" : "other"; } }, "pl": { "locale": "pl", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2); if (ord)
            return "other"; return n == 1 && v0 ? "one" : v0 && (i10 >= 2 && i10 <= 4) && (i100 < 12 || i100 > 14) ? "few" : v0 && i != 1 && (i10 == 0 || i10 == 1) || v0 && (i10 >= 5 && i10 <= 9) || v0 && (i100 >= 12 && i100 <= 14) ? "many" : "other"; } }, "pt": { "locale": "pt", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0]; if (ord)
            return "other"; return i == 0 || i == 1 ? "one" : "other"; } }, "ro": { "locale": "ro", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2); if (ord)
            return n == 1 ? "one" : "other"; return n == 1 && v0 ? "one" : !v0 || n == 0 || n != 1 && (n100 >= 1 && n100 <= 19) ? "few" : "other"; } }, "ru": { "locale": "ru", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2); if (ord)
            return "other"; return v0 && i10 == 1 && i100 != 11 ? "one" : v0 && (i10 >= 2 && i10 <= 4) && (i100 < 12 || i100 > 14) ? "few" : v0 && i10 == 0 || v0 && (i10 >= 5 && i10 <= 9) || v0 && (i100 >= 11 && i100 <= 14) ? "many" : "other"; } }, "sv": { "locale": "sv", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2); if (ord)
            return (n10 == 1 || n10 == 2) && n100 != 11 && n100 != 12 ? "one" : "other"; return n == 1 && v0 ? "one" : "other"; } }, "th": { "locale": "th", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return "other"; } }, "tl": { "locale": "tl", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], f = s[1] || "", v0 = !s[1], i10 = i.slice(-1), f10 = f.slice(-1); if (ord)
            return n == 1 ? "one" : "other"; return v0 && (i == 1 || i == 2 || i == 3) || v0 && i10 != 4 && i10 != 6 && i10 != 9 || !v0 && f10 != 4 && f10 != 6 && f10 != 9 ? "one" : "other"; } }, "tr": { "locale": "tr", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return n == 1 ? "one" : "other"; } }, "uk": { "locale": "uk", "pluralRuleFunction": function (n, ord) { var s = String(n).split("."), i = s[0], v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2), i10 = i.slice(-1), i100 = i.slice(-2); if (ord)
            return n10 == 3 && n100 != 13 ? "few" : "other"; return v0 && i10 == 1 && i100 != 11 ? "one" : v0 && (i10 >= 2 && i10 <= 4) && (i100 < 12 || i100 > 14) ? "few" : v0 && i10 == 0 || v0 && (i10 >= 5 && i10 <= 9) || v0 && (i100 >= 11 && i100 <= 14) ? "many" : "other"; } }, "zh": { "locale": "zh", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return "other"; } }, "zu": { "locale": "zu", "pluralRuleFunction": function (n, ord) { if (ord)
            return "other"; return n >= 0 && n <= 1 ? "one" : "other"; } } };
