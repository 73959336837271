import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { logBi } from 'shared/worker/actions/bi';
import { createProxy } from 'shared/worker/lib';
export var sendLoadComplete = createProxy('sendLoadComplete', function () { return function (dispatch) {
    try {
        var loadingTime = parseInt(performance.now(), 10);
        dispatch(logBi('widget.loadComplete', {
            loadingTime: loadingTime,
            viewMode: VIEW_MODES.SITE,
        }));
    }
    catch (e) {
        console.warn('unable to send widget.loadComplete 71:257');
    }
}; });
