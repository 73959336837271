import _ from 'lodash';
import { createSelector } from 'reselect';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import * as viewModeSelectors from 'widget/selectors/view-mode';
export function getCurrentSiteUser(state) {
    var isSiteMode = viewModeSelectors.isSiteMode(state);
    var currentSiteUser = getHydratedData(state).currentSiteUser;
    if (!isSiteMode) {
        return null;
    }
    return currentSiteUser;
}
export var getCurrentUserEmail = createSelector(getCurrentSiteUser, function (currentSiteUser) { return _.get(currentSiteUser, 'email', ''); });
