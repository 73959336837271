import { __decorate, __extends } from "tslib";
import React from 'react';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { connect } from 'react-redux';
import PaymentEvents from 'shared/components/payment-events/payment-events';
var PaymentsBiHandler = /** @class */ (function (_super) {
    __extends(PaymentsBiHandler, _super);
    function PaymentsBiHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.logPaymentSucceeded = function () {
            _this.props.logWidgetSystem('video.purchase.succeeded');
        };
        _this.logSubscribeSucceeded = function () {
            _this.props.logWidgetSystem('channel.subscribe.succeeded');
        };
        _this.logUnsubscribeSucceeded = function () {
            _this.props.logWidgetSystem('channel.unsubscribe.succeeded');
        };
        return _this;
    }
    PaymentsBiHandler.prototype.render = function () {
        return (React.createElement(PaymentEvents, { onSale: this.logPaymentSucceeded, onRent: this.logPaymentSucceeded, onSubscription: this.logSubscribeSucceeded, onSubscriptionCancel: this.logUnsubscribeSucceeded }));
    };
    PaymentsBiHandler = __decorate([
        connect(null, { logWidgetSystem: logWidgetSystem })
    ], PaymentsBiHandler);
    return PaymentsBiHandler;
}(React.Component));
export default PaymentsBiHandler;
