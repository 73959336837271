import { isEmpty } from 'lodash';
import WixSDK from 'shared/utils/wix-sdk';
import * as WidgetsAPI from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import { getCompId } from 'shared/utils/comp-id';
export var getWidgetId = function () {
    return WixSDK.Utils.getInstanceId() + "-" + getCompId();
};
export var getWidgetSettings = function () {
    return WidgetsAPI.getWidgetSettings(getWidgetId());
};
export var updateWidgetSettings = function (settings) {
    return WidgetsAPI.updateWidgetSettings(getWidgetId(), settings);
};
var getUpdateEntities = function (_a) {
    var channelId = _a.channelId, videoId = _a.videoId, savedChannelId = _a.savedChannelId, savedVideoId = _a.savedVideoId;
    var updateData = {};
    if (channelId && channelId !== savedChannelId) {
        updateData.listId = channelId;
    }
    if (videoId && videoId !== savedVideoId) {
        updateData.listId = channelId;
        updateData.itemId = videoId;
    }
    return updateData;
};
export function updateWidget(settings) {
    return WixSDK.getComponentInfo().then(function (_a) {
        var pageId = _a.pageId;
        var instanceId = WixSDK.Utils.getInstanceId();
        var compId = getCompId();
        return WidgetsAPI.updateWidget(getWidgetId(), {
            instanceId: instanceId,
            compId: compId,
            pageId: pageId,
            settings: settings,
        });
    });
}
export var updateDeeplink = function (channelId, videoId) {
    return getWidgetSettings()
        .then(function (data) {
        var savedChannelId = data.listId;
        var savedVideoId = data.itemId;
        var update = getUpdateEntities({
            channelId: channelId,
            videoId: videoId,
            savedChannelId: savedChannelId,
            savedVideoId: savedVideoId,
        });
        if (!isEmpty(update)) {
            return updateWidgetSettings(update);
        }
    })
        .catch(function (_a) {
        var status = _a.status;
        // create widget info in case it's not saved yet
        if (status === 404) {
            var update = getUpdateEntities({ channelId: channelId, videoId: videoId });
            if (!isEmpty(update)) {
                return updateWidget(getUpdateEntities({ channelId: channelId, videoId: videoId }));
            }
        }
    });
};
