import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { onEscPress } from 'shared/utils/call-on-keyboard-press';
import { sides, popoutPositions, trianglePositions, } from 'shared/constants/popout';
import { sideShape, popoutPositionShape, trianglePositionShape, } from 'shared/shapes/popout';
import { isPortableDevice } from 'shared/selectors/form-factor';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import Icon from 'shared/components/icon/icon';
import Popout from 'shared/components/popout/popout';
import { showTooltip, hideTooltip } from './tooltip-popout';
import { withStyles } from 'shared/utils/withStyles';
import styles from './tooltip.styl';
var Tooltip = /** @class */ (function (_super) {
    __extends(Tooltip, _super);
    function Tooltip() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showTooltipPopout = function () {
            var _a = _this.props, popoutSide = _a.popoutSide, popoutPosition = _a.popoutPosition, trianglePosition = _a.trianglePosition, preventHideOnHover = _a.preventHideOnHover, popoutComponent = _a.popoutComponent, popoutClassName = _a.popoutClassName, popoutTriangleClassName = _a.popoutTriangleClassName, popoutInnerClassName = _a.popoutInnerClassName, text = _a.text, noTooltip = _a.noTooltip, children = _a.children, onTooltipShow = _a.onTooltipShow, compId = _a.compId;
            if (noTooltip) {
                return;
            }
            showTooltip(__assign({ compId: compId,
                preventHideOnHover: preventHideOnHover,
                popoutComponent: popoutComponent,
                popoutSide: popoutSide,
                popoutPosition: popoutPosition,
                trianglePosition: trianglePosition, className: popoutClassName, innerClassName: popoutInnerClassName, triangleClassName: popoutTriangleClassName, content: text || children, referenceElement: _this.icon }, _this.tooltipPropsForDevice));
            onTooltipShow();
        };
        _this.saveRef = function (node) {
            _this.icon = node;
            _this.props.getRef(node);
        };
        return _this;
    }
    Tooltip.prototype.componentDidMount = function () {
        document.addEventListener('keyup', memoizedPartial(onEscPress, this.hideTooltipPopout));
    };
    Tooltip.prototype.UNSAFE_componentWillUpdate = function (nextProps) {
        var noTooltip = nextProps.noTooltip;
        if (noTooltip !== this.props.noTooltip) {
            if (noTooltip) {
                this.hideTooltipPopout();
            }
            else {
                this.showTooltipPopout();
            }
        }
    };
    Tooltip.prototype.componentWillUnmount = function () {
        this.hideTooltipPopout();
        document.removeEventListener('keyup', memoizedPartial(onEscPress, this.hideTooltipPopout));
    };
    Tooltip.prototype.hideTooltipPopout = function () {
        hideTooltip();
    };
    Object.defineProperty(Tooltip.prototype, "behaviorProps", {
        get: function () {
            if (this.props.isPortableDevice) {
                return {
                    onTouchStart: this.showTooltipPopout,
                };
            }
            return {
                onMouseEnter: this.showTooltipPopout,
                onMouseLeave: this.hideTooltipPopout,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tooltip.prototype, "tooltipPropsForDevice", {
        get: function () {
            if (this.props.isPortableDevice) {
                return {
                    onClickOutside: this.hideTooltipPopout,
                };
            }
            return {};
        },
        enumerable: true,
        configurable: true
    });
    Tooltip.prototype.render = function () {
        var _a = this.props, iconName = _a.iconName, className = _a.className, dataHook = _a.dataHook, iconAriaLabel = _a.iconAriaLabel, isButtonFocusable = _a.isButtonFocusable;
        return (React.createElement("button", __assign({ ref: this.saveRef, "data-hook": dataHook, className: classnames(styles.button, className), onClick: this.showTooltipPopout, "aria-label": iconAriaLabel, tabIndex: isButtonFocusable ? 0 : -1 }, this.behaviorProps),
            React.createElement(Icon, { name: iconName })));
    };
    Tooltip.propTypes = {
        iconName: PropTypes.string,
        dataHook: PropTypes.string,
        preventHideOnHover: PropTypes.bool,
        noTooltip: PropTypes.bool,
        popoutComponent: PropTypes.func,
        popoutSide: sideShape,
        popoutPosition: popoutPositionShape,
        trianglePosition: trianglePositionShape,
        className: PropTypes.string,
        popoutClassName: PropTypes.string,
        popoutTriangleClassName: PropTypes.string,
        popoutInnerClassName: PropTypes.string,
        iconAriaLabel: PropTypes.string,
        getRef: PropTypes.func,
        onTooltipShow: PropTypes.func,
        text: PropTypes.any,
        children: PropTypes.any,
        isButtonFocusable: PropTypes.bool,
        compId: PropTypes.string.isRequired,
    };
    Tooltip.defaultProps = {
        iconName: 'info',
        isButtonFocusable: true,
        preventHideOnHover: false,
        noTooltip: false,
        popoutComponent: Popout,
        popoutSide: sides.RIGHT,
        popoutPosition: popoutPositions.CENTER,
        trianglePosition: trianglePositions.CENTER,
        getRef: _.noop,
        onTooltipShow: _.noop,
    };
    Tooltip = __decorate([
        connect(function (state) { return ({
            isPortableDevice: isPortableDevice(state),
            compId: getCompId(state),
        }); }),
        withStyles(styles)
    ], Tooltip);
    return Tooltip;
}(React.Component));
export default Tooltip;
