import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Compact from './components/layouts/compact/compact';
import { Vertical } from './components/layouts/vertical/vertical';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import DeeplinkPopups from 'widget/components/deeplink-popups/deeplink-popups';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';
import { MobilePerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import { getAllSettings, isVerticalLayoutMobile, } from 'shared/selectors/app-settings';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { sendLoadComplete } from 'widget/utils/load-complete';
import { isPlayingOptimistic, isInitial, isEnded, } from 'shared/selectors/playback';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { openVideoPage } from 'widget/redux/client/actions/mobile-overlay-openners/main-openners';
import { openSubscribeOverlayFromWidget } from 'widget/redux/client/actions/mobile-overlay-openners/payment-pages-actions';
import { selectVideo, resetSelectedVideo, } from 'widget/redux/client/actions/select-video';
import { requestPlay, requestPause, } from 'shared/redux/client/reducers/playback';
import { canPlayVideoInFrame } from 'mobile/views/home/ui-selectors/can-play-video-in-frame';
import { isMainItemChannelCover } from 'mobile/views/home/ui-selectors/main-item-cover';
import { getVideoIds, getIsFetching, } from 'widget/redux/client/lazy-channel-videos/selectors';
import { isSingleVideoView } from 'mobile/views/home/ui-selectors/videos';
import { overlaySizeMobileMainItem, playerSizeMobileMainItem, } from 'widget/utils/videos-sizes/videos-sizes';
import { PersistentEvents } from 'widget/components/persistent-events/persistent-events';
import { openMobileOverlay } from 'shared/utils/open-overlay';
import { storeForReload } from 'widget/utils/reload';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import { notForPreview } from 'shared/utils/not-for-preview';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { requestLogin } from 'shared/utils/auth';
import { playbackModuleLoaded } from 'mobile/redux/actions/modules';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { withStyles } from 'shared/utils/withStyles';
import styles from './home.styl';
import { getMainVideo } from 'widget/selectors/get-video';
var HomeView = /** @class */ (function (_super) {
    __extends(HomeView, _super);
    function HomeView(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSubscribeClick = notForPreview(function () {
            _this.props.openSubscribeOverlayFromWidget();
        });
        _this.openMemberOnlyPage = function (id) {
            _this.props.logWidgetSystem('video.memberOnlyInfo.mobileOverlay.requested');
            _this.props.openMobileOverlay("#/membership/" + id + "?shouldCloseMobileOverlay=true");
        };
        _this.processDataFromStorage = function (_a) {
            var selectedVideoId = _a.selectedVideoId;
            if (selectedVideoId) {
                _this.setState({ initialVideoId: selectedVideoId });
                _this.props.selectVideo(selectedVideoId);
            }
        };
        _this.storeDataForReload = function () {
            _this.props.storeForReload({
                selectedVideoId: _this.props.mainVideoId,
            });
        };
        _this.playVideo = function (videoItem) {
            var _a = _this.props, channelData = _a.channelData, currentSiteUser = _a.currentSiteUser, selectVideo = _a.selectVideo, requestPlay = _a.requestPlay, playback = _a.playback;
            var id = videoItem.id;
            var state = { playback: playback };
            var shouldSendPlayStartBi = isInitial(state, id) || isEnded(state, id);
            var isPlayAllowed = canPlayFullOrPreview(channelData, videoItem, currentSiteUser);
            if (isPlayAllowed) {
                if (shouldSendPlayStartBi) {
                    _this.props.logWidgetVidClick({ videoItem: videoItem, channelData: channelData });
                }
                requestPlay(id);
            }
            else {
                selectVideo(id);
            }
        };
        _this.onCurrentSlideChanged = function (newIndex) {
            var _a = _this.props, isFetching = _a.isFetching, videoIds = _a.videoIds, selectVideo = _a.selectVideo;
            if (videoIds[newIndex] && !isFetching) {
                selectVideo(videoIds[newIndex]);
            }
        };
        _this.onPlayRequestedDisallowed = function () {
            var mainVideoId = _this.props.mainVideoId;
            _this.props.openVideoPage(mainVideoId);
        };
        _this.loadPlaybackModule = function () { return __awaiter(_this, void 0, void 0, function () {
            var module;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (typeof window === 'undefined') {
                            return [2 /*return*/, function () { return null; }];
                        }
                        return [4 /*yield*/, import(
                            /* webpackChunkName: "mobile/playback" */ './components/playback/playback')];
                    case 1:
                        module = _a.sent();
                        this.props.playbackModuleLoaded();
                        return [2 /*return*/, module.Playback];
                }
            });
        }); };
        _this.showLogInForm = notForPreview(function () {
            _this.props.sendBiEvent('widget.signIn.clicked');
            _this.props.requestLogin().then(_this.storeDataForReload);
        });
        _this.reset = function () {
            _this.props.resetSelectedVideo();
        };
        _this.state = {
            initialVideoId: props.mainVideoId,
        };
        return _this;
    }
    HomeView.prototype.renderLayout = function () {
        return this.props.isVerticalLayoutMobile
            ? this.renderVerticalLayout()
            : this.renderCompactLayout();
    };
    HomeView.prototype.renderVerticalLayout = function () {
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, channelData = _a.channelData, playerSize = _a.playerSize, requestPause = _a.requestPause, mainVideo = _a.mainVideo;
        return (React.createElement(Vertical, { playerSize: playerSize, channelData: channelData, isVideoPlaying: isVideoPlaying, pauseVideo: requestPause, onSubscribeClick: this.handleSubscribeClick, openMemberOnlyPage: this.openMemberOnlyPage, onPlayRequestedDisallowed: this.onPlayRequestedDisallowed, showLogInForm: this.showLogInForm, playVideo: this.playVideo, loadPlaybackModule: this.loadPlaybackModule, mainVideo: mainVideo }));
    };
    HomeView.prototype.renderCompactLayout = function () {
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, canShowChannelCover = _a.canShowChannelCover, appSettings = _a.appSettings, videoByIds = _a.videoByIds, videoIds = _a.videoIds, isFetching = _a.isFetching, channelData = _a.channelData, currentSiteUser = _a.currentSiteUser, mainVideoId = _a.mainVideoId, mainVideo = _a.mainVideo, canPlayVideoInFrame = _a.canPlayVideoInFrame, isMainItemChannelCover = _a.isMainItemChannelCover, isSingleVideoView = _a.isSingleVideoView, overlaySize = _a.overlaySize, playerSize = _a.playerSize, openVideoPage = _a.openVideoPage, selectVideo = _a.selectVideo, resetSelectedVideo = _a.resetSelectedVideo, requestPause = _a.requestPause, openSubscribeOverlayFromWidget = _a.openSubscribeOverlayFromWidget, playbackModuleLoaded = _a.playbackModuleLoaded;
        return (React.createElement(Compact, { isVideoPlaying: isVideoPlaying, canShowChannelCover: canShowChannelCover, appSettings: appSettings, videoByIds: videoByIds, videoIds: videoIds, isFetching: isFetching, channelData: channelData, currentSiteUser: currentSiteUser, mainVideoId: mainVideoId, canPlayVideoInFrame: canPlayVideoInFrame, isMainItemChannelCover: isMainItemChannelCover, isSingleVideoView: isSingleVideoView, overlaySize: overlaySize, playerSize: playerSize, openVideoPage: openVideoPage, selectVideo: selectVideo, resetSelectedVideo: resetSelectedVideo, pauseVideo: requestPause, openSubscribeOverlayFromWidget: openSubscribeOverlayFromWidget, playbackModuleLoaded: playbackModuleLoaded, openMemberOnlyPage: this.openMemberOnlyPage, playVideo: this.playVideo, onCurrentSlideChanged: this.onCurrentSlideChanged, showLogInForm: this.showLogInForm, mainVideo: mainVideo, initialVideoId: this.state.initialVideoId, onSubscribeClick: this.handleSubscribeClick, onPlayRequestedDisallowed: this.onPlayRequestedDisallowed, loadPlaybackModule: this.loadPlaybackModule }));
    };
    HomeView.prototype.render = function () {
        return (React.createElement("section", { "data-hook": "widget-container", "data-app-version": this.props.staticsVersion, "data-channel-layout": "mobile", className: styles.container },
            React.createElement(PersistentEvents, { onEvent: this.processDataFromStorage }),
            React.createElement(PaymentEvents, { onSubscription: this.reset, onSubscriptionCancel: this.reset }),
            this.renderLayout(),
            React.createElement(DeeplinkPopups, null),
            React.createElement(MobilePerformanceLoggers, null)));
    };
    HomeView.displayName = 'MobileView';
    HomeView.propTypes = {
        staticsVersion: PropTypes.string,
        appSettings: PropTypes.object.isRequired,
        channelData: PropTypes.object.isRequired,
        selectVideo: PropTypes.func.isRequired,
        canShowChannelCover: PropTypes.bool.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        isSingleVideoView: PropTypes.bool.isRequired,
        isMainItemChannelCover: PropTypes.bool.isRequired,
        resetSelectedVideo: PropTypes.func.isRequired,
        mainVideoId: PropTypes.string,
        videoByIds: PropTypes.object.isRequired,
        currentSiteUser: PropTypes.object,
        isFetching: PropTypes.bool,
        requestPlay: PropTypes.func.isRequired,
        requestPause: PropTypes.func.isRequired,
        videoIds: PropTypes.array,
        prependedVideoIds: PropTypes.array,
        openVideoPage: PropTypes.func,
        openSubscribeOverlayFromWidget: PropTypes.func,
        canPlayVideoInFrame: PropTypes.bool,
        overlaySize: PropTypes.object,
        playerSize: PropTypes.object,
        windowSize: PropTypes.object,
        MainUINew: PropTypes.func,
        isVerticalLayoutMobile: PropTypes.bool.isRequired,
        playbackModuleLoaded: PropTypes.func.isRequired,
    };
    HomeView = __decorate([
        connect(function (state) { return ({
            isVideoPlaying: Boolean(state.selectedVideoId) &&
                isPlayingOptimistic(state, state.selectedVideoId),
            playback: state.playback,
            canShowChannelCover: !state.isVideosTouched,
            appSettings: getAllSettings(state),
            videoByIds: getVideosGroupedByIds(state),
            videoIds: getVideoIds(state),
            isFetching: getIsFetching(state),
            channelData: getChannelForWidget(state),
            currentSiteUser: getCurrentSiteUser(state),
            mainVideoId: getMainVideoId(state),
            mainVideo: getMainVideo(state),
            canPlayVideoInFrame: canPlayVideoInFrame(state),
            isMainItemChannelCover: isMainItemChannelCover(state),
            isSingleVideoView: isSingleVideoView(state),
            overlaySize: overlaySizeMobileMainItem(state.windowSize.width),
            playerSize: playerSizeMobileMainItem(state.windowSize.width),
            staticsVersion: getHydratedData(state).staticsVersion,
            windowSize: state.windowSize,
            isVerticalLayoutMobile: isVerticalLayoutMobile(state),
        }); }, {
            openVideoPage: openVideoPage,
            selectVideo: selectVideo,
            resetSelectedVideo: resetSelectedVideo,
            requestPlay: requestPlay,
            requestPause: requestPause,
            openSubscribeOverlayFromWidget: openSubscribeOverlayFromWidget,
            playbackModuleLoaded: playbackModuleLoaded,
            logWidgetSystem: logWidgetSystem,
            requestLogin: requestLogin,
            sendLoadComplete: sendLoadComplete,
            logWidgetVidClick: logWidgetVidClick,
            storeForReload: storeForReload,
            sendBiEvent: sendBiEvent,
            openMobileOverlay: openMobileOverlay,
        }),
        withStyles(styles)
    ], HomeView);
    return HomeView;
}(React.Component));
export default HomeView;
