import _ from 'lodash';
import replaceArrays from 'widget/utils/replace-arrays';
export default (function (state, _a) {
    if (state === void 0) { state = {}; }
    var payload = _a.payload;
    var channels = _.get(payload, 'response.data.entities.channels');
    if (channels) {
        return _.mergeWith({}, state, channels, replaceArrays);
    }
    return state;
});
