import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';
import PlayButton from 'mobile/views/home/components/buttons/play-button';
import PurchaseButton from 'mobile/views/home/components/purchase-button/purchase-button';
import LiveLabel from 'widget/containers/live-label/live-label';
import NoVideosOverlay from '../no-videos-overlay/no-videos-overlay';
import PlayIcon from 'mobile/assets/play.svg';
import { withStyles } from 'shared/utils/withStyles';
import styles from './cover-actions-overlay.styl';
import getBuyClickHandler from 'widget/utils/get-buy-click-handler';
import { isButtonsTextShown } from 'shared/selectors/app-settings';
var CoverActionsOverlay = /** @class */ (function (_super) {
    __extends(CoverActionsOverlay, _super);
    function CoverActionsOverlay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePurchaseClick = function () {
            var _a = _this.props, videoItem = _a.videoItem, channelData = _a.channelData, onBuyClick = _a.onBuyClick, onSubscribeClick = _a.onSubscribeClick;
            var handler = getBuyClickHandler({
                purchaseFn: onBuyClick,
                subscribeFn: onSubscribeClick,
                videoItem: videoItem,
                channelData: channelData,
            });
            handler();
        };
        return _this;
    }
    CoverActionsOverlay.prototype.stopPropagation = function (e) {
        e.stopPropagation();
    };
    CoverActionsOverlay.prototype.renderPurchaseButton = function () {
        var _a, _b;
        var _c = this.props, videoItem = _c.videoItem, isTextShown = _c.isTextShown;
        var iconStyles = classNames(styles.icon, (_a = {},
            _a[styles.withText] = isTextShown,
            _a));
        return (React.createElement(PurchaseButton, { onClick: this.handlePurchaseClick, videoItem: videoItem, iconClassName: iconStyles, className: classNames(styles['central-button'], (_b = {},
                _b[styles.visible] = this.props.isPlaybackModuleLoaded,
                _b)) }));
    };
    CoverActionsOverlay.prototype.renderPlayButton = function () {
        var _a, _b;
        var _c = this.props, videoItem = _c.videoItem, onMemberSignUp = _c.onMemberSignUp, onPlayRequestedAllowed = _c.onPlayRequestedAllowed, onPlayRequestedDisallowed = _c.onPlayRequestedDisallowed, isChannelCoverView = _c.isChannelCoverView, isTextShown = _c.isTextShown;
        var iconStyles = classNames(styles.icon, (_a = {},
            _a[styles.withText] = isTextShown,
            _a));
        return (React.createElement(PlayButton, { videoItem: videoItem, onMemberSignUp: onMemberSignUp, onPlayRequestedAllowed: onPlayRequestedAllowed, onPlayRequestedDisallowed: onPlayRequestedDisallowed, isChannelCoverView: isChannelCoverView, icon: React.createElement(PlayIcon, { className: iconStyles }), className: classNames(styles['central-button'], (_b = {},
                _b[styles.visible] = this.props.isPlaybackModuleLoaded,
                _b)) }));
    };
    CoverActionsOverlay.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, mediaInfo: videoItem.mediaInfo, videoTitle: videoItem.title, startTime: videoItem.dateStartLive, isSmall: true, smallClassName: styles['live-scheduled-label'], className: styles['live-label'] }));
    };
    CoverActionsOverlay.prototype.renderTitle = function () {
        var _a;
        var _b = this.props, title = _b.title, videoItem = _b.videoItem;
        return (title && (React.createElement("div", { "data-hook": "title", className: classNames(styles.title, (_a = {},
                _a[styles['with-video']] = videoItem,
                _a)) },
            React.createElement(ClampText, { text: title, clampOptions: { lineClamp: 3 } }))));
    };
    CoverActionsOverlay.prototype.render = function () {
        var _a;
        var _b = this.props, title = _b.title, onCoverClick = _b.onCoverClick, videoItem = _b.videoItem;
        if (!videoItem) {
            return React.createElement(NoVideosOverlay, { title: title });
        }
        var buttonsClassName = classNames(styles['central-buttons'], (_a = {},
            _a[styles['no-title']] = !title,
            _a));
        return (React.createElement("div", { className: styles.overlay },
            React.createElement("div", { "data-hook": "labels", className: styles.labels }, this.renderLiveLabel()),
            React.createElement("div", { "data-hook": "central-area", className: styles['central-area'], onClick: onCoverClick },
                this.renderTitle(),
                React.createElement("div", { "data-hook": "central-buttons", className: buttonsClassName, onClick: this.stopPropagation },
                    this.renderPlayButton(),
                    this.renderPurchaseButton()))));
    };
    CoverActionsOverlay.propTypes = {
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        currentSiteUser: PropTypes.object,
        onSignInRequested: PropTypes.func,
        onCoverClick: PropTypes.func,
        onBuyClick: PropTypes.func,
        onSubscribeClick: PropTypes.func,
        onMemberSignUp: PropTypes.func,
        onPlayRequestedAllowed: PropTypes.func,
        onPlayRequestedDisallowed: PropTypes.func,
        showChannelCover: PropTypes.bool,
        isChannelCoverView: PropTypes.bool,
        isFirstVideo: PropTypes.bool,
        isPlaybackModuleLoaded: PropTypes.bool,
        isTextShown: PropTypes.bool,
        title: PropTypes.string,
    };
    CoverActionsOverlay.defaultProps = {
        onSubscribeClick: _.noop,
    };
    CoverActionsOverlay = __decorate([
        connect(function (state) { return ({
            isTextShown: isButtonsTextShown(state),
            isPlaybackModuleLoaded: state.modules.playbackModuleLoaded,
        }); }),
        withStyles(styles)
    ], CoverActionsOverlay);
    return CoverActionsOverlay;
}(React.Component));
export default CoverActionsOverlay;
