import { __decorate, __extends } from "tslib";
import { connect } from 'react-redux';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import React from 'react';
import { Direction } from 'shared/containers/direction';
import HomeLayout from 'mobile/layouts/home/home';
import SEOStructuredData from 'widget/views/seo/seo';
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Component.prototype.componentDidMount = function () {
        this.props.logWidgetSystem('mobile.app.initialized');
    };
    Component.prototype.render = function () {
        return (React.createElement(Direction, null,
            React.createElement(SEOStructuredData, null),
            React.createElement(HomeLayout, null)));
    };
    Component = __decorate([
        connect(null, { logWidgetSystem: logWidgetSystem })
    ], Component);
    return Component;
}(React.Component));
export { Component };
