var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { NAMES } from 'widget/redux/client/actions/modules';
var defaultState = {
    player: true,
};
export default handleActions((_a = {},
    _a[NAMES.PLAYER_MODULE_LOADED] = function (state) { return (__assign(__assign({}, state), { player: true })); },
    _a), defaultState);
