import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
import styles from './animate-crossfade.styl';
// Note: use mock for this component at tests, because of using onTransitionEnd
var AnimateCrossfade = /** @class */ (function (_super) {
    __extends(AnimateCrossfade, _super);
    function AnimateCrossfade() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            nextIndex: 0,
            children: [],
        };
        _this.prevIndex = null;
        _this.updateChildrenForRender = function () {
            // Because of canShowChannelCover stuff
            _this.setState({
                children: _this.props.children,
            });
            _this.props.onAnimationComplete();
        };
        _this.stopPropagation = function (event) {
            event.stopPropagation();
        };
        _this.renderWrappedSlide = function (slide, slideIndex) {
            var _a;
            var activeClassName = _this.props.activeClassName;
            var isActive = _this.isActive(slideIndex);
            var className = classnames(styles.slide, (_a = {},
                _a[styles.active] = isActive,
                _a[activeClassName] = isActive,
                _a[styles.prevActive] = _this.isPrevActive(slideIndex),
                _a));
            return (React.createElement("div", { className: className, onTransitionEnd: isActive ? _this.updateChildrenForRender : _.noop, key: slideIndex },
                React.createElement("div", { className: styles.inner, onTransitionEnd: _this.stopPropagation }, slide)));
        };
        return _this;
    }
    AnimateCrossfade.prototype.UNSAFE_componentWillMount = function () {
        this.handlePropsUpdate(this.props);
    };
    AnimateCrossfade.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        clearTimeout(this.timerId);
        this.handlePropsUpdate(nextProps);
    };
    AnimateCrossfade.prototype.componentWillUnmount = function () {
        clearTimeout(this.timerId);
    };
    AnimateCrossfade.prototype.handlePropsUpdate = function (_a) {
        var _this = this;
        var currentIdIndex = _a.currentIdIndex, nextChildren = _a.children, fadeInOnly = _a.fadeInOnly;
        this.prevIndex = this.props.currentIdIndex;
        var prevChildren = this.props.children;
        if (fadeInOnly && currentIdIndex !== this.props.currentIdIndex) {
            this.props.onAnimationComplete();
        }
        // Because of canShowChannelCover stuff
        this.timerId = setTimeout(function () {
            var children = currentIdIndex === _this.prevIndex ? nextChildren : prevChildren;
            _this.setState({
                children: children,
                nextIndex: currentIdIndex,
            });
        });
    };
    AnimateCrossfade.prototype.isActive = function (slideIndex) {
        return slideIndex === this.state.nextIndex;
    };
    AnimateCrossfade.prototype.isPrevActive = function (slideIndex) {
        return slideIndex === this.prevIndex;
    };
    AnimateCrossfade.prototype.render = function () {
        var _a = this.props, fadeInOnly = _a.fadeInOnly, children = _a.children, currentIdIndex = _a.currentIdIndex;
        return (React.createElement("div", { className: styles.container }, fadeInOnly
            ? this.renderWrappedSlide(children[currentIdIndex], currentIdIndex)
            : _.map(children, this.renderWrappedSlide)));
    };
    AnimateCrossfade.propTypes = {
        children: PropTypes.any,
        currentIdIndex: PropTypes.number,
        onAnimationComplete: PropTypes.func,
        activeClassName: PropTypes.string,
        fadeInOnly: PropTypes.bool,
    };
    AnimateCrossfade.defaultProps = {
        children: [],
        currentIdIndex: 0,
        onAnimationComplete: _.noop,
        fadeInOnly: false,
    };
    AnimateCrossfade = __decorate([
        withStyles(styles)
    ], AnimateCrossfade);
    return AnimateCrossfade;
}(React.Component));
export default AnimateCrossfade;
