import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EDITOR_TYPE } from '@wix/wix-vod-constants/dist/common/editor-type';
import { getSliderHeight, isSliderLayout, getChannelLayout, } from 'shared/selectors/app-settings';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
var ForceSliderHeight = /** @class */ (function (_super) {
    __extends(ForceSliderHeight, _super);
    function ForceSliderHeight(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            height: null,
        };
        return _this;
    }
    ForceSliderHeight.prototype.componentDidMount = function () {
        var _a = this.props, height = _a.height, hydratedEditorType = _a.hydratedEditorType;
        /**
         * ADI ignores default height of our widget saved in numbers.sliderHeight,
         * and sets it's own default height.
         * So we need to change size of parent container after mounting of slider
         * by our own.
         */
        if (hydratedEditorType === EDITOR_TYPE.ADI &&
            window.innerHeight !== height) {
            this.setState({ height: height });
        }
    };
    ForceSliderHeight.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var height = _a.height, isSliderLayout = _a.isSliderLayout, layout = _a.layout, windowSize = _a.windowSize;
        var isHeightChanged = height !== this.props.height;
        var isNextSlider = isSliderLayout && layout !== this.props.layout;
        if (isNextSlider || isHeightChanged) {
            this.setState({ height: height });
        }
        if (windowSize.height === this.state.height) {
            this.setState({ height: null });
        }
    };
    ForceSliderHeight.prototype.render = function () {
        return React.cloneElement(this.props.children, {
            forcedHeight: this.state.height,
        });
    };
    ForceSliderHeight.propTypes = {
        height: PropTypes.number.isRequired,
        windowSize: PropTypes.object,
        children: PropTypes.node,
        isSliderLayout: PropTypes.bool,
        layout: PropTypes.number,
        hydratedEditorType: PropTypes.string,
    };
    ForceSliderHeight = __decorate([
        connect(function (state) { return ({
            height: getSliderHeight(state),
            windowSize: state.windowSize,
            isSliderLayout: isSliderLayout(state),
            layout: getChannelLayout(state),
            hydratedEditorType: getHydratedData(state).editorType,
        }); })
    ], ForceSliderHeight);
    return ForceSliderHeight;
}(React.Component));
export default ForceSliderHeight;
