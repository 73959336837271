import { canSubscribeToChannel } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/channel-access';
import getTranslationData from './translation-data';
import getCallbackFromProps from './callback';
export default function getPaidAccessButtonConfig(props) {
    var channelData = props.channelData;
    if (!canSubscribeToChannel(channelData)) {
        return null;
    }
    var callback = getCallbackFromProps(props);
    var translationData = getTranslationData(channelData);
    return {
        callback: callback,
        translationData: translationData,
    };
}
