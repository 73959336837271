import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { MODAL_CONTENT_TYPES } from 'widget/components/modal/constants';
import styles from './preview-prompt.styl';
export var getOpenPreviewPromptModalConfig = function () { return ({
    contentClassName: styles['modal-content'],
    className: styles.modal,
    closeOnOverlayClick: true,
    title: i18n.t('widget.preview-prompt.preview-mode'),
    contentType: MODAL_CONTENT_TYPES.PREVIEW_PROMPT,
}); };
