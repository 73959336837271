import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import WixEvents from 'shared/constants/wix-sdk';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
var ViewerObserver = /** @class */ (function (_super) {
    __extends(ViewerObserver, _super);
    function ViewerObserver() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stopPlaybackOnPageChange = function (_a) {
            var wasAppOnPage = _a.wasAppOnPage;
            if (wasAppOnPage && _this.props.isVideoPlaying) {
                _this.props.pauseVideo();
            }
        };
        return _this;
    }
    ViewerObserver.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(WixEvents.PAGE_NAVIGATION, this.stopPlaybackOnPageChange, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    ViewerObserver.prototype.render = function () {
        return null;
    };
    ViewerObserver.propTypes = {
        isVideoPlaying: PropTypes.bool.isRequired,
        pauseVideo: PropTypes.func.isRequired,
    };
    ViewerObserver = __decorate([
        withPubSubEvents
    ], ViewerObserver);
    return ViewerObserver;
}(React.Component));
export { ViewerObserver };
