import { BI_EVENT } from 'shared/bi/constants';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { pubSubPublish } from 'shared/worker/actions/pub-sub/publish';
import { createProxy } from 'shared/worker/lib';
export var sendBiEvent = createProxy('sendBiEvent', function (eventName, params) { return function (dispatch, getState) {
    dispatch(pubSubPublish(BI_EVENT, {
        eventName: eventName,
        params: params,
        targetCompId: getCompId(getState()),
    }));
}; });
