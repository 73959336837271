import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import { getChannelIdFromAppSettings } from 'shared/selectors/app-settings';
import { getPaymentInfo } from 'widget/redux/server/actions/channel/payment';
var ReloadChannelPaymentDataOnPayment = /** @class */ (function (_super) {
    __extends(ReloadChannelPaymentDataOnPayment, _super);
    function ReloadChannelPaymentDataOnPayment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fetchChannelPaymentData = _.debounce(function () {
            _this.props.getPaymentInfo(_this.props.channelId, true);
        }, 300);
        return _this;
    }
    ReloadChannelPaymentDataOnPayment.prototype.render = function () {
        return React.createElement(PaymentEvents, { onAnyEvent: this.fetchChannelPaymentData });
    };
    ReloadChannelPaymentDataOnPayment.propTypes = {
        getPaymentInfo: PropTypes.func,
        channelId: PropTypes.string,
    };
    ReloadChannelPaymentDataOnPayment = __decorate([
        connect(function (state) { return ({
            channelId: getChannelIdFromAppSettings(state),
        }); }, {
            getPaymentInfo: getPaymentInfo,
        })
    ], ReloadChannelPaymentDataOnPayment);
    return ReloadChannelPaymentDataOnPayment;
}(React.Component));
export default ReloadChannelPaymentDataOnPayment;
