var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { SET_COMMENTS_OPEN, SET_HAS_NEW_COMMENTS, SET_OPEN_ON_LOAD, } from 'widget/redux/client/actions/comments';
var defaultState = {
    isOpen: false,
    hasNewComments: false,
    openOnLoad: false,
};
export default handleActions((_a = {},
    _a[SET_COMMENTS_OPEN] = function (state, _a) {
        var payload = _a.payload;
        return (__assign(__assign({}, state), { isOpen: payload, openOnLoad: payload ? false : state.openOnLoad }));
    },
    _a[SET_HAS_NEW_COMMENTS] = function (state, _a) {
        var payload = _a.payload;
        return (__assign(__assign({}, state), { hasNewComments: payload }));
    },
    _a[SET_OPEN_ON_LOAD] = function (state, _a) {
        var payload = _a.payload;
        return (__assign(__assign({}, state), { openOnLoad: payload }));
    },
    _a), defaultState);
