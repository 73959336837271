import { __assign, __decorate, __extends } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'shared/utils/withStyles';
import styles from './icon.styl';
import fontelloIconsStyles from 'shared/fontello/css/fontello-codes.css';
var iconStyles = __assign(__assign({}, styles), fontelloIconsStyles);
var Icon = /** @class */ (function (_super) {
    __extends(Icon, _super);
    function Icon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Icon.prototype, "iconStyle", {
        get: function () {
            var size = this.props.size;
            if (!size) {
                return null;
            }
            return {
                fontSize: size,
            };
        },
        enumerable: true,
        configurable: true
    });
    Icon.prototype.render = function () {
        var _a = this.props, name = _a.name, getRef = _a.getRef, dataHook = _a.dataHook, className = _a.className, role = _a.role;
        var props = _.omit(this.props, [
            'styles',
            'styleName',
            'intl',
            'settings',
            'getRef',
            'biEvent',
            'biError',
            'closePortal',
            'children',
            'dataHook',
        ]);
        return (React.createElement("span", __assign({ ref: getRef, "data-hook": dataHook, "aria-hidden": role === 'presentation' ? 'true' : 'false' }, props, { className: classnames(className, iconStyles.icon, iconStyles["icon--" + name]) ||
                null, style: this.iconStyle })));
    };
    Icon.propTypes = {
        size: PropTypes.number,
        name: PropTypes.string,
        className: PropTypes.string,
        getRef: PropTypes.func,
        dataHook: PropTypes.string,
        role: PropTypes.string,
    };
    Icon.defaultProps = {
        getRef: _.noop,
        role: 'presentation',
    };
    Icon = __decorate([
        withStyles(styles),
        withStyles(fontelloIconsStyles)
    ], Icon);
    return Icon;
}(React.PureComponent));
export default Icon;
var RoundedIcon = /** @class */ (function (_super) {
    __extends(RoundedIcon, _super);
    function RoundedIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedIcon.prototype.render = function () {
        var props = _.omit(this.props, 'styles');
        return (React.createElement(Icon, __assign({}, props, { className: classnames(props.className, styles['rounded-icon']) })));
    };
    return RoundedIcon;
}(React.PureComponent));
export { RoundedIcon };
