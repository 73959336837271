import { createWorkerAction } from '../lib';
import { isMobile, isTablet } from 'shared/selectors/form-factor';
export var logFedopsAction = createWorkerAction('LOG_FEDOPS');
export var LOGGER_FNS = {
    INTERACTION_START: 'interaction_start',
    INTERACTION_END: 'interaction_end',
    APP_LOADED: 'app-loaded',
};
var getLoggerName = function (state) {
    if (isMobile(state)) {
        return MOBILE_WIDGET;
    }
    if (isTablet(state)) {
        return TABLET_WIDGET;
    }
    return DESKTOP_WIDGET;
};
export function createLoggerActions(_loggerName) {
    var createAction = function (logFn, interactionName) { return function () { return function (dispatch, getState) {
        var loggerName = _loggerName || getLoggerName(getState());
        dispatch(logFedopsAction({ loggerName: loggerName, logFn: logFn, interactionName: interactionName }));
    }; }; };
    var appLoaded = createAction(LOGGER_FNS.APP_LOADED);
    var createInteraction = function (interactionName) { return ({
        start: createAction(LOGGER_FNS.INTERACTION_START, interactionName),
        end: createAction(LOGGER_FNS.INTERACTION_END, interactionName),
    }); };
    return { appLoaded: appLoaded, createInteraction: createInteraction };
}
export var BASE_LOGGER = 'BASE';
export var MOBILE_OVERLAY = 'MOBILE_OVERLAY';
export var MOBILE_WIDGET = 'MOBILE_WIDGET';
export var DESKTOP_OVERLAY = 'DESKTOP_OVERLAY';
export var DESKTOP_WIDGET = 'DESKTOP_WIDGET';
export var TABLET_WIDGET = 'TABLET_WIDGET';
