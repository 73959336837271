import { buildUrl } from '@wix/wix-vod-shared/dist/src/common/utils/url/build-url';
import { hosts } from '@wix/wix-vod-shared/dist/src/common/settings/settings';
import { openBlankTab } from '@wix/wix-vod-shared/dist/src/common/utils/open-tab';
export default {
    cancelSubscription: function (channelId, siteUrl, instance) {
        openBlankTab(buildUrl(hosts.paypal + "/vod/pp/subscribe/cancel_profile", {
            type: 'subscription',
            channelId: channelId,
            ref: siteUrl,
            instance: instance,
        }));
    },
};
