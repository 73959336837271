import { __decorate, __extends } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import { notForPreview } from 'shared/utils/not-for-preview';
import { handleLogOutPaymentModal } from 'shared/utils/handle-logout-payment-modal';
import PropTypes from 'prop-types';
import { openFullScreenPurchaseOverlay, openFullScreenSubscribeOverlay, openFullScreenRentOverlay, } from 'widget/utils/open-overlay';
var PlayerActionCallbacks = /** @class */ (function (_super) {
    __extends(PlayerActionCallbacks, _super);
    function PlayerActionCallbacks() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showPurchaseInfoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId;
            _this.props.openFullScreenPurchaseOverlay(channelId, videoId);
        });
        _this.showRentInfoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId;
            _this.props.openFullScreenRentOverlay(channelId, videoId);
        });
        _this.showChannelSubscriptionInfoAtOverlay = notForPreview(function () {
            _this.props.openFullScreenSubscribeOverlay(_this.props.channelId);
        });
        return _this;
    }
    PlayerActionCallbacks.prototype.componentDidMount = function () {
        handleLogOutPaymentModal({
            onRent: this.showRentInfoAtOverlay,
            onPurchase: this.showPurchaseInfoAtOverlay,
            onSubscription: this.showChannelSubscriptionInfoAtOverlay,
        });
    };
    PlayerActionCallbacks.prototype.render = function () {
        return React.cloneElement(this.props.children, {
            // TODO: rename to onPurchaseRequest and onSubscriptionRequest
            onPurchaseClick: this.showPurchaseInfoAtOverlay,
            onSubscribeClick: this.showChannelSubscriptionInfoAtOverlay,
            onRentClick: this.showRentInfoAtOverlay,
        });
    };
    PlayerActionCallbacks.propTypes = {
        children: PropTypes.element.isRequired,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
    };
    PlayerActionCallbacks = __decorate([
        connect(null, {
            openFullScreenPurchaseOverlay: openFullScreenPurchaseOverlay,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
            openFullScreenRentOverlay: openFullScreenRentOverlay,
        })
    ], PlayerActionCallbacks);
    return PlayerActionCallbacks;
}(React.Component));
export default PlayerActionCallbacks;
