import { createAction } from 'redux-actions';
import { INITIATORS } from 'shared/utils/initiators';
import { openMobileOverlay } from 'shared/utils/open-overlay';
import { createProxy } from 'shared/worker/lib';
export var OPEN_SUBSCRIBE_OVERLAY = 'OPEN.SUBSCRIBE-OVERLAY';
var OPEN_SUBSCRIBE_OVERLAY_ACTION = createAction(OPEN_SUBSCRIBE_OVERLAY);
export var OPEN_BUY_VIDEO_OVERLAY = 'OPEN.BUY-VIDEO-OVERLAY';
var OPEN_BUY_VIDEO_OVERLAY_ACTION = createAction(OPEN_BUY_VIDEO_OVERLAY);
export var OPEN_PAYMENT_COMPLETE_OVERLAY = 'OPEN.PAYMENT_COMPLETE_OVERLAY';
var OPEN_PAYMENT_COMPLETE_OVERLAY_ACTION = createAction(OPEN_PAYMENT_COMPLETE_OVERLAY);
export var openSubscribeOverlayFromWidget = function () {
    return openSubscribeOverlay(INITIATORS.WIDGET);
};
export var openBuyVideoOverlayFromWidget = function (videoId) {
    return openBuyVideoOverlay(videoId, INITIATORS.WIDGET);
};
export var openSubscribeOverlay = createProxy('openSubscribeOverlay', function (initiator) {
    if (initiator === void 0) { initiator = ''; }
    return function (dispatch) {
        dispatch(OPEN_SUBSCRIBE_OVERLAY_ACTION());
        dispatch(openMobileOverlay("#/channel/subscribe/" + initiator));
    };
});
export var openBuyVideoOverlay = createProxy('openBuyVideoOverlay', function (videoId, initiator) {
    if (initiator === void 0) { initiator = ''; }
    return function (dispatch) {
        dispatch(OPEN_BUY_VIDEO_OVERLAY_ACTION());
        dispatch(openMobileOverlay("#/video/" + videoId + "/purchase/" + initiator));
    };
});
export var openPaymentCompleteOverlay = createProxy('openPaymentCompleteOverlay', function (paymentType, videoId) {
    if (videoId === void 0) { videoId = null; }
    return function (dispatch) {
        dispatch(OPEN_PAYMENT_COMPLETE_OVERLAY_ACTION());
        dispatch(openMobileOverlay("#/payment-complete/" + paymentType + "/" + videoId));
    };
});
