import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import pubsub from 'shared/utils/pubsub';
import Header from './header/header';
import PropTypes from 'prop-types';
import { PrimaryButton, SecondaryButton, } from 'shared/components/button/button';
import HeaderMobile from 'mobile/components/modal/header/header-mobile';
import { DISMISSED } from 'shared/components/modal/utils';
import events from 'shared/constants/events';
import { previewPrompt } from 'shared/components/preview-prompt/preview-prompt.content';
import { withStyles } from 'shared/utils/withStyles';
import styles from './modal.styl';
import stylesMobile from './modal-mobile.styl';
var closeOnOverlayClick = false;
var modalContentTypeToContentMap = __assign({}, previewPrompt);
function injectButtonComponentsIntoConfig(config, buttonComponents) {
    var buttons = config.buttons;
    buttons = _.mapValues(buttons, function (value, key) {
        if (value.Button) {
            return value;
        }
        return __assign(__assign({}, value), { Button: buttonComponents[key] });
    });
    return __assign(__assign({}, config), { buttons: buttons });
}
function buildModalConfig(config, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    // Regular Modal
    config = injectButtonComponentsIntoConfig(config, {
        primary: PrimaryButton,
        secondary: SecondaryButton,
    });
    if (isMobile) {
        config = __assign({ Header: HeaderMobile, className: stylesMobile['modal-mobile'], windowClassName: stylesMobile['modal-mobile-window'], contentClassName: stylesMobile['modal-mobile-content'], closeOnOverlayClick: true }, config);
    }
    if (config.contentType) {
        config.content = modalContentTypeToContentMap[config.contentType];
    }
    return config;
}
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal(props) {
        var _this = _super.call(this, props) || this;
        _this.saveRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.openModal = function (config) {
            config = buildModalConfig(config, _this.props.isMobile);
            _this.setState(__assign({ title: null, closeButtonLabel: null, content: null, Header: Header,
                closeOnOverlayClick: closeOnOverlayClick, className: '', windowClassName: '', contentClassName: '', buttonsClassName: '', buttons: {}, resolve: _.noop, reject: _.noop, noCloseButton: false }, config));
            setTimeout(function () {
                _this.setState({
                    open: true,
                });
            }, 0);
        };
        _this.closeModal = function () {
            return new Promise(function (resolve) {
                _this.setState({ open: false }, function () {
                    setTimeout(function () {
                        _this.setState({ content: null }, resolve);
                    }, 200);
                });
            });
        };
        _this.handleBackdropClick = function (event) {
            var closeOnOverlayClick = _this.state.closeOnOverlayClick;
            if (!event.isDefaultPrevented() && closeOnOverlayClick) {
                _this.$modal.dismiss();
            }
        };
        _this.handleCloseButtonClick = function () {
            _this.$modal.dismiss();
        };
        _this.state = {
            open: false,
            closeOnOverlayClick: closeOnOverlayClick,
            title: null,
            closeButtonLabel: null,
            content: null,
        };
        _this.containerRef = null;
        return _this;
    }
    Modal.prototype.UNSAFE_componentWillMount = function () {
        this.openModalUnsubscribe = pubsub.subscribe(events.MODAL.OPEN, this.openModal);
        this.closeModalUnsubscribe = pubsub.subscribe(events.MODAL.CLOSE, this.closeModal);
    };
    Modal.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.state.open && !prevState.open) {
            if (this.props.useSafeFocus) {
                var containerRefHeight = this.containerRef.style.height;
                this.containerRef.style.height = 0;
                this.containerRef.focus();
                this.containerRef.style.height = containerRefHeight;
            }
            else {
                this.containerRef.focus();
            }
        }
    };
    Modal.prototype.componentWillUnmount = function () {
        this.openModalUnsubscribe();
        this.closeModalUnsubscribe();
    };
    Modal.prototype.stopEventPropagation = function (event) {
        event.preventDefault();
    };
    Object.defineProperty(Modal.prototype, "$modal", {
        get: function () {
            var _this = this;
            var _a = this.state, resolve = _a.resolve, reject = _a.reject;
            return {
                resolve: function (data) {
                    resolve({ data: data, $modal: _this.$modal });
                },
                reject: function (reason) {
                    if (reason === void 0) { reason = DISMISSED; }
                    reject(reason);
                },
                close: function (data) {
                    _this.closeModal().then(function () { return resolve(data); });
                },
                dismiss: function (reason) {
                    if (reason === void 0) { reason = DISMISSED; }
                    _this.closeModal().then(function () { return reject(reason); });
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Modal.prototype, "buttons", {
        get: function () {
            var _this = this;
            var _a = this.state, buttons = _a.buttons, buttonsClassName = _a.buttonsClassName;
            if (!_.keys(buttons).length) {
                return null;
            }
            var keyToDataHookMap = {
                primary: 'confirmation-button',
                secondary: 'cancellation-button',
            };
            return (React.createElement("footer", { className: classnames(buttonsClassName, styles.buttons) }, _.map(buttons, function (_a, key) {
                var Button = _a.Button, label = _a.label, onClick = _a.onClick;
                return (React.createElement(Button, { key: key, dataHook: keyToDataHookMap[key], label: label, type: "button", onClick: memoizedPartial(onClick, _this.$modal), className: styles.button }));
            })));
        },
        enumerable: true,
        configurable: true
    });
    Modal.prototype.render = function () {
        var _a;
        var _b = this.state, open = _b.open, content = _b.content;
        if (!open && !content) {
            return null;
        }
        var _c = this.state, InheritHeader = _c.Header, title = _c.title, closeButtonLabel = _c.closeButtonLabel, className = _c.className, windowClassName = _c.windowClassName, contentClassName = _c.contentClassName;
        var rootClassName = classnames(className, styles.modal, (_a = {},
            _a[styles.open] = open && content,
            _a));
        return (React.createElement("div", { className: rootClassName, onClick: this.handleBackdropClick, role: "presentation" },
            React.createElement("section", { "data-hook": "modal-window", className: classnames(windowClassName, styles.window), role: "dialog", "aria-modal": "true", "aria-label": title, tabIndex: -1, ref: this.saveRef, onClick: this.stopEventPropagation },
                InheritHeader && (React.createElement(InheritHeader, { title: title, closeButtonLabel: closeButtonLabel, onButtonClick: this.handleCloseButtonClick, role: "presentation" })),
                React.createElement("div", { "data-hook": "modal-content", className: classnames(contentClassName, styles.content), role: "presentation" }, _.isString(content)
                    ? content
                    : React.cloneElement(content, { $modal: this.$modal })),
                this.buttons)));
    };
    Modal.propTypes = {
        title: PropTypes.node,
        closeButtonLabel: PropTypes.string,
        content: PropTypes.element,
        className: PropTypes.string,
        windowClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        buttonsClassName: PropTypes.string,
        closeOnOverlayClick: PropTypes.bool,
        useSafeFocus: PropTypes.bool,
        isMobile: PropTypes.bool,
    };
    Modal.defaultProps = {
        closeOnOverlayClick: closeOnOverlayClick,
    };
    Modal = __decorate([
        withStyles(styles),
        withStyles(stylesMobile)
    ], Modal);
    return Modal;
}(React.Component));
export default Modal;
