import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tag from './tag/tag';
import { withStyles } from 'shared/utils/withStyles';
import styles from './tags-cloud.styl';
var TagsCloud = /** @class */ (function (_super) {
    __extends(TagsCloud, _super);
    function TagsCloud() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TagsCloud.prototype.render = function () {
        var _a = this.props, tags = _a.tags, isRounded = _a.isRounded, className = _a.className, tagClassName = _a.tagClassName, roundedTagClassName = _a.roundedTagClassName, onTagClick = _a.onTagClick, onTagFocus = _a.onTagFocus, onTagBlur = _a.onTagBlur;
        return (React.createElement("div", { className: className, "data-hook": "tags-cloud" }, _.map(tags, function (tag) { return (React.createElement(Tag, { key: tag, className: classnames(styles.tag, tagClassName), roundedClassName: roundedTagClassName, text: tag, rounded: isRounded, onClick: onTagClick, onFocus: onTagFocus, onBlur: onTagBlur })); })));
    };
    TagsCloud.propTypes = {
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        onTagFocus: PropTypes.func,
        onTagClick: PropTypes.func,
        onTagBlur: PropTypes.func,
        className: PropTypes.string,
        tagClassName: PropTypes.string,
        roundedTagClassName: PropTypes.string,
        isRounded: PropTypes.bool.isRequired,
    };
    TagsCloud.defaultProps = {
        onTagClick: _.noop,
    };
    TagsCloud = __decorate([
        withStyles(styles)
    ], TagsCloud);
    return TagsCloud;
}(React.Component));
export default TagsCloud;
