import { __extends } from "tslib";
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './slider.styl';
import classNames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
var TIMEOUT = 150;
var Slider = /** @class */ (function (_super) {
    __extends(Slider, _super);
    function Slider(props) {
        var _this = _super.call(this, props) || this;
        _this.prev = function () {
            var _a = _this.state, lastVisibleItemIndex = _a.lastVisibleItemIndex, visibleCount = _a.visibleCount;
            var index = Math.max(lastVisibleItemIndex - (visibleCount - 1) * 2, 0);
            if (index >= 0) {
                _this.scrollToItem(index);
            }
        };
        _this.next = function () {
            var itemsCount = _this.props.itemsCount;
            var lastVisibleItemIndex = _this.state.lastVisibleItemIndex;
            var itemToScrollIndex = Math.min(lastVisibleItemIndex, itemsCount - 1);
            if (itemToScrollIndex < itemsCount) {
                _this.scrollToItem(itemToScrollIndex);
            }
        };
        _this.scrollToItem = function (index) {
            var container = _this.containerRef.current;
            var elementToScroll = container.children[index];
            container.scrollLeft = elementToScroll.offsetLeft;
        };
        _this.checkHorizontalVisibleItems = function () {
            var itemsCount = _this.props.itemsCount;
            var slider = _this.containerRef.current;
            var clientWidth = slider.clientWidth, scrollLeft = slider.scrollLeft;
            var itemWidth = slider.firstChild.clientWidth;
            var scrolledCount = Math.ceil((clientWidth + scrollLeft) / itemWidth);
            var lastVisibleItemIndex = Math.min(scrolledCount - 1, itemsCount - 1);
            _this.visibleItemsChanged(lastVisibleItemIndex);
        };
        _this.checkVerticalVisibleItems = function () {
            var itemsCount = _this.props.itemsCount;
            if (_this.props.vertical) {
                var slider = _this.containerRef.current;
                var itemHeight = slider.firstChild.clientHeight;
                var rect = slider.getBoundingClientRect();
                var height = window.innerHeight - rect.y;
                var visibleCount = Math.ceil(height / itemHeight);
                var lastVisibleItemIndex = Math.min(visibleCount - 1, itemsCount - 1);
                if (lastVisibleItemIndex >= 0) {
                    _this.visibleItemsChanged(lastVisibleItemIndex);
                }
            }
        };
        _this.visibleItemsChanged = function (lastVisibleItemIndex) {
            var lastItemIndex = _this.state.lastItemIndex;
            var state = {
                lastVisibleItemIndex: lastVisibleItemIndex,
            };
            if (lastVisibleItemIndex > lastItemIndex) {
                _this.props.loadMore(lastVisibleItemIndex - lastItemIndex);
                state.lastItemIndex = lastVisibleItemIndex;
            }
            _this.setState(state);
        };
        _this.countVisibleItems = function () {
            var slider = _this.containerRef.current;
            var _a = slider.firstChild, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
            var visibleCount;
            if (_this.props.vertical) {
                visibleCount = Math.ceil(slider.clientHeight / clientHeight);
            }
            else {
                visibleCount = Math.ceil(slider.clientWidth / clientWidth);
            }
            _this.setState({
                visibleCount: visibleCount,
            });
        };
        _this.checkVisibleItems = _.debounce(function () {
            _this.countVisibleItems();
            if (_this.props.vertical) {
                _this.checkVerticalVisibleItems();
            }
            else {
                _this.checkHorizontalVisibleItems();
            }
        }, TIMEOUT);
        _this.handleScroll = _.debounce(_this.checkHorizontalVisibleItems, TIMEOUT);
        _this.handleParentScroll = _.debounce(_this.checkVerticalVisibleItems, TIMEOUT);
        _this.containerRef = React.createRef();
        _this.state = {
            lastVisibleItemIndex: 0,
            lastItemIndex: 0,
            visibleCount: 5,
        };
        return _this;
    }
    Slider.prototype.componentDidMount = function () {
        var subscribeOnParentScroll = this.props.subscribeOnParentScroll;
        if (subscribeOnParentScroll) {
            subscribeOnParentScroll(this.handleParentScroll);
        }
        window.addEventListener('resize', this.checkVisibleItems);
        this.checkVisibleItems();
    };
    Slider.prototype.componentDidUpdate = function (prevProps) {
        var itemsCount = this.props.itemsCount;
        var prevItemsCount = prevProps.itemsCount;
        if (itemsCount !== prevItemsCount) {
            this.checkVisibleItems();
        }
    };
    Slider.prototype.componentWillUnmount = function () {
        var unSubscribeFromParentScroll = this.props.unSubscribeFromParentScroll;
        if (unSubscribeFromParentScroll) {
            unSubscribeFromParentScroll(this.handleParentScroll);
        }
        window.removeEventListener('resize', this.checkVisibleItems);
    };
    Slider.prototype.renderArrows = function () {
        var _a, _b;
        var _c = this.props, itemsCount = _c.itemsCount, vertical = _c.vertical, arrowClassName = _c.arrowClassName;
        var _d = this.state, lastVisibleItemIndex = _d.lastVisibleItemIndex, visibleCount = _d.visibleCount;
        if (vertical || visibleCount > itemsCount) {
            return null;
        }
        var container = this.containerRef.current;
        var isPrevVisible = container && container.scrollLeft > 0;
        var isNextVisible = itemsCount > visibleCount;
        if (lastVisibleItemIndex === itemsCount - 1) {
            var lastItem = container.children[lastVisibleItemIndex];
            isNextVisible =
                container.scrollLeft + container.clientWidth <
                    lastItem.offsetLeft + lastItem.clientWidth;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { onClick: this.prev, className: classNames(styles.arrow, styles.arrowPrev, arrowClassName, (_a = {},
                    _a[styles.arrowVisible] = isPrevVisible,
                    _a)) }),
            React.createElement("div", { onClick: this.next, className: classNames(styles.arrow, styles.arrowNext, arrowClassName, (_b = {},
                    _b[styles.arrowVisible] = isNextVisible,
                    _b)) })));
    };
    Slider.prototype.render = function () {
        var _a;
        var _b = this.props, itemClassName = _b.itemClassName, items = _b.items, itemsCount = _b.itemsCount, renderItem = _b.renderItem, vertical = _b.vertical;
        var _c = this.state, visibleCount = _c.visibleCount, lastItemIndex = _c.lastItemIndex;
        var index = lastItemIndex + visibleCount * 2;
        var arr = _.map(Array(itemsCount).slice(0, index), function (value, index) { return items[index]; });
        return (React.createElement("div", { className: classNames(styles.slider, (_a = {},
                _a[styles.horizontal] = !vertical,
                _a[styles.vertical] = vertical,
                _a)), ref: this.containerRef, onScroll: this.handleScroll },
            _.map(arr, function (item) { return (React.createElement("div", { className: classNames(styles.sliderItem, itemClassName), key: item }, renderItem(item))); }),
            this.renderArrows()));
    };
    Slider.propTypes = {
        items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        itemsCount: PropTypes.number.isRequired,
        renderItem: PropTypes.func,
        getItemKey: PropTypes.func,
        subscribeOnParentScroll: PropTypes.func,
        unSubscribeFromParentScroll: PropTypes.func,
        loadMore: PropTypes.func,
        arrowClassName: PropTypes.string,
        itemClassName: PropTypes.string,
        vertical: PropTypes.bool,
    };
    Slider.defaultProps = {
        loadMore: _.noop,
        subscribeOnParentScroll: _.noop,
        unSubscribeFromParentScroll: _.noop,
    };
    return Slider;
}(React.Component));
export default withStyles(styles)(Slider);
