import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'shared/utils/withStyles';
import styles from './title.styl';
var truncate = function (str, maxLength) {
    if (str === void 0) { str = ''; }
    return str.length <= maxLength ? str : str.slice(0, 40) + "...";
};
var Title = function (_a) {
    var children = _a.children, dataHook = _a.dataHook, maxCharsCount = _a.maxCharsCount;
    return (React.createElement("div", { "data-hook": dataHook, className: styles.container }, truncate(children, maxCharsCount)));
};
Title.propTypes = {
    children: PropTypes.string.isRequired,
    maxCharsCount: PropTypes.number,
    dataHook: PropTypes.string,
};
Title.defaultProps = {
    maxCharsCount: 40,
};
export default withStyles(styles)(Title);
