import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isRTL } from 'shared/selectors/app-settings';
var Direction = /** @class */ (function (_super) {
    __extends(Direction, _super);
    function Direction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Direction.prototype.render = function () {
        var _a = this.props, isRTL = _a.isRTL, children = _a.children;
        var dir = isRTL ? 'rtl' : 'ltr';
        return (React.createElement("div", { "data-hook": "wix-vod-widget-direction-container", dir: dir, style: { height: '100%', position: 'relative', zIndex: 0 } }, children));
    };
    Direction.propTypes = {
        isRTL: PropTypes.bool,
        children: PropTypes.node.isRequired,
    };
    Direction = __decorate([
        connect(function (state) { return ({
            isRTL: isRTL(state),
        }); })
    ], Direction);
    return Direction;
}(React.Component));
export { Direction };
