import { __decorate, __extends } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
var ChannelSubscriptionLabel = /** @class */ (function (_super) {
    __extends(ChannelSubscriptionLabel, _super);
    function ChannelSubscriptionLabel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChannelSubscriptionLabel.prototype.render = function () {
        var subscriptionPriceInfo = this.props.subscriptionPriceInfo;
        return formatMessageWithPrice('widget.channel-actions.subscribe', subscriptionPriceInfo);
    };
    ChannelSubscriptionLabel.propTypes = {
        subscriptionPriceInfo: PropTypes.object,
        dealInfo: PropTypes.array,
    };
    ChannelSubscriptionLabel = __decorate([
        connect(function (state, ownProps) { return ({
            subscriptionPriceInfo: dealInfoHelpers.getSubscriptionPriceInfo(ownProps.dealInfo),
        }); })
    ], ChannelSubscriptionLabel);
    return ChannelSubscriptionLabel;
}(React.Component));
export default ChannelSubscriptionLabel;
