import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WixEvents from 'shared/constants/wix-sdk';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
import { setHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import * as viewModeSelectors from 'widget/selectors/view-mode';
var ViewModeObserver = /** @class */ (function (_super) {
    __extends(ViewModeObserver, _super);
    function ViewModeObserver() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleEditModeChange = function (_a) {
            var editMode = _a.editMode;
            var _b = _this.props, viewMode = _b.viewMode, setHydratedData = _b.setHydratedData, onChange = _b.onChange;
            onChange({ prevViewMode: viewMode, viewMode: editMode });
            setHydratedData({ viewMode: editMode });
        };
        return _this;
    }
    ViewModeObserver.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(WixEvents.EDIT_MODE_CHANGE, this.handleEditModeChange, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    ViewModeObserver.prototype.render = function () {
        return null;
    };
    ViewModeObserver.propTypes = {
        setHydratedData: PropTypes.func.isRequired,
        onChange: PropTypes.func,
        viewMode: PropTypes.string,
    };
    ViewModeObserver.defaultProps = {
        onChange: _.noop,
    };
    ViewModeObserver = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({
            viewMode: viewModeSelectors.getViewMode(state),
        }); }, { setHydratedData: setHydratedData })
    ], ViewModeObserver);
    return ViewModeObserver;
}(React.Component));
export { ViewModeObserver };
