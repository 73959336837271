import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuIcon from 'static/images/menu.svg';
import { withStyles } from 'shared/utils/withStyles';
import styles from './menu-button.styl';
var MenuButton = /** @class */ (function (_super) {
    __extends(MenuButton, _super);
    function MenuButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MenuButton.prototype.render = function () {
        var _a = this.props, onClick = _a.onClick, className = _a.className, ariaLabel = _a.ariaLabel;
        return (React.createElement("button", { className: classnames(styles.button, styles.icon, className), onClick: onClick, "aria-label": ariaLabel, "data-hook": "menu-button" },
            React.createElement(MenuIcon, null)));
    };
    MenuButton.propTypes = {
        onClick: PropTypes.func,
        className: PropTypes.string,
        ariaLabel: PropTypes.string,
    };
    MenuButton = __decorate([
        withStyles(styles)
    ], MenuButton);
    return MenuButton;
}(React.Component));
export default MenuButton;
