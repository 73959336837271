var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { NAMES as VIDEO_FULL_SCREEN_ACTIONS } from 'widget/redux/client/actions/player/set-fullscreen-status';
import { NAMES as VIDEO_SOUND_ACTIONS } from 'widget/redux/client/actions/player/sound';
import { NAMES as AUTOPLAYED_ACTIONS } from 'widget/redux/client/actions/player/set-autoplayed';
import { NAMES as SET_OVERLAY_CONTENT_ACTIONS } from 'widget/redux/client/actions/player/set-overlay-content';
var defaultState = {
    isInFullScreen: false,
    muted: false,
    isVolumeChangedByUser: false,
    autoPlayed: false,
    overlayContent: null,
};
var setFullScreenStatus = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { isInFullScreen: payload }));
};
var setMuted = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { muted: payload }));
};
var volumeChangedByUser = function (state) { return (__assign(__assign({}, state), { isVolumeChangedByUser: true })); };
var setAutoPlayed = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { autoPlayed: payload }));
};
var setOverlayContent = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { overlayContent: payload || null }));
};
export default handleActions((_a = {},
    _a[VIDEO_FULL_SCREEN_ACTIONS.SET] = setFullScreenStatus,
    _a[VIDEO_SOUND_ACTIONS.SET_MUTED] = setMuted,
    _a[VIDEO_SOUND_ACTIONS.SET_VOLUME_CHANGED_BY_USER] = volumeChangedByUser,
    _a[AUTOPLAYED_ACTIONS.SET] = setAutoPlayed,
    _a[SET_OVERLAY_CONTENT_ACTIONS.SET] = setOverlayContent,
    _a), defaultState);
