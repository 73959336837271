import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import { isSubscriptionButtonVisible } from './subscribe-button-text-utils';
/**
 * Is video subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @return {Boolean}
 */
/**
 * Video sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.translation {Object} default is 'widget.overlay-buttons.get-subscription'
 * @return {React.Component}
 */
export default function renderSubscribeButtonText(props) {
    if (!isSubscriptionButtonVisible(props)) {
        return null;
    }
    return formatMessageWithPrice(props.translation || 'widget.overlay-buttons.get-subscription', dealInfoHelpers.getSubscriptionPriceInfo(props.channel.dealInfo));
}
