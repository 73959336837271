var _a, _b, _c;
import { __assign } from "tslib";
import _ from 'lodash';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getAvailabilityStatus, FULL_ACCESS_ACTION_NAMES, OWNERSHIP_STATUSES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import formatMessageWithPrice from '../format-message-with-price';
import formatMessageWithDate from '../format-message-with-date';
// FIXME: these translations looks redundant, it should be enough to have two separate
// key-map translations for cover and player
export var DEFAULT_TRANSLATIONS = (_a = {},
    _a[FULL_ACCESS_ACTION_NAMES.BUY] = 'widget.overlay-buttons.buy',
    _a[FULL_ACCESS_ACTION_NAMES.RENT] = 'widget.overlay-buttons.rent',
    _a[FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT] = 'widget.overlay-buttons.buy-from',
    _a);
var COVER_TRANSLATIONS_MAP = (_b = {},
    _b[FULL_ACCESS_ACTION_NAMES.SUBSCRIBE] = 'widget.channel-actions.subscribe',
    _b[FULL_ACCESS_ACTION_NAMES.BUY] = 'widget.overlay-buttons.buy',
    _b[FULL_ACCESS_ACTION_NAMES.RENT] = 'widget.overlay-buttons.rent',
    _b[FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT] = 'widget.overlay-buttons.buy-from',
    _b);
var PLAYER_TRANSLATIONS_MAP = __assign(__assign({}, COVER_TRANSLATIONS_MAP), (_c = {}, _c[OWNERSHIP_STATUSES.YOU_BOUGHT_THIS] = 'actions.purchased', _c[OWNERSHIP_STATUSES.YOU_RENTED_THIS] = 'actions.rented', _c));
var getBuyRentText = function (_a) {
    var channelData = _a.channelData, videoItem = _a.videoItem, currentSiteUser = _a.currentSiteUser, translations = _a.translations;
    var status = getAvailabilityStatus({
        channelData: channelData,
        videoItem: videoItem,
        currentSiteUser: currentSiteUser,
    });
    var translationString = translations[status];
    if (!translationString) {
        return null;
    }
    var priceModel;
    var rentModel;
    switch (status) {
        case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
            priceModel = dealInfoHelpers.getSubscriptionPriceInfo(channelData.dealInfo);
            return formatMessageWithPrice(translationString, priceModel);
        case FULL_ACCESS_ACTION_NAMES.BUY:
            priceModel = dealInfoHelpers.getSalePriceInfo(videoItem.dealInfo);
            return formatMessageWithPrice(translationString, priceModel);
        case FULL_ACCESS_ACTION_NAMES.RENT:
            priceModel = dealInfoHelpers.getRentPriceInfo(videoItem.dealInfo);
            return formatMessageWithPrice(translationString, priceModel);
        case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
            priceModel = dealInfoHelpers.getMinPriceInfo(videoItem.dealInfo);
            return formatMessageWithPrice(translationString, priceModel);
        case OWNERSHIP_STATUSES.YOU_RENTED_THIS:
            rentModel = _.get(videoItem.dgsInfo, 'rent');
            return formatMessageWithDate(translationString, rentModel.expirationTs * 1000);
        default:
            return i18n.t(translationString);
    }
};
export var getBuyRentTextForPlayer = function (_a) {
    var channelData = _a.channelData, videoItem = _a.videoItem, currentSiteUser = _a.currentSiteUser;
    var params = {
        channelData: channelData,
        videoItem: videoItem,
        currentSiteUser: currentSiteUser,
        translations: PLAYER_TRANSLATIONS_MAP,
    };
    return getBuyRentText(params);
};
