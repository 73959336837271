import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { isShareOverlayOpen } from 'widget/selectors/share-overlay';
import { toggleShareChannelOverlay, toggleShareVideoOverlay, } from 'widget/redux/client/actions/share-overlay';
import { canShowChannelShare } from 'shared/selectors/app-settings';
import { canShareVideo } from 'shared/selectors/video-share';
var OpenShareOverlay = /** @class */ (function (_super) {
    __extends(OpenShareOverlay, _super);
    function OpenShareOverlay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggleShare = function () {
            var canShowChannelShare = _this.props.canShowChannelShare;
            if (canShowChannelShare) {
                _this.props.toggleShareChannelOverlay();
            }
            else {
                _this.props.toggleShareVideoOverlay();
            }
        };
        return _this;
    }
    OpenShareOverlay.prototype.render = function () {
        var _a = this.props, isFocusable = _a.isFocusable, isOpen = _a.isOpen, children = _a.children, canShowChannelShare = _a.canShowChannelShare;
        // TODO: ask Yuriy, do we really need to hide tabindex
        if (!canShowChannelShare) {
            return null;
        }
        return (children &&
            children({
                toggleShare: this.toggleShare,
                tabIndex: !isFocusable || isOpen ? -1 : 0,
                ariaLabel: i18n.t('widget.accessibility.share'),
            }));
    };
    OpenShareOverlay.propTypes = {
        isOpen: PropTypes.bool,
        isFocusable: PropTypes.bool,
        canShowVideoShare: PropTypes.bool,
        canShowChannelShare: PropTypes.bool,
        toggleShareChannelOverlay: PropTypes.func,
        toggleShareVideoOverlay: PropTypes.func,
        children: PropTypes.func,
    };
    OpenShareOverlay.defaultProps = {
        isFocusable: true,
    };
    OpenShareOverlay = __decorate([
        connect(function (state) { return ({
            isOpen: isShareOverlayOpen(state),
            canShowVideoShare: canShareVideo(state),
            canShowChannelShare: canShowChannelShare(state),
        }); }, {
            toggleShareChannelOverlay: toggleShareChannelOverlay,
            toggleShareVideoOverlay: toggleShareVideoOverlay,
        })
    ], OpenShareOverlay);
    return OpenShareOverlay;
}(React.Component));
export default OpenShareOverlay;
