import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notForPreview } from 'shared/utils/not-for-preview';
import { handleLogOutPaymentModal } from 'shared/utils/handle-logout-payment-modal';
import { playVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from 'widget/redux/client/actions/full-screen-modal';
import { openFullScreenPurchaseOverlay, openFullScreenRentOverlay, openFullScreenMemberOnlyOverlay, openFullScreenSubscribeOverlay, } from 'widget/utils/open-overlay';
var InFrameActionCallbacks = /** @class */ (function (_super) {
    __extends(InFrameActionCallbacks, _super);
    function InFrameActionCallbacks() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, videoId = _a.videoId;
            selectVideo(videoId);
        };
        _this.showVideoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay;
            openFullScreenVideoOverlay(channelId, videoId, null, closeFullScreenVideoOverlay);
        });
        _this.showVideoMemberOnlyInfo = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId;
            _this.props.openFullScreenMemberOnlyOverlay(channelId, videoId);
        });
        _this.showVideoPurchaseInfo = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId;
            _this.props.openFullScreenPurchaseOverlay(channelId, videoId);
        });
        _this.showVideoRentInfo = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoId = _a.videoId;
            _this.props.openFullScreenRentOverlay(channelId, videoId);
        });
        _this.showChannelSubscriptionInfoAtOverlay = notForPreview(function () {
            _this.props.openFullScreenSubscribeOverlay(_this.props.channelId);
        });
        _this.handleSelectVideo = function () {
            _this.props.onBeforeClick();
            _this.selectVideo();
        };
        _this.handlePlayVideo = function () {
            _this.props.onBeforePlayRequested();
            _this.props.onPlayRequestedBi();
            _this.playVideo();
        };
        return _this;
    }
    InFrameActionCallbacks.prototype.componentDidMount = function () {
        handleLogOutPaymentModal({
            onRent: this.showVideoRentInfo,
            onPurchase: this.showVideoPurchaseInfo,
            onSubscription: this.showChannelSubscriptionInfoAtOverlay,
        });
    };
    InFrameActionCallbacks.prototype.playVideo = function () {
        var _a = this.props, playVideo = _a.playVideo, videoId = _a.videoId;
        playVideo(videoId);
    };
    InFrameActionCallbacks.prototype.render = function () {
        return React.cloneElement(this.props.children, {
            onClick: this.handleSelectVideo,
            onPlayRequest: this.handlePlayVideo,
            onPlayMemberOnlyRequest: this.showVideoMemberOnlyInfo,
            onPlayWithoutPreviewRequest: this.handleSelectVideo,
            onPurchaseRequest: this.showVideoPurchaseInfo,
            onRentRequest: this.showVideoRentInfo,
            onSubscriptionRequest: this.showChannelSubscriptionInfoAtOverlay,
        });
    };
    InFrameActionCallbacks.propTypes = {
        children: PropTypes.element.isRequired,
        selectVideo: PropTypes.func.isRequired,
        playVideo: PropTypes.func.isRequired,
        onBeforeClick: PropTypes.func,
        onBeforePlayRequested: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        videoItem: PropTypes.object,
    };
    InFrameActionCallbacks.defaultProps = {
        onBeforeClick: _.noop,
        onBeforePlayRequested: _.noop,
        onPlayRequestedBi: _.noop,
    };
    InFrameActionCallbacks = __decorate([
        connect(null, {
            playVideo: playVideo,
            selectVideo: selectVideo,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
            openFullScreenPurchaseOverlay: openFullScreenPurchaseOverlay,
            openFullScreenRentOverlay: openFullScreenRentOverlay,
            openFullScreenMemberOnlyOverlay: openFullScreenMemberOnlyOverlay,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
        })
    ], InFrameActionCallbacks);
    return InFrameActionCallbacks;
}(React.Component));
export default InFrameActionCallbacks;
