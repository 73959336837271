import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import Select from '@wix/wix-vod-shared/dist/src/widget/ui-components/select/select';
import { withStyles } from 'shared/utils/withStyles';
import styles from './embed-styles.styl';
var EmbedSelect = /** @class */ (function (_super) {
    __extends(EmbedSelect, _super);
    function EmbedSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e) {
            _this.props.onChange(e.target.value);
        };
        return _this;
    }
    EmbedSelect.prototype.render = function () {
        var _a = this.props, options = _a.options, selected = _a.selected, dataHook = _a.dataHook, ariaLabel = _a.ariaLabel, isRTL = _a.isRTL;
        return (React.createElement(Select, { dataHook: dataHook, ariaLabel: ariaLabel, className: styles.select, selected: selected, onChange: this.handleChange, options: options, isRTL: isRTL }));
    };
    EmbedSelect.propTypes = {
        dataHook: PropTypes.string,
        ariaLabel: PropTypes.string,
        selected: PropTypes.string,
        options: PropTypes.array,
        onChange: PropTypes.func,
        isRTL: PropTypes.bool,
    };
    EmbedSelect = __decorate([
        withStyles(styles)
    ], EmbedSelect);
    return EmbedSelect;
}(React.Component));
export default EmbedSelect;
