import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { areElementsRounded } from 'shared/selectors/app-settings';
import { GenericButton } from 'shared/components/button/button';
import { withStyles } from 'shared/utils/withStyles';
import styles from './button.styl';
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Button.prototype.render = function () {
        var _a;
        var _b = this.props, isRounded = _b.isRounded, disableRoundBorders = _b.disableRoundBorders, disableTheme = _b.disableTheme, className = _b.className;
        var props = _.omit(this.props, 'isRounded', 'styles', 'dispatch', 'disableRoundBorders', 'disableTheme');
        var styleNames = classnames(className, styles.button, (_a = {},
            _a[styles['disable-theme']] = disableTheme,
            _a[styles.rounded] = !disableRoundBorders && isRounded,
            _a));
        return React.createElement(GenericButton, __assign({}, props, { className: styleNames }));
    };
    Button.propTypes = {
        isRounded: PropTypes.bool,
        disableRoundBorders: PropTypes.bool,
        disableTheme: PropTypes.bool,
        className: PropTypes.string,
        dataHook: PropTypes.string,
    };
    Button.defaultProps = {
        disableRoundBorders: false,
        disableTheme: false,
    };
    Button = __decorate([
        connect(function (state) { return ({
            isRounded: areElementsRounded(state),
        }); }),
        withStyles(styles)
    ], Button);
    return Button;
}(React.Component));
export default Button;
