import _ from 'lodash';
import { normalize } from 'normalizr';
import { channel, paymentInfoChannel, video, arrayOfVideos, arrayOfPaymentInfoVideos, paymentInfoChannelAndVideos, } from 'shared/schemas';
export function getResult(state, _a) {
    var payload = _a.payload;
    return _.get(payload, 'response.data.result');
}
export function normalizeVideo(data) {
    return normalize(data, video);
}
export function normalizeArrayOfVideos(data) {
    return normalize(data, arrayOfVideos);
}
export function normalizeArrayOfPaymentInfoVideos(data) {
    return normalize(data, arrayOfPaymentInfoVideos);
}
export function normalizeChannel(data) {
    return normalize(data, channel);
}
export function normalizePaymentInfoChannel(data) {
    return normalize(data, paymentInfoChannel);
}
export function normalizePaymentInfoGql(data) {
    return normalize(data, paymentInfoChannelAndVideos);
}
