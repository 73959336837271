import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WixSDK from 'shared/utils/wix-sdk';
import { updateAppSettings } from 'widget/redux/client/actions/app-settings';
import { getChannelIdFromAppSettings } from 'shared/selectors/app-settings';
import { getChannelId } from 'shared/utils/app-settings';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import WixEvents from 'shared/constants/wix-sdk';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
var AppSettingsObserver = /** @class */ (function (_super) {
    __extends(AppSettingsObserver, _super);
    function AppSettingsObserver() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleEditModeChange = _.debounce(function (_a) {
            var editMode = _a.editMode;
            _this.props.updateAppSettings({ editMode: editMode });
        }, 100);
        _this.handleStylesChange = _.debounce(function (styleParams) {
            var _a = _this.props, currentChannelId = _a.currentChannelId, isSiteMode = _a.isSiteMode;
            WixSDK.Styles.extendStylesWithInitialChannelId(styleParams).then(function (updatedStyles) {
                var isChanged = currentChannelId !== getChannelId(updatedStyles);
                if (isSiteMode) {
                    return;
                }
                if (isChanged) {
                    window.location.reload();
                }
                else {
                    _this.props.updateAppSettings(updatedStyles);
                }
            });
        }, 100);
        return _this;
    }
    AppSettingsObserver.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(WixEvents.STYLE_PARAMS_CHANGE, this.handleStylesChange, {
            prevProps: prevProps,
            nextProps: this.props,
        });
        consumePubSubEvent(WixEvents.EDIT_MODE_CHANGE, this.handleEditModeChange, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    AppSettingsObserver.prototype.render = function () {
        var _a = this.props, children = _a.children, appSettings = _a.appSettings;
        if (children) {
            return children(appSettings);
        }
        return null;
    };
    AppSettingsObserver.propTypes = {
        children: PropTypes.func,
        appSettings: PropTypes.object,
        isSiteMode: PropTypes.bool.isRequired,
        currentChannelId: PropTypes.string,
        updateAppSettings: PropTypes.func.isRequired,
    };
    AppSettingsObserver = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({
            appSettings: state.appSettings,
            currentChannelId: getChannelIdFromAppSettings(state),
            isSiteMode: viewModeSelectors.isSiteMode(state),
        }); }, {
            updateAppSettings: updateAppSettings,
        })
    ], AppSettingsObserver);
    return AppSettingsObserver;
}(React.Component));
export { AppSettingsObserver };
