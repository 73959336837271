import { createWorkerAction, createProxy } from '../lib';
import userTransactions from 'shared/bi/user-transactions';
export var logBi = createWorkerAction('LOG_BI');
export var logWidgetSystem = createProxy('bi.logWidgetSystem', function (eventName, _a) {
    var previousEventName = (_a === void 0 ? {} : _a).previousEventName;
    return function (dispatch) {
        if (userTransactions.isTransaction({ eventName: eventName, previousEventName: previousEventName })) {
            var shouldSendEvent = userTransactions.getTransactionResult({
                eventName: eventName,
            });
            if (!shouldSendEvent) {
                return;
            }
        }
        dispatch(logBi('widget-system', {
            eventName: eventName,
        }));
    };
});
