import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { Picture } from '@wix/wix-vod-shared/dist/src/common/components/picture';
import { withStyles } from 'shared/utils/withStyles';
import styles from './video-cover.styl';
var VideoCover = /** @class */ (function (_super) {
    __extends(VideoCover, _super);
    function VideoCover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoCover.prototype.render = function () {
        var _a = this.props, videoItem = _a.videoItem, className = _a.className, breakpoints = _a.breakpoints;
        return (React.createElement("div", { className: classNames(styles.cover, className) },
            React.createElement(Picture, { alt: videoItem.title, src: getVideoCoverUrl(videoItem), breakpoints: breakpoints })));
    };
    VideoCover.propTypes = {
        videoItem: PropTypes.object.isRequired,
        breakpoints: PropTypes.array.isRequired,
        className: PropTypes.string,
    };
    VideoCover = __decorate([
        withStyles(styles)
    ], VideoCover);
    return VideoCover;
}(React.Component));
export default VideoCover;
