import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFeaturedVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { canShowCancelSubscription, canShowSubscription, } from 'widget/selectors/subscription';
import { getMainVideo } from 'widget/selectors/get-video';
import { isVideoPlayingOptimistic, isVideoPausedOptimistic, } from 'widget/selectors/video-playback-status';
import { canShowChannelShare, getCanShowChannelInfo, getCanShowSignIn, canShowChannelTitle, isVerticalLayoutMobile, } from 'shared/selectors/app-settings';
import { canShowChannelCategories } from 'widget/selectors/categories';
import { getOverlayContent } from 'shared/selectors/player';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';
import INFO_OVERLAY_CONTENT from 'widget/player-widget/components/new-player/containers/animated-overlay-content/info-overlay-content-enums';
import { VERTICAL_LAYOUT_HEADER_HEIGHT, VERTICAL_LAYOUT_VIDEO_HEIGHT, VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM, VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT, DEFAULT_WIDGET_HEIGHT, DEFAULT_WIDGET_WIDTH, } from 'widget/constants/sizes';
import { getChannelVideos, getCurrentPageIndex, getVideosPerPageCount, } from 'widget/redux/client/lazy-channel-videos/selectors';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { isPortableDevice } from 'shared/selectors/form-factor';
export var canShowActionBar = createSelector(canShowChannelTitle, getCanShowSignIn, getCanShowChannelInfo, canShowChannelShare, canShowCancelSubscription, canShowSubscription, canShowChannelCategories, function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return _.some(args);
});
export var showAutoPlay = createSelector(getMainVideo, isPortableDevice, function (mainVideo, isPortableDevice) {
    return !!mainVideo &&
        !(mainVideo.videoSource === VIDEO_SOURCES.FACEBOOK && isPortableDevice) &&
        !(mainVideo.videoSource === VIDEO_SOURCES.VIMEO && isPortableDevice);
});
export var isAllCardsOverlayVisible = createSelector(getOverlayContent, function (overlayContent) { return overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS; });
export var isPlayerActive = createSelector(isAllCardsOverlayVisible, isVideoPlayingOptimistic, isVideoPausedOptimistic, function (isAllCardsOverlayVisible, isVideoPlaying, isVideoPaused) {
    return isAllCardsOverlayVisible || isVideoPlaying || isVideoPaused;
});
export var getVerticalVisibleVideos = createSelector(getCurrentPageIndex, getChannelVideos, getFeaturedVideoId, getVideosPerPageCount, function (currentPageIndex, channelVideos, featuredVideoId, videosPerPage) {
    if (featuredVideoId) {
        var featuredVideoIndex = _.findIndex(channelVideos, function (_a) {
            var id = _a.id;
            return id === featuredVideoId;
        });
        var featuredVideo = channelVideos[featuredVideoIndex];
        channelVideos.splice(featuredVideoIndex, 1);
        channelVideos.unshift(featuredVideo);
    }
    return _.slice(channelVideos, 0, (currentPageIndex + 1) * videosPerPage);
});
export var getWidgetSize = createSelector(isVerticalLayoutMobile, getVerticalVisibleVideos, function (state) { return state.windowSize; }, viewModeSelectors.isSiteMode, function (isVertical, verticalVisibleVideos, windowSize, isSite) {
    if (isVertical) {
        var visibleVideosCount = verticalVisibleVideos.length;
        var width = DEFAULT_WIDGET_WIDTH;
        var height = DEFAULT_WIDGET_HEIGHT;
        if (visibleVideosCount > 0) {
            height =
                VERTICAL_LAYOUT_HEADER_HEIGHT +
                    visibleVideosCount *
                        (VERTICAL_LAYOUT_VIDEO_HEIGHT +
                            VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM) +
                    VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT;
        }
        return {
            height: height,
            width: width,
        };
    }
    // Previously users had a possibility to resize widget height. Now widget calculates height by itself.
    // But some users had already developed site layouts based on old widget height.
    // So, we do not touch widget height in view mode unless user will not try to change it in edit mode,
    // then widget will recalculate height.
    if (isSite) {
        var height = windowSize.height, width = windowSize.width;
        return {
            height: Math.max(height, DEFAULT_WIDGET_HEIGHT),
            width: width,
        };
    }
    return {
        width: DEFAULT_WIDGET_WIDTH,
        height: DEFAULT_WIDGET_HEIGHT,
    };
});
export var getShareModalTopPosition = function (videoIndex) {
    return VERTICAL_LAYOUT_HEADER_HEIGHT +
        videoIndex *
            (VERTICAL_LAYOUT_VIDEO_HEIGHT + VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM) +
        VERTICAL_LAYOUT_VIDEO_HEIGHT / 2;
};
