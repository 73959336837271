import { __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ShareIcon from './share-icon.svg';
import { withStyles } from 'shared/utils/withStyles';
import styles from './styles.styl';
var ShareButton = /** @class */ (function (_super) {
    __extends(ShareButton, _super);
    function ShareButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShareButton.prototype.render = function () {
        var _a = this.props, className = _a.className, iconClassName = _a.iconClassName, onClick = _a.onClick, tabIndex = _a.tabIndex, ariaLabel = _a.ariaLabel, children = _a.children, icon = _a.icon;
        var Icon = icon || ShareIcon;
        return (React.createElement("button", { className: classnames(styles['share-button'], className), tabIndex: tabIndex, "data-hook": "share-overlay-button", onClick: onClick, "aria-label": ariaLabel },
            React.createElement(Icon, { className: classnames(styles['share-icon'], iconClassName) }),
            children));
    };
    ShareButton.propTypes = {
        className: PropTypes.string,
        icon: PropTypes.func,
        iconClassName: PropTypes.string,
        onClick: PropTypes.func,
        tabIndex: PropTypes.number,
        ariaLabel: PropTypes.string,
        children: PropTypes.node,
        canShowChannelShare: PropTypes.bool,
    };
    ShareButton.defaultProps = {
        tabIndex: 0,
    };
    ShareButton = __decorate([
        withStyles(styles)
    ], ShareButton);
    return ShareButton;
}(React.Component));
export default ShareButton;
