import React from 'react';
import { Route } from 'react-router';
import LayoutSwitcher from 'widget/layouts/layout-switcher';
import { Direction } from 'shared/containers/direction';
import { widgetWrapper } from 'shared/worker/widgetWrapper';
import { createStore } from './widget.store';
function Component() {
    return (React.createElement(Direction, null,
        React.createElement(Route, { path: "/", component: LayoutSwitcher })));
}
export var WidgetComponent = widgetWrapper({
    createStore: createStore,
    Component: Component,
});
export default {
    component: WidgetComponent,
};
