import { __decorate, __extends } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import { logBi } from 'shared/worker/actions/bi';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
import { BI_EVENT } from 'shared/bi/constants';
var BiHandler = /** @class */ (function (_super) {
    __extends(BiHandler, _super);
    function BiHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sendBiEvent = function (_a) {
            var data = _a.data;
            var eventName = data.eventName, params = data.params, targetCompId = data.targetCompId;
            if (targetCompId === _this.props.currentCompId) {
                _this.props.logBi(eventName, params);
            }
        };
        return _this;
    }
    BiHandler.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(BI_EVENT, this.sendBiEvent, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    BiHandler.prototype.render = function () {
        return null;
    };
    BiHandler = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({
            currentCompId: getCompId(state),
        }); }, { logBi: logBi })
    ], BiHandler);
    return BiHandler;
}(React.Component));
export default BiHandler;
