import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { notForPreview } from 'shared/utils/not-for-preview';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import { openFullScreenSubscribeOverlay } from 'widget/utils/open-overlay';
import { logChannelCoverButtonClicked, logSubscriptionPopupClosed, } from 'widget/components/player-overlay/bi';
import { createProxy } from 'shared/worker/lib';
var OPEN_SUBSCRIPTION = 'CLIENT.PLAYER_OVERLAY.OPEN_SUBSCRIPTION';
var openSubscriptionAction = createAction(OPEN_SUBSCRIPTION);
export var openSubscription = createProxy('openSubscription', function () { return function (dispatch, getState) {
    var state = getState();
    var channelData = getChannelForWidget(state);
    notForPreview(function () {
        dispatch(sendBiEvent('widget.subscription.clicked'));
        dispatch(logChannelCoverButtonClicked('subscribe'));
        dispatch(openFullScreenSubscribeOverlay(channelData.id, function () {
            return dispatch(logSubscriptionPopupClosed());
        }));
    })();
    dispatch(openSubscriptionAction());
}; });
