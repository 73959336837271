import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInstance } from 'widget/redux/client/hydrated-data/hydrated-data';
import VideoPreview from './video-preview/video-preview';
import { Picture } from '@wix/wix-vod-shared/dist/src/common/components/picture';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { isPortableDevice } from 'shared/selectors/form-factor';
import { MAX_COVER_IMAGE_WIDTH, MAX_COVER_IMAGE_HEIGHT, } from 'widget/constants/sizes';
var ChannelCover = /** @class */ (function (_super) {
    __extends(ChannelCover, _super);
    function ChannelCover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChannelCover.prototype.shouldRenderVideoPreview = function () {
        var _a = this.props, isEditorViewMode = _a.isEditorViewMode, isMobileDevice = _a.isMobileDevice;
        return this.isVideoPreview() && !isEditorViewMode && !isMobileDevice;
    };
    ChannelCover.prototype.isVideoPreview = function () {
        var _a = this.props, showChannelCover = _a.showChannelCover, mediaInfo = _a.mediaInfo;
        return Boolean(showChannelCover && mediaInfo);
    };
    ChannelCover.prototype.getImageCover = function () {
        var _a = this.props, _b = _a.width, width = _b === void 0 ? MAX_COVER_IMAGE_WIDTH : _b, _c = _a.height, height = _c === void 0 ? MAX_COVER_IMAGE_HEIGHT : _c, posterUrl = _a.posterUrl, channelTitle = _a.channelTitle, videoTitle = _a.videoTitle, showChannelCover = _a.showChannelCover;
        return (React.createElement(Picture, { dataHook: "image-cover", alt: showChannelCover ? channelTitle : videoTitle, key: posterUrl, src: posterUrl, breakpoints: [
                {
                    min: 0,
                    width: width,
                    height: height,
                },
            ] }));
    };
    ChannelCover.prototype.render = function () {
        var _a = this.props, width = _a.width, height = _a.height, channelId = _a.channelId, instance = _a.instance;
        if (this.shouldRenderVideoPreview()) {
            return (React.createElement(VideoPreview, { dataHook: "video-preview", channelId: channelId, instance: instance, poster: this.getImageCover(), width: width, height: height }));
        }
        return this.getImageCover();
    };
    ChannelCover.propTypes = {
        channelId: PropTypes.string,
        channelTitle: PropTypes.string,
        videoTitle: PropTypes.string,
        instance: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        posterUrl: PropTypes.string,
        mediaInfo: PropTypes.object,
        isEditorViewMode: PropTypes.bool,
        isMobileDevice: PropTypes.bool,
        showChannelCover: PropTypes.bool,
    };
    ChannelCover = __decorate([
        connect(function (state) { return ({
            instance: getInstance(state),
            isEditorViewMode: viewModeSelectors.isEditorMode(state),
            isMobileDevice: isPortableDevice(state),
        }); })
    ], ChannelCover);
    return ChannelCover;
}(React.Component));
export default ChannelCover;
