import { createLoggerActions } from 'shared/worker/actions/fedopsLogger';
export function createBaseDesktopLogger(loggerName) {
    var _a = createLoggerActions(loggerName), appLoaded = _a.appLoaded, createInteraction = _a.createInteraction;
    return {
        appLoaded: appLoaded,
        loadCashierModal: createInteraction('CASHIER_MODAL_LOAD'),
        loadCashierPaymentPage: createInteraction('CASHIER_PAYMENT_PAGE_LOAD'),
        loadCashierPaymentCompletePage: createInteraction('CASHIER_PAYMENT_COMPLETE_PAGE_LOAD'),
    };
}
