import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isSliderLayout, isGridLayout } from 'shared/selectors/app-settings';
import { createSelector } from 'reselect';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getVideoIdToSelect, videoToSelectExists, } from 'widget/selectors/video-id-to-select';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from './full-screen-modal';
var isPlayAllowed = createSelector([
    getVideoIdToSelect,
    getChannelForWidget,
    getCurrentSiteUser,
    getVideosGroupedByIds,
], function (videoIdToSelect, channelData, currentSiteUser, videos) {
    return canPlayFullOrPreview(channelData, videos[videoIdToSelect], currentSiteUser);
});
export var openFullScreenVideoOnMount = function () { return function (dispatch, getState) {
    var state = getState();
    if (!videoToSelectExists(state)) {
        return;
    }
    if (!(isSliderLayout(state) || isGridLayout(state))) {
        return;
    }
    var videoIdToSelect = getVideoIdToSelect(state);
    var channelData = getChannelForWidget(state);
    var isAutoPlayable = isPlayAllowed(state);
    dispatch(openFullScreenVideoOverlay(channelData.id, videoIdToSelect, isAutoPlayable, function () { return dispatch(closeFullScreenVideoOverlay()); }));
}; };
