import { createAction } from 'redux-actions';
import { isVolumeChangedByUser } from 'shared/selectors/player';
import { createProxy } from 'shared/worker/lib';
export var NAMES = {
    SET_MUTED: 'CLIENT.VIDEO.SOUND.SET_MUTED',
    SET_VOLUME_CHANGED_BY_USER: 'CLIENT.VIDEO.SOUND.SET_VOLUME_CHANGED_BY_USER',
};
var setVolumeChangedByUserAction = createAction(NAMES.SET_VOLUME_CHANGED_BY_USER);
export var setMuted = createAction(NAMES.SET_MUTED);
export var setVolumeChangedByUser = createProxy('setVolumeChangedByUser', function () { return function (dispatch, getState) {
    if (!isVolumeChangedByUser(getState())) {
        dispatch(setVolumeChangedByUserAction());
    }
}; });
