import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fedopsLogger from 'loggers/fedops/mobile-widget';
import { visitChannel } from 'widget/redux/client/actions/mobile-overlay-openners/main-openners';
import { logBi } from 'shared/worker/actions/bi';
import { openSubscribeOverlay } from 'widget/redux/client/actions/mobile-overlay-openners/payment-pages-actions';
import SlideMenuButton from 'mobile/views/home/components/buttons/slide-menu-button';
import { logOutCurrentMember, requestLogin } from 'shared/utils/auth';
import { notForPreview } from 'shared/utils/not-for-preview';
import { getVisibleMenuItems, ALL_MENU_ITEMS, } from 'mobile/views/home/ui-selectors/slide-menu';
import { subscribeButtonText } from 'mobile/views/home/ui-selectors/buttons';
import ShareMobile from 'mobile/components/share-mobile/share-mobile';
import { titleCase } from 'mobile/utils/capitalize';
import { closeSlideMenu } from 'mobile/redux/actions/slide-menu';
import { withStyles } from 'shared/utils/withStyles';
import styles from './slide-menu.styl';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var SlideMenu = /** @class */ (function (_super) {
    __extends(SlideMenu, _super);
    function SlideMenu(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.renderMenuItems = function () {
            var visibleMenuItems = _this.props.visibleMenuItems;
            var nullFn = function () { return null; };
            return _.map(visibleMenuItems, function (item) {
                return _.get(_this.menuItemsRenderMap, item, nullFn)();
            });
        };
        _this.renderItem = function (item) {
            var text = item.text || titleCase(i18n.t(item.title));
            return (React.createElement("div", { "data-hook": item.dataHook, key: item.dataHook, className: styles['menu-item'], onClick: item.onClick, role: "button", "aria-label": text }, text));
        };
        _this.visitChannel = function () {
            _this.props.visitChannel();
        };
        _this.renderVisitChannel = function () {
            return _this.renderItem({
                dataHook: 'visit-channel-menu-item',
                title: 'widget.mobile.overlay-button.visit-channel',
                onClick: _this.visitChannel,
            });
        };
        _this.renderSubscribe = function () {
            var subscribeButtonText = _this.props.subscribeButtonText;
            return _this.renderItem({
                dataHook: 'subscribe-menu-item',
                text: subscribeButtonText,
                onClick: _this.props.openSubscribeOverlay,
            });
        };
        _this.renderSignOut = function () {
            return _this.renderItem({
                dataHook: 'sign-out-menu-item',
                title: 'widget.mobile.overlay-button.sign-out',
                onClick: _this.props.logOutCurrentMember,
            });
        };
        _this.renderSignIn = function () {
            _this.props.logBi('widget.signIn.clicked');
            return _this.renderItem({
                dataHook: 'sign-in-menu-item',
                title: 'widget.mobile.overlay-button.sign-in',
                onClick: notForPreview(function () { return _this.props.requestLogin(); }),
            });
        };
        _this.renderChannelShare = function () {
            var channelData = _this.props.channelData;
            return (React.createElement(ShareMobile, { channelData: channelData, key: "share-channel-menu-item", dataHook: "share-channel-menu-item" }, _this.renderItem({
                dataHook: 'share-menu-item',
                title: 'share-overlay.social.title',
            })));
        };
        _this.renderVideoShare = function () {
            var _a = _this.props, channelData = _a.channelData, videoItem = _a.videoItem;
            return (React.createElement(ShareMobile, { videoItem: videoItem, channelData: channelData, key: "share-video-menu-item", dataHook: "share-video-menu-item" }, _this.renderItem({
                dataHook: 'share-menu-item',
                title: 'share-overlay.social.title',
            })));
        };
        _this.menuItemsRenderMap = (_a = {},
            _a[ALL_MENU_ITEMS.VISIT_CHANNEL] = _this.renderVisitChannel,
            _a[ALL_MENU_ITEMS.SUBSCRIBE] = _this.renderSubscribe,
            _a[ALL_MENU_ITEMS.SIGN_OUT] = _this.renderSignOut,
            _a[ALL_MENU_ITEMS.SIGN_IN] = _this.renderSignIn,
            _a[ALL_MENU_ITEMS.SHARE_CHANNEL] = _this.renderChannelShare,
            _a[ALL_MENU_ITEMS.SHARE_VIDEO] = _this.renderVideoShare,
            _a);
        return _this;
    }
    SlideMenu.prototype.componentDidMount = function () {
        this.props.logOpenSlideMenuEnd();
    };
    SlideMenu.prototype.render = function () {
        var _a;
        var _b = this.props, closeSlideMenu = _b.closeSlideMenu, showMenuButton = _b.showMenuButton, stretchToHeight = _b.stretchToHeight, menuClassName = _b.menuClassName;
        return (React.createElement("div", { className: styles['menu-wrapper'] },
            React.createElement("div", { "data-hook": "menu-close-area", className: styles['menu-close-area'], onClick: closeSlideMenu }),
            React.createElement("div", { "data-hook": "slide-menu", className: classnames(styles.menu, menuClassName, styles['with-mobile-colors'], (_a = {},
                    _a[styles['stretch-to-height']] = stretchToHeight,
                    _a)) },
                showMenuButton && (React.createElement(SlideMenuButton, { onClick: closeSlideMenu, className: styles['menu-icon-wrapper'], iconClassName: styles['menu-icon'], alwaysShow: true })),
                React.createElement("div", { className: styles['menu-items'] }, this.renderMenuItems()))));
    };
    SlideMenu.propTypes = {
        videoItem: PropTypes.object,
        channelData: PropTypes.object.isRequired,
        subscribeButtonText: PropTypes.object,
        isFirstVideo: PropTypes.bool,
        showMenuButton: PropTypes.bool,
        stretchToHeight: PropTypes.bool,
        visibleMenuItems: PropTypes.array,
        onMemberSignUp: PropTypes.func,
        closeSlideMenu: PropTypes.func,
        visitChannel: PropTypes.func,
        logOutCurrentMember: PropTypes.func.isRequired,
        requestLogin: PropTypes.func.isRequired,
        openSubscribeOverlay: PropTypes.func,
        menuClassName: PropTypes.string,
    };
    SlideMenu.defaultProps = {
        showMenuButton: true,
    };
    SlideMenu = __decorate([
        connect(function (state, props) { return ({
            subscribeButtonText: subscribeButtonText(state, props),
            visibleMenuItems: getVisibleMenuItems(state, props),
        }); }, {
            closeSlideMenu: closeSlideMenu,
            visitChannel: visitChannel,
            openSubscribeOverlay: openSubscribeOverlay,
            logBi: logBi,
            logOutCurrentMember: logOutCurrentMember,
            requestLogin: requestLogin,
            logOpenSlideMenuEnd: fedopsLogger.openSlideMenu.end,
        }),
        withStyles(styles)
    ], SlideMenu);
    return SlideMenu;
}(React.Component));
export default SlideMenu;
