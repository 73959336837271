import { createClient } from '@wix/wix-vod-gql-api/dist/src/client/client';
import { PRIVATE_API_URL } from '@wix/wix-vod-gql-api/dist/src/constants';
import WixSDK from 'shared/utils/wix-sdk';
var privateOptions = {
    get headers() {
        return {
            Authorization: WixSDK.Utils.getInstance(),
        };
    },
};
export var privateClient = createClient(PRIVATE_API_URL, privateOptions);
