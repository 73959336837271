import { __assign, __decorate, __extends, __read, __spread } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNavigationArrowsOutside, getStretchToFullWidth, canShowVideoListItemTitle, getVideoListPublisherVisibility, canShowVideoListItemDescription, canShowVideoListItemContentBelow, getUseOptimalFontSizes, getTitleFont, getTextFont, } from 'shared/selectors/app-settings';
import { canShowActionBar } from 'widget/selectors/layout';
import { getSliderHorizontalMargin } from 'widget/layouts/slider/selectors';
import { getItemWidthForSliderHeight, getSliderHorizontalPadding, getActionBarHeightForWidgetHeight, } from 'widget/layouts/slider/utils/layout-calculations';
import { SLIDER_PADDING } from 'widget/layouts/slider/constants';
import { WIDTH_CONSTRAINTS } from 'widget/constants/thumbnail-sizes';
import { VIDEOS_ASPECT_RATIO } from 'widget/constants/videos-aspect-ratio';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import { getContentHeight, THUMBNAIL_WIDTH_BREAKPOINTS, } from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/content/content.config';
export default (function (Target) {
    var WithResizer = /** @class */ (function (_super) {
        __extends(WithResizer, _super);
        function WithResizer(props) {
            var _this = _super.call(this, props) || this;
            _this.getThumbnailSettings = function (props) {
                return _.pick(props, [
                    'withTitle',
                    'withPublisher',
                    'withDescription',
                    'withAllInfo',
                    'isOptimalFontSizes',
                    'titleFont',
                    'textFont',
                    'isArrowsOutside',
                    'canShowActionBar',
                ]);
            };
            _this.resizeWidget = function () {
                var _a = _this.props, isEditorMode = _a.isEditorMode, isFullWidth = _a.isFullWidth, setWidgetHeight = _a.setWidgetHeight, resizeComponent = _a.resizeComponent;
                if (!_this.containerRef) {
                    return;
                }
                if (isEditorMode) {
                    var width = _this.containerRef.clientWidth;
                    var height = _this.containerRef.clientHeight;
                    if (isFullWidth) {
                        setWidgetHeight(height, width);
                        return;
                    }
                    resizeComponent({ width: width, height: height });
                }
            };
            _this.getSliderPadding = function () {
                return getSliderHorizontalPadding(_this.getItemWidth(), _this.props.isArrowsOutside);
            };
            _this.getAllMarginsAndPaddings = function () {
                return (_this.props.horizontalMargin + _this.getSliderPadding()) * 2;
            };
            _this.getItemHeight = function () { return _this.getItemWidth() / VIDEOS_ASPECT_RATIO; };
            _this.getItemWidth = function (sliderHeight) {
                if (sliderHeight === void 0) { sliderHeight = _this.state.minSliderHeight; }
                return _this.state.enforcedWidth ||
                    _this.getItemWidthForSliderHeight(sliderHeight);
            };
            _this.getSliderWidth = function () {
                return _this.props.windowSize.width - _this.getAllMarginsAndPaddings();
            };
            _this.getItemWidthForSliderHeight = function (sliderHeight) {
                return getItemWidthForSliderHeight(sliderHeight, _this.getContentHeightForSliderHeight(sliderHeight));
            };
            _this.getContentHeightForSliderHeight = function (sliderHeight) {
                var sizes = _this.getSizes();
                if (!sizes.length) {
                    return;
                }
                var _a = __read(sizes, 5), size275 = _a[0], size399 = _a[1], size400 = _a[2], size500 = _a[3], size700 = _a[4];
                if (sliderHeight <= size275.height) {
                    return size275.contentHeight;
                }
                if (sliderHeight <= size399.height) {
                    return size399.contentHeight;
                }
                if (sliderHeight < size500.height) {
                    return size400.contentHeight;
                }
                if (sliderHeight < size700.height) {
                    return size500.contentHeight;
                }
                return size700.contentHeight;
            };
            _this.getNormalizedSliderHeight = function (sliderHeight) {
                var _a = __read(_this.getSizes(), 3), size399 = _a[1], size400 = _a[2];
                sliderHeight = _this.getClampedHeight(sliderHeight);
                if (sliderHeight > size399.height && sliderHeight < size400.height) {
                    sliderHeight = size400.height;
                }
                return sliderHeight;
            };
            _this.getClampedHeight = function (height) {
                return _.clamp.apply(_, __spread([height], _this.getHeightConstraints()));
            };
            _this.saveContainerRef = function (ref) {
                _this.containerRef = ref;
                _this.resizeWidget();
            };
            _this.state = {
                minSliderWidth: 0,
                minSliderHeight: 0,
                enforcedWidth: 0,
            };
            Object.assign(_this.state, _this.getSliderDimensions());
            _this.skipSizeUpdate = false;
            return _this;
        }
        WithResizer.prototype.componentWillReceiveProps = function (nextProps, nextContext) {
            var _this = this;
            if (nextProps.isSiteMode) {
                return;
            }
            if (!_.isEqual(this.getThumbnailSettings(nextProps), this.getThumbnailSettings(this.props))) {
                this.setState({ enforcedWidth: this.getItemWidth() }, this.resizeWidget);
                this.skipSizeUpdate = true;
                return;
            }
            if (!_.isEqual(this.props.windowSize, nextProps.windowSize)) {
                if (this.skipSizeUpdate) {
                    this.skipSizeUpdate = false;
                    return;
                }
            }
            this.setState({
                enforcedWidth: 0,
            }, function () {
                _this.setState(_this.getSliderDimensions(), _this.resizeWidget);
            });
        };
        WithResizer.prototype.getSizes = function () {
            var _a = this.props, titleFont = _a.titleFont, textFont = _a.textFont, isOptimalFontSizes = _a.isOptimalFontSizes, withDescription = _a.withDescription, withPublisher = _a.withPublisher, withTitle = _a.withTitle;
            return _.map(THUMBNAIL_WIDTH_BREAKPOINTS, function (width) {
                var contentHeight = getContentHeight({
                    width: width,
                    titleFont: titleFont,
                    textFont: textFont,
                    isOptimalFontSizes: isOptimalFontSizes,
                    withDescription: withDescription,
                    withPublisher: withPublisher,
                    withTitle: withTitle,
                });
                return {
                    height: width / VIDEOS_ASPECT_RATIO + contentHeight,
                    width: width,
                    contentHeight: contentHeight,
                };
            });
        };
        WithResizer.prototype.getSliderDimensions = function (_a) {
            var _b = _a === void 0 ? this.props : _a, windowSize = _b.windowSize, canShowActionBar = _b.canShowActionBar;
            var actionBarHeight = canShowActionBar
                ? getActionBarHeightForWidgetHeight(windowSize.height)
                : 0;
            var minSliderHeight = this.getNormalizedSliderHeight(windowSize.height - SLIDER_PADDING * 2 - actionBarHeight);
            return {
                minSliderWidth: this.getItemWidth(minSliderHeight) + this.getAllMarginsAndPaddings(),
                minSliderHeight: minSliderHeight,
            };
        };
        WithResizer.prototype.getHeightConstraints = function () {
            var _a = __read(this.getSizes(), 5), size275 = _a[0], size700 = _a[4];
            var _b = __read(WIDTH_CONSTRAINTS, 2), min = _b[0], max = _b[1];
            return [
                Math.floor(min / VIDEOS_ASPECT_RATIO + size275.contentHeight),
                Math.ceil(max / VIDEOS_ASPECT_RATIO + size700.contentHeight),
            ];
        };
        WithResizer.prototype.render = function () {
            var horizontalMargin = this.props.horizontalMargin;
            var _a = this.getSliderDimensions(), minSliderWidth = _a.minSliderWidth, minSliderHeight = _a.minSliderHeight;
            var enforcedWidth = this.state.enforcedWidth;
            if (enforcedWidth) {
                minSliderWidth = this.state.minSliderWidth;
                minSliderHeight = this.state.minSliderHeight;
            }
            return (React.createElement(Target, __assign({}, this.props, { sliderWidth: this.getSliderWidth(), minSliderWidth: minSliderWidth, minSliderHeight: enforcedWidth ? 0 : minSliderHeight, itemWidth: this.getItemWidth(), thumbnailHeight: this.getItemHeight(), sliderMargin: horizontalMargin, sliderPadding: this.getSliderPadding(), onContainerRef: this.saveContainerRef, isResized: true })));
        };
        WithResizer.propTypes = {
            appSettings: PropTypes.object.isRequired,
            withTitle: PropTypes.bool.isRequired,
            withPublisher: PropTypes.bool.isRequired,
            withDescription: PropTypes.bool.isRequired,
            withAllInfo: PropTypes.bool.isRequired,
            windowSize: PropTypes.object.isRequired,
            isArrowsOutside: PropTypes.bool.isRequired,
            isFullWidth: PropTypes.bool.isRequired,
            horizontalMargin: PropTypes.number.isRequired,
            isOptimalFontSizes: PropTypes.bool.isRequired,
            titleFont: PropTypes.object.isRequired,
            textFont: PropTypes.object.isRequired,
            canShowActionBar: PropTypes.bool.isRequired,
            isSiteMode: PropTypes.bool.isRequired,
            isEditorMode: PropTypes.bool.isRequired,
            setWidgetHeight: PropTypes.func.isRequired,
            resizeComponent: PropTypes.func.isRequired,
        };
        WithResizer = __decorate([
            connect(function (state) { return ({
                appSettings: state.appSettings,
                withTitle: canShowVideoListItemTitle(state),
                withPublisher: getVideoListPublisherVisibility(state),
                withDescription: canShowVideoListItemDescription(state),
                withAllInfo: canShowVideoListItemContentBelow(state),
                canShowActionBar: canShowActionBar(state),
                windowSize: state.windowSize,
                isArrowsOutside: isNavigationArrowsOutside(state),
                isFullWidth: getStretchToFullWidth(state),
                horizontalMargin: getSliderHorizontalMargin(state),
                isOptimalFontSizes: getUseOptimalFontSizes(state),
                titleFont: getTitleFont(state),
                textFont: getTextFont(state),
                isSiteMode: viewModeSelectors.isSiteMode(state),
                isEditorMode: viewModeSelectors.isEditorMode(state),
            }); }, {
                setWidgetHeight: setWidgetHeight,
                resizeComponent: resizeComponent,
            })
        ], WithResizer);
        return WithResizer;
    }(React.Component));
    return WithResizer;
});
