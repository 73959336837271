import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { LinkButton } from 'shared/components/button/button';
import Icon from 'shared/components/icon/icon';
import { getCanShowChannelInfo } from 'shared/selectors/app-settings';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import { withStyles } from 'shared/utils/withStyles';
import styles from './channel-actions.styl';
var ChannelInfoButton = /** @class */ (function (_super) {
    __extends(ChannelInfoButton, _super);
    function ChannelInfoButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.doSendAboutInfoDisplayedBI = _.once(function () {
            _this.props.sendBiEvent('widget.aboutCh.displayed');
        });
        _this.handleClick = function (event) {
            _this.props.sendBiEvent('widget.aboutCh.clicked');
            _this.props.onClick(event);
        };
        return _this;
    }
    ChannelInfoButton.prototype.componentDidMount = function () {
        this.sendAboutInfoDisplayedBI();
    };
    ChannelInfoButton.prototype.componentDidUpdate = function () {
        this.sendAboutInfoDisplayedBI();
    };
    ChannelInfoButton.prototype.sendAboutInfoDisplayedBI = function () {
        if (this.props.isVisible) {
            this.doSendAboutInfoDisplayedBI();
        }
    };
    ChannelInfoButton.prototype.render = function () {
        var _a = this.props, className = _a.className, isVisible = _a.isVisible, isFocusable = _a.isFocusable;
        if (!isVisible) {
            return null;
        }
        return (React.createElement(LinkButton, { className: className, styleName: "action info", dataHook: "channel-info-button", isFocusable: isFocusable, ariaLabel: i18n.t('widget.accessibility.show-channel-info'), onClick: this.handleClick },
            React.createElement(Icon, { name: "info-1" })));
    };
    ChannelInfoButton.propTypes = {
        isVisible: PropTypes.bool,
        isFocusable: PropTypes.bool,
        channelData: PropTypes.object,
        className: PropTypes.string,
        onClick: PropTypes.func,
    };
    ChannelInfoButton = __decorate([
        connect(function (state) { return ({
            isVisible: getCanShowChannelInfo(state),
        }); }, { sendBiEvent: sendBiEvent }),
        withStyles(styles),
        VODCSSModules(styles)
    ], ChannelInfoButton);
    return ChannelInfoButton;
}(React.Component));
export default ChannelInfoButton;
