import { __assign, __awaiter, __generator } from "tslib";
import { isVODChannel } from 'shared/utils/channel-helpers';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { normalizeVideo } from 'shared/redux/helpers/normalized';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getYoutubeVideoById } from '@wix/wix-vod-api/dist/src/public/youtube/videos';
import { getChannelVideoById } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';
import { PublicChannelService } from '@wix/wix-vod-gql-api/dist/src/public/channel/channel';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.VIDEO.GET');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
var getPublicVideoGql = createAsyncProxy('video.getPublicVideo', function (channelId, videoId) { return function (dispatch, getState, _a) {
    var createService = _a.createService;
    return __awaiter(void 0, void 0, void 0, function () {
        var params, state, _b, hydratedSingleVideoId, hydratedSingleVideo, service, promise, video, response, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    params = { channelId: channelId, videoId: videoId };
                    state = getState();
                    dispatch(START(params));
                    _b = getHydratedData(state), hydratedSingleVideoId = _b.singleVideoId, hydratedSingleVideo = _b.singleVideo;
                    service = createService(PublicChannelService);
                    if (videoId === hydratedSingleVideoId && hydratedSingleVideo) {
                        promise = Promise.resolve(hydratedSingleVideo);
                    }
                    else {
                        promise = service.getVideo(videoId);
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, promise];
                case 2:
                    video = _c.sent();
                    response = {
                        data: normalizeVideo(video),
                    };
                    dispatch(SUCCESS(params, response));
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    dispatch(FAIL(params, error_1, {
                        analytics: { type: 'error', name: 'shared.channel.video.get' },
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}; });
export var getPublicVideo = createAsyncProxy('video.getPublicVideo', function (channelId, videoId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var state, params, channel, _a, singleVideoId, singleVideo, templateMetaSiteId, response, _b, error_2, error_3;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                state = getState();
                if (isGraphAPIEnabled()) {
                    return [2 /*return*/, dispatch(getPublicVideoGql(channelId, videoId))];
                }
                params = { channelId: channelId, videoId: videoId };
                channel = getChannelById(state, channelId);
                _a = getHydratedData(state), singleVideoId = _a.singleVideoId, singleVideo = _a.singleVideo, templateMetaSiteId = _a.templateMetaSiteId;
                dispatch(START(params));
                _c.label = 1;
            case 1:
                _c.trys.push([1, 14, , 15]);
                if (!(singleVideoId === videoId)) return [3 /*break*/, 3];
                return [4 /*yield*/, getChannelVideoById(channelId, videoId, {
                        hydratedVideo: singleVideo,
                        templateMetaSiteId: templateMetaSiteId,
                    })];
            case 2:
                response = _c.sent();
                return [3 /*break*/, 13];
            case 3:
                if (!channel) return [3 /*break*/, 8];
                if (!isVODChannel(channel)) return [3 /*break*/, 5];
                return [4 /*yield*/, getChannelVideoById(channelId, videoId, {
                        templateMetaSiteId: templateMetaSiteId,
                    })];
            case 4:
                _b = _c.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, getYoutubeVideoById(videoId)];
            case 6:
                _b = _c.sent();
                _c.label = 7;
            case 7:
                response = _b;
                return [3 /*break*/, 13];
            case 8:
                _c.trys.push([8, 10, , 13]);
                return [4 /*yield*/, getChannelVideoById(channelId, videoId, {
                        templateMetaSiteId: templateMetaSiteId,
                    })];
            case 9:
                response = _c.sent();
                return [3 /*break*/, 13];
            case 10:
                error_2 = _c.sent();
                if (!(error_2.status === 404)) return [3 /*break*/, 12];
                return [4 /*yield*/, getYoutubeVideoById(videoId)];
            case 11:
                response = _c.sent();
                _c.label = 12;
            case 12: return [3 /*break*/, 13];
            case 13:
                response = __assign(__assign({}, response), { data: normalizeVideo(response.data) });
                dispatch(SUCCESS(params, response));
                return [2 /*return*/, response];
            case 14:
                error_3 = _c.sent();
                dispatch(FAIL(params, error_3, {
                    analytics: { type: 'error', name: 'shared.channel.video.get' },
                }));
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}); }; });
