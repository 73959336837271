import { createAction } from 'redux-actions';
import { closeSlideMenu } from 'mobile/redux/actions/slide-menu';
import { openMobileOverlay } from 'shared/utils/open-overlay';
import { createProxy } from 'shared/worker/lib';
export var VISIT_CHANNEL = 'CLIENT.VISIT-CHANNEL';
var VISIT_CHANNEL_ACTION = createAction(VISIT_CHANNEL);
export var OPEN_VIDEO_PAGE = 'CLIENT.OPEN.VIDEO-PAGE';
var OPEN_VIDEO_PAGE_ACTION = createAction(OPEN_VIDEO_PAGE);
export var visitChannel = createProxy('visitChannel', function () {
    return function (dispatch) {
        dispatch(VISIT_CHANNEL_ACTION());
        dispatch(closeSlideMenu());
        dispatch(openMobileOverlay());
    };
});
export var openVideoPage = createProxy('openVideoPage', function (videoId, onClose) {
    return function (dispatch) {
        dispatch(OPEN_VIDEO_PAGE_ACTION());
        dispatch(closeSlideMenu());
        dispatch(openMobileOverlay("#/video/" + videoId, onClose));
    };
});
