import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getSubscribeButtonText, getPublisherText, getPlayButtonText, getPurchaseButtonText, getOpacityStyle, } from 'widget/components/player-overlay/selectors';
import { openSubscription } from 'widget/redux/client/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from 'widget/redux/client/player-overlay/actions/open-player-overlay';
import { openPurchase } from 'widget/redux/client/player-overlay/actions/open-purchase';
import { playSelectedVideo } from 'widget/redux/client/player-overlay/actions/play-selected-video';
import ElementSwitcher from 'widget/components/element-switcher/element-switcher';
import Title from 'widget/components/player-overlay/partials/title';
import ComingSoon from 'widget/components/player-overlay/partials/coming-soon';
import LiveLabel from 'widget/containers/live-label/live-label';
import { hasVideos } from 'shared/utils/channel-helpers';
import { RoundedIcon } from 'shared/components/icon/icon';
import { GenericButton } from 'shared/components/button/button';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { notForPreview } from 'shared/utils/not-for-preview';
import { isThumbnailsPreviewHover, isVideosListInfoAlwaysShown, isVideosListInfoNeverShown, } from 'shared/selectors/app-settings';
import { withStyles } from 'shared/utils/withStyles';
import styles from './thumbnail-overlay.styl';
var THUMB_PADDING = 24;
var ThumbnailOverlayStrip = /** @class */ (function (_super) {
    __extends(ThumbnailOverlayStrip, _super);
    function ThumbnailOverlayStrip(props) {
        var _this = _super.call(this, props) || this;
        _this.playSelectedVideo = notForPreview(function (event) {
            event.preventDefault();
            event.stopPropagation();
            var _a = _this.props, videoItem = _a.videoItem, playSelectedVideo = _a.playSelectedVideo, onPlaySelectedVideo = _a.onPlaySelectedVideo, showChannelCover = _a.showChannelCover;
            playSelectedVideo({ videoItem: videoItem, onPlaySelectedVideo: onPlaySelectedVideo, showChannelCover: showChannelCover });
        });
        _this.handleSubscriptionClick = function (event) {
            event.preventDefault();
            event.stopPropagation();
            _this.props.openSubscription();
        };
        _this.handlePurchaseClick = function (event) {
            event.preventDefault();
            event.stopPropagation();
            var _a = _this.props, videoItem = _a.videoItem, openPlayerOverlay = _a.openPlayerOverlay;
            openPlayerOverlay(videoItem);
        };
        var durationStr = _this.props.videoItem.durationStr;
        _this.clampOptions = {
            truncateText: " / " + durationStr,
            textAlign: 'center',
        };
        return _this;
    }
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "titleBlock", {
        get: function () {
            var _a = this.props, videoItem = _a.videoItem, isTitleNeverShown = _a.isTitleNeverShown;
            if (isTitleNeverShown) {
                return null;
            }
            return (React.createElement("div", { className: styles['title-block'] },
                React.createElement("div", { className: styles.title },
                    React.createElement(Title, { text: videoItem.title, clampOptions: this.clampOptions, enforceJSClamp: true }))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "smallPlayButton", {
        get: function () {
            if (!this.props.playButtonText) {
                return null;
            }
            return (React.createElement(GenericButton, { styleName: "button play-button", onClick: this.playSelectedVideo, isFocusable: this.props.isContentFocusable },
                React.createElement(RoundedIcon, { styleName: "icon play-icon", name: "arrow-right-filled" })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "smallSubscribeButton", {
        get: function () {
            if (!this.props.subscribeButtonText) {
                return null;
            }
            return (React.createElement(GenericButton, { styleName: "button small-subscribe-button", onClick: this.handleSubscriptionClick, isFocusable: this.props.isContentFocusable },
                React.createElement(RoundedIcon, { styleName: "icon paid-icon", name: "paid" })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "smallPurchaseButton", {
        get: function () {
            if (!this.props.purchaseButtonText) {
                return null;
            }
            return (React.createElement(GenericButton, { styleName: "button small-buy-button", onClick: this.handlePurchaseClick, isFocusable: this.props.isContentFocusable },
                React.createElement(RoundedIcon, { styleName: "icon paid-icon", name: "paid" })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "normalButtons", {
        get: function () {
            var play = this.renderPlayButton();
            var subscribe = this.renderSubscribeButton();
            var purchase = this.renderPurchaseButton();
            var key = "" + (play ? '1' : '') + (subscribe ? '2' : '') + (purchase ? '3' : '');
            return (React.createElement("div", { styleName: "buttons-wrapper", key: key },
                play,
                subscribe || purchase));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "smallButtons", {
        get: function () {
            return (React.createElement("div", { styleName: "buttons-wrapper" },
                this.smallPlayButton,
                this.smallSubscribeButton || this.smallPurchaseButton));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailOverlayStrip.prototype, "buttons", {
        get: function () {
            var _a = this.props, width = _a.width, isOnlySmallButtons = _a.isOnlySmallButtons;
            if (isOnlySmallButtons) {
                return this.smallButtons;
            }
            return (React.createElement(ElementSwitcher, { maxWidth: width - 2 * THUMB_PADDING },
                this.normalButtons,
                this.smallButtons));
        },
        enumerable: true,
        configurable: true
    });
    ThumbnailOverlayStrip.prototype.renderPlayButton = function () {
        var _a = this.props, channelData = _a.channelData, isContentFocusable = _a.isContentFocusable, playButtonText = _a.playButtonText;
        if (!hasVideos(channelData)) {
            return React.createElement(ComingSoon, { className: styles['soon-info'] });
        }
        if (!playButtonText) {
            return null;
        }
        var styleName = classnames(styles.button, styles['play-button']);
        var iconStyleName = classnames(styles.icon, styles['play-icon']);
        return (React.createElement("a", { href: "", className: styleName, "data-hook": "overlay-play-button", onClick: this.playSelectedVideo, tabIndex: isContentFocusable ? 0 : -1 },
            React.createElement(RoundedIcon, { className: iconStyleName, name: "arrow-right-filled" }),
            React.createElement("span", { className: styles.text }, playButtonText)));
    };
    ThumbnailOverlayStrip.prototype.renderSubscribeButton = function () {
        var subscribeButtonText = this.props.subscribeButtonText;
        if (!subscribeButtonText) {
            return null;
        }
        var styleName = classnames(styles.button, 'qa-widget-overlay-subscribe-button');
        return (React.createElement("a", { href: "", className: styleName, "data-hook": "subscribe-button", onClick: this.handleSubscriptionClick, tabIndex: this.props.isContentFocusable ? 0 : -1 },
            React.createElement("span", { className: styles.text }, subscribeButtonText)));
    };
    ThumbnailOverlayStrip.prototype.renderPurchaseButton = function () {
        var purchaseButtonText = this.props.purchaseButtonText;
        if (!purchaseButtonText) {
            return null;
        }
        var styleName = classnames('qa-widget-overlay-buy-from-button', styles.button);
        return (React.createElement("a", { href: "", className: styleName, onClick: this.handlePurchaseClick, "data-hook": "paid-access-button", tabIndex: this.props.isContentFocusable ? 0 : -1 },
            React.createElement("span", { className: styles.text }, purchaseButtonText)));
    };
    ThumbnailOverlayStrip.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, mediaInfo: videoItem.mediaInfo, startTime: videoItem.dateStartLive, isSmall: true, noBackground: true, className: styles['live-label'], smallClassName: styles['shown-on-hover'] }));
    };
    ThumbnailOverlayStrip.prototype.render = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem, className = _a.className, isThumbnailsPreviewHover = _a.isThumbnailsPreviewHover, isTitleAlwaysShown = _a.isTitleAlwaysShown, isTitleNeverShown = _a.isTitleNeverShown;
        if (!channelData || !videoItem) {
            return null;
        }
        var styleNames = classnames('overlay-wrapper', {
            'preview-hover': isThumbnailsPreviewHover,
            visible: isTitleAlwaysShown || isTitleNeverShown,
        });
        return (React.createElement("div", { styleName: styleNames, className: className },
            React.createElement("div", { styleName: "background" }),
            React.createElement("div", { styleName: "content" },
                React.createElement("div", { styleName: "top-content" },
                    this.titleBlock,
                    this.buttons,
                    this.renderLiveLabel()))));
    };
    ThumbnailOverlayStrip.propTypes = {
        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isTitleAlwaysShown: PropTypes.bool.isRequired,
        isTitleNeverShown: PropTypes.bool.isRequired,
        currentSiteUser: PropTypes.object,
        channelData: PropTypes.object.isRequired,
        videoItem: PropTypes.object.isRequired,
        publisherText: PropTypes.node,
        subscribeButtonText: PropTypes.node,
        playButtonText: PropTypes.node,
        purchaseButtonText: PropTypes.node,
        opacityStyle: PropTypes.object.isRequired,
        showChannelCover: PropTypes.bool,
        onPlaySelectedVideo: PropTypes.func,
        openSubscription: PropTypes.func.isRequired,
        openPlayerOverlay: PropTypes.func.isRequired,
        openPurchase: PropTypes.func.isRequired,
        playSelectedVideo: PropTypes.func.isRequired,
        isContentFocusable: PropTypes.bool,
        width: PropTypes.number,
        isOnlySmallButtons: PropTypes.bool,
        className: PropTypes.string,
    };
    ThumbnailOverlayStrip.defaultProps = {
        isContentFocusable: true,
    };
    ThumbnailOverlayStrip = __decorate([
        connect(function (state, ownProps) { return ({
            isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
            isTitleAlwaysShown: isVideosListInfoAlwaysShown(state),
            isTitleNeverShown: isVideosListInfoNeverShown(state),
            subscribeButtonText: getSubscribeButtonText(state, ownProps),
            publisherText: getPublisherText(state, ownProps),
            playButtonText: getPlayButtonText(state, ownProps),
            purchaseButtonText: getPurchaseButtonText(state, ownProps),
            opacityStyle: getOpacityStyle(state),
        }); }, {
            openSubscription: openSubscription,
            openPlayerOverlay: openPlayerOverlay,
            playSelectedVideo: playSelectedVideo,
            openPurchase: openPurchase,
        }),
        withStyles(styles),
        VODCSSModules(styles)
    ], ThumbnailOverlayStrip);
    return ThumbnailOverlayStrip;
}(React.Component));
export default ThumbnailOverlayStrip;
