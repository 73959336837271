var _a;
import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ContainerQuery } from 'react-container-query';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { canShowMainItemTitle, isCompactMainActionsOnlyIcons, } from 'shared/selectors/app-settings';
import { createGetPlayButtonConfig } from './ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from './ui-selectors/video-overlay/paid-access/button-config';
import { getOpacityStyles } from './ui-selectors/opacity-styles';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import Title from './partials/title';
import PaidAccessButton from './partials/paid-access-button';
import PlayButton from './partials/play-button';
import VideoCover from 'shared/components/video-cover/video-cover';
import LiveLabel from 'widget/containers/live-label/live-label';
import { withStyles } from 'shared/utils/withStyles';
import styles from './overlay.styl';
var containerQuery = (_a = {},
    _a[styles['element-max-1899']] = {
        maxWidth: 1899,
        minWidth: 550,
    },
    _a[styles['element-max-549']] = {
        maxWidth: 549,
        minWidth: 450,
    },
    _a[styles['element-max-449']] = {
        maxWidth: 449,
        minWidth: 300,
    },
    _a[styles['element-max-299']] = {
        maxWidth: 299,
    },
    _a);
var VideoOverlay = /** @class */ (function (_super) {
    __extends(VideoOverlay, _super);
    function VideoOverlay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var onPlayRequest = _this.props.onPlayRequest;
            if (!_this.canPlayVideo()) {
                return;
            }
            onPlayRequest();
        };
        return _this;
    }
    VideoOverlay.prototype.canPlayVideo = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem, currentSiteUser = _a.currentSiteUser;
        return canPlayFullOrPreview(channelData, videoItem, currentSiteUser);
    };
    VideoOverlay.prototype.renderTitle = function () {
        var _a = this.props, videoItem = _a.videoItem, canShowMainItemTitle = _a.canShowMainItemTitle;
        if (!canShowMainItemTitle) {
            return null;
        }
        return (React.createElement("div", { className: styles['title-block'] },
            React.createElement(Title, null, videoItem.title)));
    };
    VideoOverlay.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, mediaInfo: videoItem.mediaInfo, videoTitle: videoItem.title, startTime: videoItem.dateStartLive, className: styles['live-label'] }));
    };
    VideoOverlay.prototype.renderPlayButton = function () {
        var _a = this.props, playButtonConfig = _a.playButtonConfig, isContentFocusable = _a.isContentFocusable, isMainActionsOnlyIcons = _a.isMainActionsOnlyIcons, isPlayerModuleLoaded = _a.isPlayerModuleLoaded;
        if (!playButtonConfig || !isPlayerModuleLoaded) {
            return null;
        }
        return (React.createElement(PlayButton, { onClick: playButtonConfig.callback, isFocusable: isContentFocusable, isIconOnly: isMainActionsOnlyIcons }, i18n.t(playButtonConfig.translationData.props)));
    };
    VideoOverlay.prototype.renderPaidAccessButton = function () {
        var _a = this.props, paidAccessButtonConfig = _a.paidAccessButtonConfig, isContentFocusable = _a.isContentFocusable, isMainActionsOnlyIcons = _a.isMainActionsOnlyIcons;
        if (!paidAccessButtonConfig) {
            return null;
        }
        return (React.createElement(PaidAccessButton, { onClick: paidAccessButtonConfig.callback, isFocusable: isContentFocusable, isIconOnly: isMainActionsOnlyIcons }, formatMessageWithPrice(paidAccessButtonConfig.translationData.props, paidAccessButtonConfig.translationData.priceModel)));
    };
    VideoOverlay.prototype.render = function () {
        var _this = this;
        var _a = this.props, videoItem = _a.videoItem, className = _a.className, width = _a.width, height = _a.height, opacityStyles = _a.opacityStyles, isContentFocusable = _a.isContentFocusable;
        if (!videoItem) {
            return null;
        }
        var dataHook = classnames('player-overlay', {
            'player-overlay-visible': isContentFocusable,
        });
        return (React.createElement(ContainerQuery, { query: containerQuery }, function (queryClasses) {
            var _a;
            return (React.createElement("div", { "data-hook": dataHook, className: classnames(styles.container, className, __assign((_a = {}, _a[styles.playable] = _this.canPlayVideo(), _a), queryClasses)), onClick: _this.handleClick },
                React.createElement(VideoCover, { videoItem: videoItem, breakpoints: [{ min: 0, width: width, height: height }] }),
                React.createElement("div", { className: styles.content, style: opacityStyles },
                    React.createElement("div", { className: styles['top-content'] },
                        _this.renderTitle(),
                        _this.renderLiveLabel(),
                        React.createElement("div", { className: styles['buttons-wrapper'] },
                            _this.renderPlayButton(),
                            _this.renderPaidAccessButton())))));
        }));
    };
    VideoOverlay.propTypes = {
        videoItem: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        opacityStyles: PropTypes.object.isRequired,
        canShowMainItemTitle: PropTypes.bool.isRequired,
        isMainActionsOnlyIcons: PropTypes.bool.isRequired,
        onPlayRequest: PropTypes.func.isRequired,
        onPlayMemberOnlyRequest: PropTypes.func.isRequired,
        onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
        onSubscriptionRequest: PropTypes.func.isRequired,
        onPurchaseRequest: PropTypes.func.isRequired,
        className: PropTypes.string,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,
        playButtonConfig: PropTypes.object,
        paidAccessButtonConfig: PropTypes.object,
        isContentFocusable: PropTypes.bool,
        isPlayerModuleLoaded: PropTypes.bool,
    };
    VideoOverlay.defaultProps = {
        isContentFocusable: true,
        showChannelCover: false,
        onPlayRequest: _.noop,
        onPlayMemberOnlyRequest: _.noop,
        onPlayWithoutPreviewRequest: _.noop,
        onPurchaseRequest: _.noop,
        onSubscriptionRequest: _.noop,
    };
    VideoOverlay = __decorate([
        connect(function () {
            var getPlayButtonConfig = createGetPlayButtonConfig();
            var getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
            return function (state, ownProps) { return ({
                playButtonConfig: getPlayButtonConfig(state, ownProps),
                paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
                canShowMainItemTitle: canShowMainItemTitle(state),
                isMainActionsOnlyIcons: isCompactMainActionsOnlyIcons(state),
                opacityStyles: getOpacityStyles(state),
                isPlayerModuleLoaded: state.modules.player,
            }); };
        }),
        withStyles(styles)
    ], VideoOverlay);
    return VideoOverlay;
}(React.Component));
export default VideoOverlay;
