var _a;
import { handleActions } from 'redux-actions';
import { SELECTED_VIDEO_ACTIONS } from 'widget/redux/client/actions/select-video';
import { NAMES as VIDEO_PLAYBACK_ACTIONS } from 'widget/redux/client/actions/player/change-playback-status';
function markAsTouched() {
    return true;
}
function markAsNotTouched() {
    return false;
}
export default handleActions((_a = {},
    _a[SELECTED_VIDEO_ACTIONS.SET] = markAsTouched,
    _a[SELECTED_VIDEO_ACTIONS.RESET] = markAsNotTouched,
    _a[VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY] = markAsTouched,
    _a), false);
