var _a;
import React from 'react';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { closeModal } from 'shared/components/modal/utils';
import { PrimaryButton } from 'shared/components/button/button';
import { MODAL_CONTENT_TYPES } from 'widget/components/modal/constants';
import { withStyles } from 'shared/utils/withStyles';
import styles from './preview-prompt.styl';
var PreviewModePrompt = withStyles(styles)(function () { return (React.createElement("div", { className: styles['prompt-wrapper'], "data-hook": "preview-mode-prompt" },
    React.createElement("div", null, i18n.t('widget.preview-prompt.you-in-preview.line1')),
    React.createElement("div", { className: styles['second-message'] }, i18n.t('widget.preview-prompt.you-in-preview.line2')),
    React.createElement(PrimaryButton, { dataHook: "close-preview-prompt", className: styles['close-button'], onClick: closeModal }, i18n.t('widget.preview-prompt.got-it')))); });
export var previewPrompt = (_a = {},
    _a[MODAL_CONTENT_TYPES.PREVIEW_PROMPT] = React.createElement(PreviewModePrompt, null),
    _a);
