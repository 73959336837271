import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';
import ChannelSubscriptionLabel from 'shared/components/channel-subscription-label/channel-subscription-label';
import { LinkButton } from 'shared/components/button/button';
import { getDealInfo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import { withStyles } from 'shared/utils/withStyles';
import styles from './channel-actions.styl';
var ChannelSubscriptionButton = /** @class */ (function (_super) {
    __extends(ChannelSubscriptionButton, _super);
    function ChannelSubscriptionButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChannelSubscriptionButton.prototype.render = function () {
        var _a = this.props, isVisible = _a.isVisible, dealInfo = _a.dealInfo, className = _a.className, onClick = _a.onClick, isFocusable = _a.isFocusable;
        if (!isVisible) {
            return null;
        }
        return (React.createElement(LinkButton, { className: className, styleName: "action subscribe", dataHook: "channel-subscription-button", isFocusable: isFocusable, onClick: onClick },
            React.createElement(ChannelSubscriptionLabel, { dealInfo: dealInfo, isVisible: isVisible })));
    };
    ChannelSubscriptionButton.propTypes = {
        channelData: PropTypes.object,
        dealInfo: PropTypes.array,
        className: PropTypes.string,
        onClick: PropTypes.func,
        isVisible: PropTypes.bool,
        isFocusable: PropTypes.bool,
    };
    ChannelSubscriptionButton = __decorate([
        connect(function (state, ownProps) { return ({
            dealInfo: getDealInfo(ownProps),
            isVisible: isSubscriptionButtonVisible({ channel: ownProps.channelData }),
        }); }),
        withStyles(styles),
        VODCSSModules(styles)
    ], ChannelSubscriptionButton);
    return ChannelSubscriptionButton;
}(React.Component));
export default ChannelSubscriptionButton;
