import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from 'shared/utils/withStyles';
import styles from './load-more-button.styl';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var LoadMoreButton = /** @class */ (function (_super) {
    __extends(LoadMoreButton, _super);
    function LoadMoreButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoadMoreButton.prototype.render = function () {
        var _a = this.props, height = _a.height, onClick = _a.onClick;
        return (React.createElement("button", { "data-hook": "mobile-load-more-button", onClick: onClick, className: styles.button, style: {
                height: height,
                lineHeight: height + "px",
            } }, i18n.t('widget.load-more')));
    };
    LoadMoreButton.propTypes = {
        height: PropTypes.number,
        onClick: PropTypes.func,
    };
    LoadMoreButton.defaultProps = {
        height: 66,
        onClick: _.noop,
    };
    LoadMoreButton = __decorate([
        withStyles(styles)
    ], LoadMoreButton);
    return LoadMoreButton;
}(React.Component));
export default LoadMoreButton;
