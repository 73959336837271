import { createSelector } from 'reselect';
import { canShowVideoList, canShowVideoListStrip, getCanShowSignIn, isCompactLayout, isStripLayout, } from 'shared/selectors/app-settings';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { hasMoreThanOneVideo } from 'shared/selectors/videos';
export var showSignIn = createSelector(getCanShowSignIn, getCurrentSiteUser, function (allowShowSignIn, currentSiteUser) {
    return Boolean(allowShowSignIn && !currentSiteUser);
});
export var showSignOut = createSelector(getCanShowSignIn, getCurrentSiteUser, function (allowShowSignIn, currentSiteUser) {
    return Boolean(allowShowSignIn && currentSiteUser);
});
export var showVisitChannel = createSelector([
    hasMoreThanOneVideo,
    isStripLayout,
    isCompactLayout,
    canShowVideoListStrip,
    canShowVideoList,
], function (hasMoreThanOneVideo, isStripLayout, isCompactLayout, canShowVideoListStrip, canShowVideoList) {
    return hasMoreThanOneVideo &&
        !(isStripLayout && !canShowVideoListStrip) &&
        !(isCompactLayout && !canShowVideoList);
});
