var _a;
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import defaultSettings from '@wix/wix-vod-constants/dist/app-settings/default-settings';
import { defaultSettingsEditorX } from '@wix/wix-vod-constants/dist/app-settings/default-settings-editor-x';
import { APP_SETTINGS_UPDATE } from 'widget/redux/client/actions/app-settings';
import { migrateToLatestVersion } from '@wix/wix-vod-shared/dist/src/app-settings/migration';
import { isNewInstallation, isLatestVersion } from 'shared/utils/app-settings';
var initialSettings = {
    booleans: {},
    colors: {},
    fonts: {},
    numbers: {},
};
export default handleActions((_a = {},
    _a[APP_SETTINGS_UPDATE] = function (state, _a) {
        var payload = _a.payload;
        var currentSettings = _.merge({}, state, payload);
        if (!isNewInstallation(currentSettings) &&
            !isLatestVersion(currentSettings)) {
            currentSettings = migrateToLatestVersion(currentSettings);
        }
        var isResponsiveEditor = payload.booleans && payload.booleans.responsive;
        return _.merge({}, initialSettings, isResponsiveEditor ? defaultSettingsEditorX : defaultSettings, currentSettings);
    },
    _a), null);
