import CACHE_ITEM_STATUS from 'widget/constants/ulrs-cache-status';
import { createSelector } from 'reselect';
var getUrlsCache = function (state) { return state.videoUrlsCache; };
var getVideoItem = function (_, props) { return props.videoItem; };
var getVideoUrls = createSelector([getUrlsCache, getVideoItem], function (cache, videoItem) {
    if (!videoItem) {
        return null;
    }
    var videoUrlsFromCache = cache[videoItem.id];
    if (videoUrlsFromCache &&
        videoUrlsFromCache.status === CACHE_ITEM_STATUS.RESOLVED) {
        return videoUrlsFromCache.urls;
    }
    return null;
});
export default getVideoUrls;
