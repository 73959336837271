import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import { areElementsRounded, isRTL } from 'shared/selectors/app-settings';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import ClosableOverlay from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/closable-overlay/closable-overlay';
import VideoInfo from '../../components/video-info/video-info';
import Animate from '@wix/wix-vod-shared/dist/src/widget/ui-components/animate/animate';
import INFO_OVERLAY_CONTENT from 'widget/player-widget/components/new-player/containers/animated-overlay-content/info-overlay-content-enums';
import { withStyles } from 'shared/utils/withStyles';
import styles from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/actions-panel/actions-panel.scss';
// TBD: should we put it to shared?
var AnimatedOverlayContent = /** @class */ (function (_super) {
    __extends(AnimatedOverlayContent, _super);
    function AnimatedOverlayContent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.videoInfoContainerRef = null;
        _this.allCardsContainerRef = null;
        _this.saveVideoInfoContainerRef = function (ref) {
            _this.videoInfoContainerRef = ref;
        };
        _this.saveAllCardsContainerRef = function (ref) {
            _this.allCardsContainerRef = ref;
        };
        return _this;
    }
    AnimatedOverlayContent.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        var overlayContent = this.props.overlayContent;
        if (!overlayContent && prevProps.overlayContent === overlayContent) {
            return;
        }
        var hasChangedToTrue = function (key) { return _this.props[key] && !prevProps[key]; };
        if (hasChangedToTrue('isVideoInfoVisible')) {
            focus(this.videoInfoContainerRef);
        }
        if (hasChangedToTrue('isAllCardsVisible')) {
            focus(this.allCardsContainerRef);
        }
    };
    AnimatedOverlayContent.prototype.render = function () {
        var _a;
        var _b = this.props, _c = _b.videoItem, posterUrl = _c.posterUrl, title = _c.title, description = _c.description, publisher = _c.publisher, durationStr = _c.durationStr, genre = _c.genre, videoType = _c.videoType, cast = _c.cast, crew = _c.crew, allCardsOverlayContent = _b.allCardsOverlayContent, overlayContent = _b.overlayContent, isPlayerInFullScreen = _b.isPlayerInFullScreen, isVideoInfoVisible = _b.isVideoInfoVisible, isAllCardsVisible = _b.isAllCardsVisible, onClose = _b.onClose, isRTL = _b.isRTL;
        var classNames = classnames(styles['info-overlay-wrapper'], (_a = {},
            _a[styles.active] = overlayContent,
            _a));
        var infoClassName = styles['info-overlay'];
        return (React.createElement("div", { className: classNames },
            React.createElement("section", { className: styles['info-overlay-content'], "aria-hidden": !isVideoInfoVisible, ref: this.saveVideoInfoContainerRef },
                React.createElement(Animate, { type: "fade-in-out", className: infoClassName, when: isVideoInfoVisible },
                    React.createElement(ClosableOverlay, { dataHook: "closable-overlay", onClose: onClose, isInFullScreen: isPlayerInFullScreen, posterUrl: posterUrl, isVisible: isVideoInfoVisible, isRTL: isRTL, ariaLabel: i18n.t('widget.accessibility.video-info'), closeButtonAriaLabel: i18n.t('widget.accessibility.close-video-info') },
                        React.createElement(VideoInfo, { title: title, description: description, posterUrl: posterUrl, publisher: publisher, genre: genre, videoType: videoType, durationStr: durationStr, durationAriaLabel: i18n.t('widget.accessibility.duration', {
                                durationStr: durationStr,
                            }), cast: cast, crew: crew, isPlayerInFullScreen: isPlayerInFullScreen, isRTL: isRTL })))),
            React.createElement("section", { className: styles['info-overlay-content'], "aria-hidden": !isAllCardsVisible, ref: this.saveAllCardsContainerRef },
                React.createElement(Animate, { className: infoClassName, when: isAllCardsVisible },
                    React.createElement(ClosableOverlay, { dataHook: "all-cards-overlay", onClose: onClose, isVisible: isAllCardsVisible, contentClassName: styles['all-cards-overlay-content'], isFullWidth: true, isFullWidthForced: true, isInFullScreen: isPlayerInFullScreen, isRTL: isRTL, closeOnBodyClick: true, ariaLabel: i18n.t('widget.accessibility.all-cards'), closeButtonAriaLabel: i18n.t('widget.accessibility.close-all-cards') }, isAllCardsVisible && allCardsOverlayContent)))));
    };
    AnimatedOverlayContent.propTypes = {
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        allCardsOverlayContent: PropTypes.node,
        overlayContent: PropTypes.string,
        onClose: PropTypes.func,
        compId: PropTypes.string,
        isVideoInfoVisible: PropTypes.bool,
        isAllCardsVisible: PropTypes.bool,
        isPlayerInFullScreen: PropTypes.bool,
        areElementsRounded: PropTypes.bool,
        isRTL: PropTypes.bool,
    };
    AnimatedOverlayContent.defaultProps = {
        onClose: _.noop,
    };
    AnimatedOverlayContent = __decorate([
        connect(function (state, ownProps) { return ({
            compId: getCompId(state),
            isVideoInfoVisible: ownProps.overlayContent === INFO_OVERLAY_CONTENT.VIDEO_INFO,
            isAllCardsVisible: ownProps.overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS,
            areElementsRounded: areElementsRounded(state),
            isRTL: isRTL(state),
        }); }),
        withStyles(styles)
    ], AnimatedOverlayContent);
    return AnimatedOverlayContent;
}(React.Component));
export default AnimatedOverlayContent;
