import pixWixProxy from 'api/pix-wix-proxy/pix-wix-proxy';
export var getAuthToken = function (userId, metaSiteId) {
    return pixWixProxy
        .get('/annoto/token', {
        params: {
            member_id: userId,
            metasite_id: metaSiteId,
        },
    })
        .then(function (response) { return response.data.token; });
};
