import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { REFRESH_EVENT, storeForReload } from 'widget/utils/reload';
import { connect } from 'react-redux';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { withPubSubEvents } from 'widget/containers/pub-sub-events';
var RELOAD_STORAGE_READY = 'reloadStorageReady';
// TODO: enhance this to look like real storage with get/set/remove methods
/*
 * Since after UOU login or logout, whole site rebuilds, and there is no nice solution in WixSDK for this case.
 * This is workaround, that use persistent events in Wix.PubSub. Anytime we let user login or logout, we also
 * stores data, that we want to path into "next state" of widget after it's reloaded. But since we use
 * persistent events, they are stored in same sequence as we publish them until site is closed or manually refreshed.
 * But we need only last one, that's why we use @debounce for method `processRetrievedData`
 */
var PersistentEvents = /** @class */ (function (_super) {
    __extends(PersistentEvents, _super);
    function PersistentEvents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lastEventData = {};
        _this.lastHandledEventData = {};
        _this.saveEventData = function (_a) {
            var data = _a.data;
            if (!data || data.stored !== RELOAD_STORAGE_READY) {
                _this.lastEventData = data || {};
            }
            _this.retrieveDataAfterReload();
        };
        _this.retrieveDataAfterReload = _.debounce(function () {
            var _a = _this.lastEventData, stored = _a.stored, rootCompId = _a.rootCompId;
            if (rootCompId === _this.props.compId &&
                stored &&
                !_.isEqual(_this.lastHandledEventData, _this.lastEventData)) {
                _this.props.onEvent(stored);
                _this.lastHandledEventData = _this.lastEventData;
            }
            _this.lastEventData = {};
        }, 300);
        return _this;
    }
    PersistentEvents.prototype.componentDidMount = function () {
        this.props.storeForReload(RELOAD_STORAGE_READY);
    };
    PersistentEvents.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (this.props.pubSubEvents) {
            this.props.pubSubEvents[REFRESH_EVENT].forEach(this.saveEventData);
        }
    };
    PersistentEvents.prototype.componentWillUnmount = function () {
        this.unsubscribe();
    };
    PersistentEvents.prototype.unsubscribe = function () {
        this.props.storeForReload(null);
    };
    PersistentEvents.prototype.render = function () {
        return null;
    };
    PersistentEvents.propTypes = {
        onEvent: PropTypes.func,
    };
    PersistentEvents.defaultProps = {
        onEvent: _.noop,
    };
    PersistentEvents = __decorate([
        connect(function (state) { return ({ compId: getCompId(state) }); }, { storeForReload: storeForReload }),
        withPubSubEvents
    ], PersistentEvents);
    return PersistentEvents;
}(React.Component));
export { PersistentEvents };
