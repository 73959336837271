var _a;
import { __assign, __decorate, __extends } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import cxs from 'cxs';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* containers */
import withApplyForcedHeight from 'widget/layouts/slider/containers/apply-forced-height/apply-forced-height';
import withResizer from 'widget/layouts/slider/containers/resizer/resizer';
import ActionCallbacks from 'widget/containers/action-callbacks/action-callbacks';
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import ShareOverlay from 'widget/containers/share-overlay/share-overlay';
import OpenFullscreenModalShare from 'widget/containers/open-fullscreen-modal-share/open-fullscreen-modal-share';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import VideoList from 'widget/components/video-list/video-list';
import Player from 'widget/containers/player/player';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';
/* components */
import Slider from '@wix/wix-vod-shared/dist/src/widget/ui-components/slider/slider';
import ActionBar from 'widget/components/action-bar/action-bar';
import VideoThumbnail from 'shared/components/video-thumbnail/video-thumbnail';
import NoResults from 'widget/layouts/components/no-results/no-results';
import AutoPlayVideo from 'shared/components/autoplay-video/autoplay-video';
/* utils */
import getThumbnailMinWidthAttribute from '@wix/wix-vod-shared/dist/src/widget/ui-components/slider/get-thumbnail-min-width-attribute';
import { appStatus } from 'services/app-status';
/* bi */
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';
/* selectors */
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVideoPlayingOptimistic, isVideoPausedOptimistic, } from 'widget/selectors/video-playback-status';
import { isResponsiveEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getVideoIds, getCursor, getIsFetching, } from 'widget/redux/client/lazy-channel-videos/selectors';
import { getCategory } from 'shared/selectors/search';
import { isThumbnailsPreviewHover, isSliderNavAlwaysVisible, getThumbnailSpacing, getContainerMargins, getChannelLayout, isPlayInFrame, isRTL, isResponsiveEditor, } from 'shared/selectors/app-settings';
import { getMainVideo } from 'widget/selectors/get-video';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { canRenderPlayer } from 'widget/layouts/slider/selectors';
import { isInlineShareVisible } from 'widget/selectors/inline-share';
import { showAutoPlay } from 'widget/selectors/layout';
/* actions */
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from 'widget/redux/client/actions/full-screen-modal';
import { loadMore, loadMoreVideoPages, } from 'widget/redux/client/lazy-channel-videos/actions';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { resetSearch } from 'widget/redux/client/actions/search';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';
/* constants */
import { SLIDER_PADDING } from 'widget/layouts/slider/constants';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';
import { VIDEOS_ASPECT_RATIO } from 'widget/constants/videos-aspect-ratio';
/* translations */
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from 'shared/utils/withStyles';
import styles from './slider.styl';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { SliderEmptyState } from '../../components/slider-empty-state/slider-empty-state';
var channelLayoutToNameMap = (_a = {},
    _a[CHANNEL_LAYOUT_VALUES.SLIDER_SMALL] = 'sliderSmall',
    _a[CHANNEL_LAYOUT_VALUES.SLIDER_BIG] = 'sliderBig',
    _a);
var SliderLayout = /** @class */ (function (_super) {
    __extends(SliderLayout, _super);
    function SliderLayout(props) {
        var _this = _super.call(this, props) || this;
        _this.logVideoPlayRequested = function (videoItem) {
            var channel = _this.props.channel;
            _this.props.logWidgetVidClick({ videoItem: videoItem, channelData: channel });
        };
        _this.renderItem = function (videoId, checkIsVisible) {
            var _a = _this.props, channel = _a.channel, videoByIds = _a.videoByIds, currentSiteUser = _a.currentSiteUser, videoIds = _a.videoIds, itemWidth = _a.itemWidth, mainVideo = _a.mainVideo;
            if (!videoId) {
                return null;
            }
            var video = videoByIds[videoId];
            var index = videoIds.indexOf(videoId);
            var dataHook = classnames('video-list-thumb-wrapper', {
                'video-list-thumb-wrapper-selected': mainVideo.id === videoId,
            });
            return (React.createElement(ActionCallbacks, { channelId: channel.id, videoItem: video, onPlayRequestedBi: _this.logVideoPlayRequested, key: videoId },
                React.createElement(VideoThumbnail, { videoItem: video, key: videoId, dataHook: dataHook, channelData: channel, isContentFocusable: checkIsVisible(index), currentSiteUser: currentSiteUser, width: itemWidth, breakpoints: [
                        {
                            min: 0,
                            width: itemWidth,
                            height: itemWidth / VIDEOS_ASPECT_RATIO,
                        },
                    ] })));
        };
        _this.getSliderAreaStyles = function () {
            var _a = _this.props, sliderMargin = _a.sliderMargin, sliderPadding = _a.sliderPadding;
            return {
                width: "calc(100% - " + (sliderMargin + sliderPadding) * 2 + "px)",
            };
        };
        _this.loadMoreItems = function (itemsToLoadCount) {
            var _a = _this.props, loadMore = _a.loadMore, loadMoreVideoPages = _a.loadMoreVideoPages, selectedCategory = _a.selectedCategory;
            if (selectedCategory) {
                loadMoreVideoPages();
                return;
            }
            loadMore(itemsToLoadCount, { category: selectedCategory });
        };
        _this.handleActiveItemLeftFrame = function () {
            var _a = _this.props, isVideoPlaying = _a.isVideoPlaying, pauseVideo = _a.pauseVideo;
            if (isVideoPlaying) {
                pauseVideo();
            }
        };
        _this.setCurrentVideoFromPayment = function (_a) {
            var itemId = (_a === void 0 ? {} : _a).itemId;
            if (itemId) {
                _this.props.selectVideo(itemId);
            }
        };
        _this.playVideo = function (_a) {
            var id = _a.id;
            var _b = _this.props, channel = _b.channel, requestPlayVideo = _b.requestPlayVideo, openFullScreenVideoOverlay = _b.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _b.closeFullScreenVideoOverlay, isPlayInFrame = _b.isPlayInFrame, isPortableDevice = _b.isPortableDevice;
            if (isPlayInFrame || isPortableDevice) {
                requestPlayVideo(id);
                return;
            }
            openFullScreenVideoOverlay(channel.id, id, true, closeFullScreenVideoOverlay);
        };
        cxs.prefix(props.styleId);
        return _this;
    }
    SliderLayout.prototype.componentDidMount = function () {
        appStatus.setMarkerReady(this.props.channelLayout);
    };
    SliderLayout.prototype.componentDidUpdate = function () {
        this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
            previousEventName: 'videoList.searchByCategory.requested',
        });
    };
    SliderLayout.prototype.getNavButtonClassName = function () {
        var _a = this.props, isArrowsOutside = _a.isArrowsOutside, sliderMargin = _a.sliderMargin, sliderPadding = _a.sliderPadding, thumbnailHeight = _a.thumbnailHeight;
        var buttonWidthClassName = isArrowsOutside
            ? cxs({ minWidth: sliderMargin + sliderPadding + "px" })
            : null;
        return classnames(styles.navButton, cxs({ height: thumbnailHeight + "px" }), buttonWidthClassName);
    };
    SliderLayout.prototype.getPublishedChannelItemsCount = function () {
        var _a = this.props, channel = _a.channel, videoIds = _a.videoIds, nextVideosCursor = _a.nextVideosCursor;
        return !nextVideosCursor && videoIds.length
            ? videoIds.length
            : channel.videosCount;
    };
    SliderLayout.prototype.getMaxItemsCount = function () {
        var _a = this.props, videoIds = _a.videoIds, selectedCategory = _a.selectedCategory, nextVideosCursor = _a.nextVideosCursor, sliderWidth = _a.sliderWidth, itemWidth = _a.itemWidth;
        var itemsPerPage = Math.ceil(sliderWidth / itemWidth);
        if (!selectedCategory || !nextVideosCursor) {
            return this.getPublishedChannelItemsCount();
        }
        return Math.max(videoIds.length + (nextVideosCursor ? 1 : 0), itemsPerPage);
    };
    SliderLayout.prototype.renderSlider = function () {
        var _a = this.props, channel = _a.channel, videoIds = _a.videoIds, isFetching = _a.isFetching, selectedCategory = _a.selectedCategory, mainVideoIndex = _a.mainVideoIndex, nextVideosCursor = _a.nextVideosCursor, isArrowsOutside = _a.isArrowsOutside, isNavAlwaysVisible = _a.isNavAlwaysVisible, thumbnailSpacing = _a.thumbnailSpacing, sliderWidth = _a.sliderWidth, itemWidth = _a.itemWidth, thumbnailHeight = _a.thumbnailHeight, isRTL = _a.isRTL, isEditorMode = _a.isEditorMode;
        var navButtonClassName = this.getNavButtonClassName();
        return (React.createElement(Slider, { width: sliderWidth, reset: isEditorMode, containerStyle: isArrowsOutside ? null : this.getSliderAreaStyles(), navButtonClassName: navButtonClassName, shadyNavButtonClassName: styles.shadyNav, thumbnailHeight: thumbnailHeight, itemsPerPageCount: 1, itemIds: videoIds, horizontalSpacing: thumbnailSpacing, itemsCount: this.getMaxItemsCount(), itemWidth: itemWidth, activeItemOverlay: this.renderActiveItemContent(), onActiveItemLeftFrame: this.handleActiveItemLeftFrame, renderItem: this.renderItem, canLoadMoreItems: Boolean(nextVideosCursor && !isFetching), mainItemIndex: mainVideoIndex, loadMoreItems: this.loadMoreItems, accessibilityLabels: {
                containerAriaLabel: i18n.t('widget.accessibility.channel-videos', {
                    channelTitle: channel.title,
                }),
                prevButtonAriaLabel: i18n.t('widget.accessibility.prev-videos'),
                nextButtonAriaLabel: i18n.t('widget.accessibility.next-videos'),
            }, withDisabledNav: Boolean(selectedCategory) && isFetching, withArrowsOutside: isArrowsOutside, withNavAlwaysVisible: isNavAlwaysVisible, withKeyboardNavigation: true, withShadyNavigation: true, isRTL: isRTL }));
    };
    SliderLayout.prototype.renderEmptyState = function () {
        return (React.createElement("div", { "data-hook": "slider-empty", style: this.getSliderAreaStyles(), className: styles.empty }, !this.props.isFetching && (React.createElement("div", { className: styles.emptyContent }, i18n.t('widget.this-channel-is-coming-soon')))));
    };
    SliderLayout.prototype.renderContent = function () {
        var _a = this.props, mainVideo = _a.mainVideo, selectedCategory = _a.selectedCategory, nextVideosCursor = _a.nextVideosCursor, videoIds = _a.videoIds, isFetching = _a.isFetching;
        if (!isFetching &&
            selectedCategory &&
            !nextVideosCursor &&
            !videoIds.length) {
            return this.renderEmptySearchState(i18n.t('widget.categories.no-videos-in-category'));
        }
        return mainVideo ? this.renderSlider() : this.renderEmptyState();
    };
    SliderLayout.prototype.renderEmptySearchState = function (message) {
        return (React.createElement("div", { style: this.getSliderAreaStyles() },
            React.createElement(NoResults, { dataHook: "slider-empty-search-results", message: message, onButtonClick: this.props.resetSearch })));
    };
    SliderLayout.prototype.renderActions = function () {
        var _a = this.props, containerMargins = _a.containerMargins, isArrowsOutside = _a.isArrowsOutside, channel = _a.channel, isResponsive = _a.isResponsive, videoIds = _a.videoIds;
        var style = isResponsive
            ? null
            : __assign({ padding: !containerMargins && !isArrowsOutside ? '0 15px' : 0 }, this.getSliderAreaStyles());
        if (isResponsive && !videoIds.length) {
            return null;
        }
        return (React.createElement(ActionBar, { onPageRefresh: _.noop, style: style, channelData: channel, isResponsive: isResponsive }));
    };
    SliderLayout.prototype.renderSliderContent = function () {
        var _a = this.props, selectedCategory = _a.selectedCategory, minSliderHeight = _a.minSliderHeight;
        return (React.createElement("section", { className: styles.slider, key: "slider-" + selectedCategory, "data-hook": "slider-container", style: { minHeight: '100%', height: minSliderHeight || '100%' } }, this.renderContent()));
    };
    SliderLayout.prototype.renderResponsiveSliderContent = function () {
        var _a = this.props, videoIds = _a.videoIds, videoByIds = _a.videoByIds, channel = _a.channel, currentSiteUser = _a.currentSiteUser, minSliderHeight = _a.minSliderHeight;
        if (!videoIds.length) {
            return (React.createElement("div", { style: {
                    height: minSliderHeight,
                } },
                React.createElement(SliderEmptyState, null)));
        }
        return (React.createElement(VideoList, { videoIds: videoIds, videoByIds: videoByIds, itemsCount: this.getMaxItemsCount(), channel: channel, currentSiteUser: currentSiteUser, onPlayRequestedBi: this.logVideoPlayRequested }));
    };
    SliderLayout.prototype.renderActiveItemContent = function () {
        var _a;
        var _b = this.props, channel = _b.channel, mainVideo = _b.mainVideo, itemWidth = _b.itemWidth, thumbnailHeight = _b.thumbnailHeight, isPlayerVisible = _b.isPlayerVisible, isVideoPlaying = _b.isVideoPlaying, isVideoPaused = _b.isVideoPaused, isInlineShareVisible = _b.isInlineShareVisible, showAutoPlay = _b.showAutoPlay, PlayerComponent = _b.PlayerComponent;
        if (!isPlayerVisible || !PlayerComponent) {
            return null;
        }
        return (React.createElement("div", { "data-hook": "player-wrapper", className: classnames(styles.player, (_a = {},
                _a[styles.active] = isVideoPlaying || isVideoPaused,
                _a)) },
            React.createElement(Player, { width: itemWidth, height: thumbnailHeight, PlayerComponent: PlayerComponent }),
            isInlineShareVisible && (React.createElement(ShareOverlay, { key: channel.id, channelData: channel, videoItem: mainVideo })),
            showAutoPlay && React.createElement(AutoPlayVideo, null)));
    };
    SliderLayout.prototype.render = function () {
        var _a;
        var _b = this.props, minSliderWidth = _b.minSliderWidth, onContainerRef = _b.onContainerRef, itemWidth = _b.itemWidth, isResized = _b.isResized, channel = _b.channel, layoutName = _b.layoutName, isVideoPlaying = _b.isVideoPlaying, mainVideoId = _b.mainVideoId, isResponsive = _b.isResponsive;
        var style = isResponsive
            ? {}
            : {
                minWidth: minSliderWidth,
                paddingTop: SLIDER_PADDING,
                paddingBottom: SLIDER_PADDING,
            };
        return (React.createElement("main", { className: classnames(styles.container, (_a = {},
                _a[styles.isResized] = isResized,
                _a)), ref: onContainerRef, "data-thumbnail-min-width": getThumbnailMinWidthAttribute(itemWidth), "data-hook": "widget-container", "data-channel-layout": layoutName, "aria-label": i18n.t('widget.accessibility.channel-videos-widget', {
                channelTitle: channel.title,
            }), tabIndex: "0", style: style },
            this.renderActions(),
            isResponsive
                ? this.renderResponsiveSliderContent()
                : this.renderSliderContent(),
            React.createElement(OpenFullscreenModalShare, { itemWidth: itemWidth }),
            React.createElement(PaymentEvents, { onRent: this.setCurrentVideoFromPayment, onSale: this.setCurrentVideoFromPayment }),
            React.createElement(LiveStartHandler, { playVideo: this.playVideo, isVideoPlaying: isVideoPlaying, selectedVideoId: mainVideoId }),
            React.createElement(WidgetPerformanceLoggers, null),
            __SERVER__ && React.createElement("style", null, cxs.css())));
    };
    SliderLayout.propTypes = {
        styleId: PropTypes.string,
        channel: PropTypes.object.isRequired,
        layoutName: PropTypes.string.isRequired,
        currentSiteUser: PropTypes.object,
        isEditorMode: PropTypes.bool.isRequired,
        mainVideo: PropTypes.object,
        mainVideoId: PropTypes.string,
        mainVideoIndex: PropTypes.number,
        videoIds: PropTypes.array.isRequired,
        videoByIds: PropTypes.object.isRequired,
        nextVideosCursor: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        loadMore: PropTypes.func.isRequired,
        loadMoreVideoPages: PropTypes.func.isRequired,
        selectVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        resetSearch: PropTypes.func.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        channelLayout: PropTypes.number.isRequired,
        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isNavAlwaysVisible: PropTypes.bool.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        isVideoPaused: PropTypes.bool.isRequired,
        isResponsive: PropTypes.bool.isRequired,
        containerMargins: PropTypes.number.isRequired,
        thumbnailSpacing: PropTypes.number.isRequired,
        isArrowsOutside: PropTypes.bool.isRequired,
        itemWidth: PropTypes.number.isRequired,
        thumbnailHeight: PropTypes.number.isRequired,
        sliderMargin: PropTypes.number.isRequired,
        sliderPadding: PropTypes.number.isRequired,
        minSliderWidth: PropTypes.number.isRequired,
        minSliderHeight: PropTypes.number.isRequired,
        isResized: PropTypes.bool.isRequired,
        isPlayerVisible: PropTypes.bool.isRequired,
        isInlineShareVisible: PropTypes.bool.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        showAutoPlay: PropTypes.bool,
        selectedCategory: PropTypes.string,
        onContainerRef: PropTypes.func.isRequired,
        sliderWidth: PropTypes.number.isRequired,
        openFullScreenVideoOverlay: PropTypes.func.isRequired,
        closeFullScreenVideoOverlay: PropTypes.func.isRequired,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
        isRTL: PropTypes.bool,
    };
    SliderLayout = __decorate([
        connect(function (state, props) {
            var mainVideo = getMainVideo(state);
            var videoIds = getVideoIds(state);
            var channelLayout = getChannelLayout(state);
            var mainVideoId = _.get(mainVideo, 'id');
            return {
                styleId: getCompId(state),
                isEditorMode: viewModeSelectors.isEditorMode(state),
                isResponsive: isResponsiveEditor(state) && isResponsiveEnabled(),
                channel: getChannelForWidget(state),
                channelLayout: channelLayout,
                layoutName: _.get(channelLayoutToNameMap, channelLayout, ''),
                currentSiteUser: getCurrentSiteUser(state),
                mainVideo: mainVideo,
                mainVideoId: mainVideoId,
                mainVideoIndex: _.indexOf(videoIds, mainVideoId),
                videoIds: videoIds,
                videoByIds: getVideosGroupedByIds(state),
                nextVideosCursor: getCursor(state),
                isFetching: getIsFetching(state),
                isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
                isNavAlwaysVisible: isSliderNavAlwaysVisible(state),
                isVideoPlaying: isVideoPlayingOptimistic(state),
                isVideoPaused: isVideoPausedOptimistic(state),
                thumbnailSpacing: getThumbnailSpacing(state),
                containerMargins: getContainerMargins(state),
                selectedCategory: getCategory(state),
                isPlayerVisible: canRenderPlayer(state),
                isInlineShareVisible: isInlineShareVisible(state, props),
                isPlayInFrame: isPlayInFrame(state),
                showAutoPlay: showAutoPlay(state),
                isRTL: isRTL(state),
            };
        }, {
            loadMore: loadMore,
            loadMoreVideoPages: loadMoreVideoPages,
            selectVideo: selectVideo,
            pauseVideo: pauseVideo,
            resetSearch: resetSearch,
            requestPlayVideo: requestPlayVideo,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
            logWidgetSystem: logWidgetSystem,
            logWidgetVidClick: logWidgetVidClick,
        }),
        withStyles(styles)
    ], SliderLayout);
    return SliderLayout;
}(React.Component));
export { SliderLayout };
export default _.flow(withResizer, withApplyForcedHeight, withPlayerModuleLoader)(SliderLayout);
