import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { pubSubPublish } from 'shared/worker/actions/pub-sub/publish';
import EVENTS from 'shared/constants/events';
var PaymentEventProxy = /** @class */ (function (_super) {
    __extends(PaymentEventProxy, _super);
    function PaymentEventProxy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.proxyEvent = function (event) {
            if (_.get(event, 'data.completed', false)) {
                _this.props.pubSubPublish(EVENTS.PAYMENT.PAYMENT_EVENT, {
                    message: event.data,
                });
            }
        };
        return _this;
    }
    //at least, handling unsubscribe payment success event, maybe paypal too.
    PaymentEventProxy.prototype.componentDidMount = function () {
        window.addEventListener('message', this.proxyEvent, false);
    };
    PaymentEventProxy.prototype.componentWillUnmount = function () {
        window.removeEventListener('message', this.proxyEvent, false);
    };
    PaymentEventProxy.prototype.render = function () {
        return null;
    };
    PaymentEventProxy = __decorate([
        connect(null, { pubSubPublish: pubSubPublish })
    ], PaymentEventProxy);
    return PaymentEventProxy;
}(React.Component));
export default PaymentEventProxy;
