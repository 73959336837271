import { __assign } from "tslib";
import _ from 'lodash';
import { NAMES } from 'widget/redux/client/actions/videos';
import replaceArrays from 'widget/utils/replace-arrays';
export default (function (state, _a) {
    var _b;
    if (state === void 0) { state = {}; }
    var payload = _a.payload, type = _a.type;
    if (type === NAMES.UPDATE_VIDEO_LIVE_STATUS) {
        return __assign(__assign({}, state), (_b = {}, _b[payload.id] = payload, _b));
    }
    var videos = _.get(payload, 'response.data.entities.videos');
    if (videos) {
        return _.mergeWith({}, state, videos, replaceArrays);
    }
    return state;
});
