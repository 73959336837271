var _a;
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as CHANNEL_GET } from 'widget/redux/server/actions/channel/get-public';
var PATH_TO_CHANNELS = 'response.data.entities.channels';
var defaultState = {};
export default handleActions((_a = {},
    _a[CHANNEL_GET.SUCCESS] = function (state, _a) {
        var payload = _a.payload;
        var channels = _.get(payload, PATH_TO_CHANNELS);
        var channelData = _.values(channels)[0];
        return channelData || state;
    },
    _a), defaultState);
