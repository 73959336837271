import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getAllSettings } from 'shared/selectors/app-settings';
import { canShowChannelCover } from 'widget/components/player-overlay/selectors';
import { notForPreview } from 'shared/utils/not-for-preview';
import { openFullScreenPurchaseOverlay, openFullScreenRentOverlay, openFullScreenSubscribeOverlay, } from 'widget/utils/open-overlay';
import { logPurchaseButtonClicked } from 'widget/components/player-overlay/bi';
import { createProxy } from 'shared/worker/lib';
import { getFullAccessActionName, FULL_ACCESS_ACTION_NAMES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
var OPEN_FULL_SCREEN_OVERLAY = 'CLIENT.PLAYER_OVERLAY.OPEN_FULL_SCREEN_OVERLAY';
var openOverlayAction = createAction(OPEN_FULL_SCREEN_OVERLAY);
var openOverlay = function (_a, actionName) {
    var channelData = _a.channelData, videoItem = _a.videoItem;
    return function (dispatch) {
        switch (actionName) {
            case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
                return dispatch(openFullScreenSubscribeOverlay(channelData.id));
            case FULL_ACCESS_ACTION_NAMES.RENT:
                return dispatch(openFullScreenRentOverlay(channelData.id, videoItem.id));
            default:
                return dispatch(openFullScreenPurchaseOverlay(channelData.id, videoItem.id));
        }
    };
};
export var openPlayerOverlay = createProxy('openPlayerOverlay', function (videoItem, showChannelCover) { return function (dispatch, getState) {
    var state = getState();
    var channelData = getChannelForWidget(state);
    var appSettings = getAllSettings(state);
    var isChannelCover = canShowChannelCover(state, { showChannelCover: showChannelCover });
    var actionName = getFullAccessActionName(channelData, videoItem);
    var props = {
        channelData: channelData,
        appSettings: appSettings,
        videoItem: videoItem,
        isChannelCover: isChannelCover,
    };
    notForPreview(function () {
        dispatch(logPurchaseButtonClicked(props));
        dispatch(openOverlay(props, actionName));
    })();
    dispatch(openOverlayAction());
}; });
