import { __assign } from "tslib";
import { sides, popoutPositions, trianglePositions, } from 'shared/constants/popout';
import invertSide from './invert-side';
var POPOUT_OFFSET = 12;
var POPOUT_OVERSHOOT = 23;
var defaultPositioning = {
    triangleSide: sides.TOP,
    popoutSide: sides.BOTTOM,
    popoutPosition: popoutPositions.CENTER,
    trianglePosition: trianglePositions.CENTER,
};
export function byPopoutSide(referenceElement, popoutElement, positioning) {
    if (positioning === void 0) { positioning = defaultPositioning; }
    if (!referenceElement || !popoutElement) {
        return { left: 0, top: 0, offsetX: 0 };
    }
    var _a = __assign(__assign({}, defaultPositioning), positioning), popoutSide = _a.popoutSide, popoutPosition = _a.popoutPosition, trianglePosition = _a.trianglePosition;
    var referenceRect = referenceElement.getBoundingClientRect();
    var popoutRect = popoutElement.getBoundingClientRect();
    var referenceTop = referenceRect.top, referenceLeft = referenceRect.left, referenceWidth = referenceRect.width, referenceHeight = referenceRect.height;
    var popoutWidth = popoutRect.width, popoutHeight = popoutRect.height;
    var windowWidth = window.innerWidth, windowScrollX = window.pageXOffset;
    var top = referenceTop;
    var left = referenceLeft + windowScrollX;
    if (popoutSide === sides.TOP) {
        top -= popoutHeight + POPOUT_OFFSET;
    }
    else if (popoutSide === sides.LEFT) {
        top -= popoutHeight / 2 - referenceHeight / 2;
        left -= popoutWidth + POPOUT_OFFSET;
    }
    else if (popoutSide === sides.RIGHT) {
        top -= popoutHeight / 2 - referenceHeight / 2;
        left += referenceWidth + POPOUT_OFFSET;
    }
    else if (popoutSide === sides.BOTTOM) {
        top += referenceHeight + POPOUT_OFFSET;
    }
    if (popoutSide === sides.TOP || popoutSide === sides.BOTTOM) {
        if (popoutPosition === popoutPositions.RIGHT) {
            left += referenceWidth - popoutWidth + POPOUT_OVERSHOOT;
        }
        else if (popoutPosition === popoutPositions.LEFT) {
            left -= POPOUT_OVERSHOOT;
        }
        else {
            left -= popoutWidth / 2 - referenceWidth / 2;
        }
    }
    var offsetX = 0;
    if (trianglePosition === trianglePositions.CENTER &&
        windowScrollX + windowWidth < left + popoutWidth) {
        offsetX =
            left + popoutWidth + POPOUT_OFFSET - (windowScrollX + windowWidth);
        left -= offsetX;
    }
    // remove crop from left side
    if (left < POPOUT_OFFSET) {
        offsetX = -POPOUT_OFFSET + left;
        left = POPOUT_OFFSET;
    }
    return { top: top, left: left, offsetX: offsetX };
}
export function byTriangleSide(referenceElement, popoutElement, positioning) {
    if (positioning === void 0) { positioning = defaultPositioning; }
    positioning = __assign(__assign({}, positioning), { popoutSide: invertSide(positioning.triangleSide) });
    return byPopoutSide(referenceElement, popoutElement, positioning);
}
