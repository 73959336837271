import { __decorate, __extends, __read, __spread } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUserEmail } from 'shared/selectors/current-site-user';
import { canShowChannelShare, getCanShowChannelInfo, getCanShowSignIn, canShowChannelTitle, isMenuPreview, isActionBarPreview, isRTL, } from 'shared/selectors/app-settings';
import { canShowCancelSubscription, canShowSubscription, } from 'widget/selectors/subscription';
import { logOutCurrentMember } from 'shared/utils/auth';
import EVENTS from 'shared/constants/events';
import WixSDK from 'shared/utils/wix-sdk';
import MenuItem from 'widget/components/action-bar/menu/menu-item/menu-item';
import ChannelSubscriptionLabel from 'shared/components/channel-subscription-label/channel-subscription-label';
import Popover from '@wix/wix-vod-shared/dist/src/widget/ui-components/popover/popover';
import Title from 'widget/components/action-bar/menu/title/title';
import MenuIcon from 'widget/components/action-bar/menu/icons/menu.svg';
import SignInIcon from 'widget/components/action-bar/menu/icons/sign-in.svg';
import { withStyles } from 'shared/utils/withStyles';
import styles from './menu.styl';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { logBi } from 'shared/worker/actions/bi';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleLogoutEvent = function () {
            _this.props.logOutCurrentMember();
            _this.unsubscribeLogoutEvent();
        };
        _this.handlePopoverToggle = function (isOpen) {
            if (isOpen) {
                _this.logMenuClickedBi();
            }
        };
        _this.logMenuClickedBi = function () {
            var channel = _this.props.channel;
            _this.props.logBi('widget.menu.clicked', { channelID: channel.id });
        };
        _this.handleSubscribeRequest = function () {
            var onSubscriptionRequest = _this.props.onSubscriptionRequest;
            onSubscriptionRequest();
        };
        _this.handleChannelInfoRequest = function () {
            var _a = _this.props, onInfoRequest = _a.onInfoRequest, channel = _a.channel;
            _this.props.logBi('widget.aboutCh.clicked', { channelID: channel.id });
            onInfoRequest();
        };
        return _this;
    }
    Menu.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(EVENTS.SESSION.LOGOUT_USER, this.handleLogoutEvent, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    Menu.prototype.componentWillUnmount = function () {
        this.unsubscribeLogoutEvent();
    };
    Menu.prototype.unsubscribeLogoutEvent = function () {
        WixSDK.PubSub.unsubscribe(EVENTS.SESSION.LOGOUT_USER, this.handleLogoutEvent);
    };
    Menu.prototype.renderSubscribe = function () {
        var channel = this.props.channel;
        return (React.createElement(MenuItem, { onClick: this.handleSubscribeRequest, key: "channel-subscription", dataHook: "channel-subscription-button" },
            React.createElement(ChannelSubscriptionLabel, { dealInfo: channel.dealInfo })));
    };
    Menu.prototype.renderSubscriptionCancel = function () {
        var onCancelSubscriptionRequest = this.props.onCancelSubscriptionRequest;
        return (React.createElement(MenuItem, { onClick: onCancelSubscriptionRequest, key: "channel-subscription-cancel", dataHook: "channel-subscription-cancel" }, i18n.t('widget.payments.cancel-subscription')));
    };
    Menu.prototype.renderShare = function () {
        return (React.createElement(MenuItem, { onClick: this.props.onShareRequest, key: "channel-share", dataHook: "share-overlay-button" }, i18n.t('widget.accessibility.share')));
    };
    Menu.prototype.renderSignIn = function (hasOtherItems) {
        var _a;
        var _b = this.props, currentSiteUserEmail = _b.currentSiteUserEmail, onShowAccountInfoRequest = _b.onShowAccountInfoRequest, onLogInRequest = _b.onLogInRequest;
        var className = classnames(styles.account, (_a = {},
            _a[styles.withSeparator] = hasOtherItems,
            _a));
        if (currentSiteUserEmail) {
            return (React.createElement(MenuItem, { onClick: onShowAccountInfoRequest, className: className, key: "channel-account-info", icon: React.createElement(SignInIcon, { className: styles.signIn }), dataHook: "channel-account-info" }, currentSiteUserEmail));
        }
        return (React.createElement(MenuItem, { onClick: onLogInRequest, className: className, key: "channel-login", dataHook: "login-button" }, i18n.t('widget.account.sign-in')));
    };
    Menu.prototype.renderChannelInfo = function () {
        return (React.createElement(MenuItem, { onClick: this.handleChannelInfoRequest, key: "channel-info", dataHook: "channel-info-button" }, i18n.t('widget.compact-view.components.action-bar.channel-info-tooltip')));
    };
    Menu.prototype.renderContent = function () {
        var _a = this.props, canShowSignIn = _a.canShowSignIn, canShowChannelInfo = _a.canShowChannelInfo, canShowChannelShare = _a.canShowChannelShare, canShowSubscription = _a.canShowSubscription, canShowCancelSubscription = _a.canShowCancelSubscription;
        var restItems = _.compact([
            canShowSubscription && this.renderSubscribe(),
            canShowCancelSubscription && this.renderSubscriptionCancel(),
            canShowChannelShare && this.renderShare(),
            canShowChannelInfo && this.renderChannelInfo(),
        ]);
        var content = _.compact(__spread([
            canShowSignIn && this.renderSignIn(restItems.length)
        ], restItems));
        return _.size(content) ? (React.createElement("div", { className: styles.popoverMenuContainer }, content)) : null;
    };
    Menu.prototype.renderTitle = function () {
        var _a = this.props, canShowChannelTitle = _a.canShowChannelTitle, channel = _a.channel;
        return canShowChannelTitle ? (React.createElement("div", { className: styles.title },
            React.createElement(Title, { dataHook: "channel-title" }, channel.title))) : null;
    };
    Menu.prototype.renderMenuWithTitle = function (content) {
        var _a = this.props, channel = _a.channel, isRTL = _a.isRTL;
        return (React.createElement(Popover, { ariaRole: "combobox", openerClassName: styles.menuContainer, ariaLabel: channel.title, isOpen: this.props.isMenuOpen, className: styles.popoverMenu, content: content, onToggle: this.handlePopoverToggle, isRTL: isRTL },
            React.createElement("div", { "data-hook": "menu-button", className: styles.menu },
                React.createElement(MenuIcon, { className: styles.menuButton }),
                this.renderTitle())));
    };
    Menu.prototype.render = function () {
        var content = this.renderContent();
        return (React.createElement("div", { className: styles.container, "data-hook": "channel-actions" }, content ? this.renderMenuWithTitle(content) : this.renderTitle()));
    };
    Menu.propTypes = {
        canShowChannelTitle: PropTypes.bool.isRequired,
        canShowSignIn: PropTypes.bool.isRequired,
        canShowChannelInfo: PropTypes.bool.isRequired,
        canShowChannelShare: PropTypes.bool.isRequired,
        canShowSubscription: PropTypes.bool.isRequired,
        canShowCancelSubscription: PropTypes.bool.isRequired,
        currentSiteUserEmail: PropTypes.string.isRequired,
        isMenuOpen: PropTypes.bool.isRequired,
        isRTL: PropTypes.bool,
        channel: PropTypes.object.isRequired,
        onInfoRequest: PropTypes.func.isRequired,
        onShareRequest: PropTypes.func.isRequired,
        onSubscriptionRequest: PropTypes.func.isRequired,
        onCancelSubscriptionRequest: PropTypes.func.isRequired,
        onLogInRequest: PropTypes.func.isRequired,
        onShowAccountInfoRequest: PropTypes.func.isRequired,
    };
    Menu = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({
            isMenuOpen: isMenuPreview(state) || isActionBarPreview(state),
            currentSiteUserEmail: getCurrentUserEmail(state),
            canShowChannelTitle: canShowChannelTitle(state),
            canShowSignIn: getCanShowSignIn(state),
            canShowChannelInfo: getCanShowChannelInfo(state),
            canShowChannelShare: canShowChannelShare(state),
            canShowSubscription: canShowSubscription(state),
            canShowCancelSubscription: canShowCancelSubscription(state),
            isRTL: isRTL(state),
        }); }, { logBi: logBi, logOutCurrentMember: logOutCurrentMember }),
        withStyles(styles)
    ], Menu);
    return Menu;
}(React.Component));
export default Menu;
