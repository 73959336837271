var _a;
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as PLAYER_UI_VISIBILITY_ACTIONS } from 'widget/redux/client/actions/player/ui';
var defaultState = {
    isVisible: null,
};
export default handleActions((_a = {},
    _a[PLAYER_UI_VISIBILITY_ACTIONS.SHOW] = _.constant({ isVisible: true }),
    _a[PLAYER_UI_VISIBILITY_ACTIONS.HIDE] = _.constant({ isVisible: false }),
    _a), defaultState);
