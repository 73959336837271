import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { withStyles } from 'shared/utils/withStyles';
import styles from './scrollbar-wrapper.styl';
var ScrollbarWrapper = /** @class */ (function (_super) {
    __extends(ScrollbarWrapper, _super);
    function ScrollbarWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRef = function (node) {
            if (!node) {
                return;
            }
            _this.props.getRef(node);
        };
        return _this;
    }
    ScrollbarWrapper.prototype.render = function () {
        var _a = this.props, onlyOnHover = _a.onlyOnHover, scrollX = _a.scrollX, scrollY = _a.scrollY;
        var props = _.pick(this.props, 'className', 'children', 'style');
        var classNames = classnames('scrollbar-wrapper', {
            'only-on-hover': onlyOnHover,
            'scroll-x': scrollX,
            'scroll-y': scrollY,
        });
        return React.createElement("div", __assign({}, props, { ref: this.getRef, styleName: classNames }));
    };
    ScrollbarWrapper.propTypes = {
        scrollX: PropTypes.bool,
        scrollY: PropTypes.bool,
        onlyOnHover: PropTypes.bool,
        getRef: PropTypes.func,
    };
    ScrollbarWrapper.defaultProps = {
        scrollX: false,
        scrollY: true,
        onlyOnHover: false,
        getRef: _.noop,
    };
    ScrollbarWrapper = __decorate([
        withStyles(styles),
        VODCSSModules(styles)
    ], ScrollbarWrapper);
    return ScrollbarWrapper;
}(React.Component));
var WidgetScrollbarWrapper = /** @class */ (function (_super) {
    __extends(WidgetScrollbarWrapper, _super);
    function WidgetScrollbarWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WidgetScrollbarWrapper.prototype.render = function () {
        var props = _.omit(this.props, 'styles');
        return React.createElement(ScrollbarWrapper, __assign({}, props, { styleName: "widget" }));
    };
    WidgetScrollbarWrapper = __decorate([
        VODCSSModules(styles)
    ], WidgetScrollbarWrapper);
    return WidgetScrollbarWrapper;
}(React.Component));
export { WidgetScrollbarWrapper };
