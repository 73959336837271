import { isSubscriptionEnabled, isSubscribedTo, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
/**
 * Is video subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @return {Boolean}
 */
export function isSubscriptionButtonVisible(_a) {
    var _b = _a.channel, channel = _b === void 0 ? {} : _b;
    return isSubscriptionEnabled(channel) && !isSubscribedTo(channel);
}
