import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { notForPreview } from 'shared/utils/not-for-preview';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { openFullScreenChannelOverlay } from 'shared/utils/open-overlay';
import { openFullScreenMemberOnlyOverlay } from 'widget/utils/open-overlay';
import { storeForReload } from 'widget/utils/reload';
import { MAX_WIDGET_WIDTH, stripLayoutThumbnailSize, } from 'widget/utils/videos-sizes/videos-sizes';
import { isSearchInputVisible, isCategoriesDropdownVisible, } from 'shared/selectors/layout';
import { getCanShowChannelInfo, getCanShowSignIn, isThumbnailsPreviewHover, getAllSettings, isSingleVideo, canShowExpandedVideoShare, isMainItemChannelCover, canShowChannelShare, isVideoListAlwaysShow, isVideoListExpandOnClick, isVideoListNeverShow, isRTL, } from 'shared/selectors/app-settings';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';
import { selectVideo, resetSelectedVideo, } from 'widget/redux/client/actions/select-video';
import { searchByString, searchByCategory, } from 'widget/redux/client/actions/search';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from 'widget/redux/client/actions/full-screen-modal';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';
import { getVideoIdsByPageNumber, getCurrentPageIndex, getCurrentVideoIndex, getIsFetching, getVideoIds, hasPrevVideo, hasNextVideo, } from 'widget/redux/client/lazy-channel-videos/selectors';
import { goToLazyVideosPageIndex } from 'widget/redux/client/lazy-channel-videos/actions';
import { playSelectedVideo } from 'widget/redux/client/player-overlay/actions/play-selected-video';
import { getMainVideo } from 'widget/selectors/get-video';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getSearchQuery } from 'shared/selectors/search';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { getIsSearchResultEmpty } from 'widget/selectors/search-results';
import PlayerBlocksVisibility from 'widget/containers/player-blocks-visibility';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import Icon from 'shared/components/icon/icon';
import { LinkButton } from 'shared/components/button/button';
import { StripWidgetDropdown } from 'shared/components/dropdown/dropdown';
import AccountButton from 'widget/components/account/account-button/account-button';
import ChannelInfoButton from 'widget/components/channel-actions/channel-info-button';
import ChannelSubscriptionButton from 'widget/components/channel-actions/channel-subscription-button';
import StripPlayerWrapper from 'widget/layouts/strip/components/strip-player-wrapper/strip-player-wrapper';
import Categories from 'widget/components/categories/categories';
import SearchBar from 'widget/components/search-bar/search-bar';
import Videos from 'widget/layouts/strip/components/videos/videos';
import OverlayStrip from 'widget/components/player-overlay/strip/strip';
import EmptySearchResults from 'widget/components/empty-search-results/empty-search-results';
import Button from 'widget/components/button/button';
import DeeplinkPopups from 'widget/components/deeplink-popups/deeplink-popups';
import AccessibleVideosContainer from 'widget/components/accessible-videos-container/accessible-videos-container';
import ShareButton from 'widget/components/share-button/share-button';
import NoVideosContent from 'widget/components/no-content/no-videos-content/no-videos-content';
import { getCurrentChannelId } from 'widget/selectors/channel-info';
/* containers */
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import ChannelActionsContainer from 'shared/containers/channel-actions/channel-actions';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { withStyles } from 'shared/utils/withStyles';
import styles from './strip.styl';
var SEARCH_LINE_HEIGHT = 42;
var FIXED_NO_SEARCH_RESULT_MIN_HEIGHT = 350;
var RESIZE_INTERVAL = 300;
var DEFAULT_STATE = {
    isSearchBarExpanded: false,
    currentCategory: null,
    areAllVideosVisible: false,
};
var StripView = /** @class */ (function (_super) {
    __extends(StripView, _super);
    function StripView(props) {
        var _this = _super.call(this, props) || this;
        _this.videosContainerRef = null;
        _this.playerOverlayContainerRef = null;
        _this.containerRef = null;
        _this.saveRef = function (name, ref) {
            _this[name] = ref;
        };
        _this.focusVideosContainer = function () {
            focus(_this.videosContainerRef);
            window.removeEventListener('resize', _this.focusVideosContainer);
        };
        _this.handleSlideToPrev = function () {
            _this.props.logWidgetSystem('videoList.changePage.requested');
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            goToLazyVideosPageIndex(currentVideosPageNumber - 1);
        };
        _this.handleSlideToNext = function () {
            _this.props.logWidgetSystem('videoList.changePage.requested');
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            goToLazyVideosPageIndex(currentVideosPageNumber + 1);
        };
        _this.handleThumbnailClick = function (video) {
            if (video.id !== _this.props.mainVideoId || _this.props.canShowChannelCover) {
                _this.props.selectVideo(video.id);
            }
        };
        _this.reset = function (props) {
            if (props === void 0) { props = _this.props; }
            _this.setState(__assign(__assign(__assign({ searchBarValue: '' }, DEFAULT_STATE), _this.getDefaultPlayerConfig(props)), { areAllVideosVisible: props.isThumbnailsPreviewHover }));
            _this.props.goToLazyVideosPageIndex(0);
        };
        _this.resizeByContentInterval = null;
        _this.currentContentHeight = 0;
        _this.resizeByContent = function () {
            var _a = _this.props, setWidgetHeight = _a.setWidgetHeight, windowSize = _a.windowSize;
            var height = Math.ceil(_this.contentHeight);
            if (!height || height === _this.currentContentHeight) {
                return;
            }
            _this.currentContentHeight = height;
            setWidgetHeight(height, windowSize.width, { overflow: false });
        };
        _this.handleCategorySelect = function (category) {
            _this.props.searchByCategory(category);
            _this.props.logWidgetSystem('videoList.searchByCategory.requested');
            _this.setState({
                currentCategory: category,
                searchBarValue: '',
            });
        };
        _this.storeDataForReload = function () {
            _this.props.storeForReload({
                selectedVideoId: _this.props.mainVideoId,
            });
        };
        _this.setCurrentVideoFromPayment = function (paymentEvent) {
            if (paymentEvent.itemId) {
                _this.props.selectVideo(paymentEvent.itemId);
            }
        };
        _this.showChannelInfo = function (event) {
            event.preventDefault();
            _this.props.openFullScreenChannelOverlay(_this.props.channelData.id);
        };
        _this.openMembership = function () {
            _this.props.openFullScreenMemberOnlyOverlay(_this.props.channelData.id, _this.props.mainVideoId);
        };
        _this.handleSearchInputFocus = function () {
            _this.setState({ isSearchBarExpanded: true });
        };
        _this.handleSearchInputBlur = function () {
            _this.setState({ isSearchBarExpanded: false });
        };
        _this.clearSearch = function () {
            _this.setState({ searchBarValue: '', isSearchBarExpanded: false });
            if (!_this.state.currentCategory) {
                //if category is selected nothing to reset
                _this.props.searchByString('');
            }
        };
        _this.searchByQuery = function (value) {
            _this.props.searchByString(value);
            _this.props.logWidgetSystem('videoList.searchByQuery.requested');
            _this.setState({ currentCategory: null });
        };
        _this.handleSearchInputChange = function (searchBarValue) {
            _this.setState({ searchBarValue: searchBarValue });
        };
        _this.playVideo = notForPreview(function (videoItem) {
            var _a = _this.props, currentSiteUser = _a.currentSiteUser, channelData = _a.channelData, mainVideoId = _a.mainVideoId, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay, requestPlayVideo = _a.requestPlayVideo, isPortableDevice = _a.isPortableDevice;
            var id = videoItem.id, memberOnly = videoItem.memberOnly;
            if (isPortableDevice) {
                // we can`t start playing for not rendered player because of browser restriction
                if (id === mainVideoId) {
                    return requestPlayVideo(id);
                }
                return _this.handleThumbnailClick(videoItem);
            }
            if (!memberOnly || (memberOnly && currentSiteUser)) {
                _this.props.playSelectedVideo({ videoItem: videoItem });
                openFullScreenVideoOverlay(channelData.id, id, true, closeFullScreenVideoOverlay);
            }
            else {
                _this.props.openFullScreenMemberOnlyOverlay(channelData.id, id);
            }
        });
        _this.selectNextVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, lazyLoadedVideoIds = _a.lazyLoadedVideoIds, currentIdIndex = _a.currentIdIndex;
            selectVideo(lazyLoadedVideoIds[currentIdIndex + 1]);
        };
        _this.selectPrevVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, lazyLoadedVideoIds = _a.lazyLoadedVideoIds, currentIdIndex = _a.currentIdIndex;
            selectVideo(lazyLoadedVideoIds[currentIdIndex - 1]);
        };
        _this.handleUserToggleVisibilityAllVideos = function () {
            if (!_this.state.areAllVideosVisible) {
                _this.props.logWidgetSystem('videoList.show.requested');
            }
            _this.toggleVisibilityAllVideos();
        };
        _this.toggleVisibilityAllVideos = function () {
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            var nextAreAllVideosVisible = !_this.state.areAllVideosVisible;
            _this.setState({
                areAllVideosVisible: nextAreAllVideosVisible,
            });
            if (nextAreAllVideosVisible && !currentVideosPageNumber) {
                goToLazyVideosPageIndex(0);
            }
        };
        _this.state = __assign(__assign({ searchBarValue: props.searchQuery, isInitialVideosLoaded: false }, DEFAULT_STATE), _this.getDefaultPlayerConfig(props));
        return _this;
    }
    StripView.prototype.componentDidMount = function () {
        var _a = this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
        this.sendSubscriptionDisplayedOnWidget();
        this.resizeByContentInterval = setInterval(this.resizeByContent, RESIZE_INTERVAL);
        if (this.shouldRenderVideos && !currentVideosPageNumber) {
            goToLazyVideosPageIndex(0);
        }
    };
    StripView.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (nextProps.isEditor && this.hasPreviewBecameActive(nextProps)) {
            this.reset(nextProps);
        }
    };
    StripView.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.props, selectedVideoId = _a.selectedVideoId, setWidgetHeight = _a.setWidgetHeight;
        var isSelectedVideoChanged = prevProps.selectedVideoId !== selectedVideoId;
        if (isSelectedVideoChanged) {
            focus(this.playerOverlayContainerRef);
        }
        if (this.props.windowSize.height !== prevProps.windowSize.height &&
            this.currentContentHeight !== this.props.windowSize.height) {
            setWidgetHeight(this.currentContentHeight, this.props.windowSize.width, {
                overflow: false,
            });
        }
        var isAllVideosOpened = prevState.areAllVideosVisible !== this.state.areAllVideosVisible &&
            this.shouldRenderVideos;
        if (isAllVideosOpened) {
            // because of scroll position will be updated after widget resize
            window.addEventListener('resize', this.focusVideosContainer);
        }
    };
    StripView.prototype.componentWillUnmount = function () {
        clearInterval(this.resizeByContentInterval);
    };
    StripView.prototype.sendSubscriptionDisplayedOnWidget = function () {
        var channelData = this.props.channelData;
        if (isSubscriptionButtonVisible({ channel: channelData })) {
            this.props.sendBiEvent('widget.subscription.displayed', {
                whereDisplayed: 'widget',
            });
        }
    };
    Object.defineProperty(StripView.prototype, "shouldRenderVideos", {
        get: function () {
            var _a = this.props, isThumbnailsPreviewHover = _a.isThumbnailsPreviewHover, isVideoListAlwaysShow = _a.isVideoListAlwaysShow, isVideoListExpandOnClick = _a.isVideoListExpandOnClick;
            if (isThumbnailsPreviewHover) {
                return true;
            }
            return isVideoListExpandOnClick
                ? this.state.areAllVideosVisible
                : isVideoListAlwaysShow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "shouldRenderAllVideosButton", {
        get: function () {
            var _a = this.props, isVideoListExpandOnClick = _a.isVideoListExpandOnClick, isSingleVideo = _a.isSingleVideo, isThumbnailsPreviewHover = _a.isThumbnailsPreviewHover;
            if (isThumbnailsPreviewHover) {
                return false;
            }
            if (isSingleVideo) {
                return false;
            }
            return isVideoListExpandOnClick && !this.state.areAllVideosVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "listThumbnailSize", {
        get: function () {
            if (typeof window === 'undefined') {
                return {};
            }
            var widgetWidth = this.props.windowSize.width;
            var videosInRow = this.props.appSettings.numbers.videosInRow;
            return stripLayoutThumbnailSize(widgetWidth, videosInRow);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "videosListHeight", {
        get: function () {
            var _a = this.props, appSettings = _a.appSettings, channelData = _a.channelData;
            var _b = appSettings.numbers, videosInRow = _b.videosInRow, numberOfRows = _b.numberOfRows;
            var videosCount = Math.min(channelData.videosCount, videosInRow * numberOfRows);
            return this.listThumbnailSize.height * Math.ceil(videosCount / videosInRow);
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.isVideoPreviewMode = function () {
        // TODO: clean up video preview
        return false;
    };
    StripView.prototype.getDefaultPlayerConfig = function () {
        return {
            // TODO: move to selector or method. Probably will be used with isWidgetTouched (old canShowChannelCover)
            isVideoPreview: false,
        };
    };
    Object.defineProperty(StripView.prototype, "contentHeight", {
        get: function () {
            if (!this.containerRef) {
                return 0;
            }
            return this.containerRef.clientHeight;
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.hasPreviewBecameActive = function (nextProps) {
        // TODO: rename to videoAsBackground
        return !this.state.isVideoPreview && this.isVideoPreviewMode(nextProps);
    };
    Object.defineProperty(StripView.prototype, "categoriesList", {
        get: function () {
            var categoriesStats = _.get(this.props, 'channelData.statsInfo.categories') || [];
            return _.map(categoriesStats, 'value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "noSearchResults", {
        get: function () {
            var styleNames = classnames('no-search-results', {
                stretched: this.videosListHeight < FIXED_NO_SEARCH_RESULT_MIN_HEIGHT,
            });
            return React.createElement(EmptySearchResults, { styleName: styleNames });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "videosList", {
        get: function () {
            var _a = this.props, appSettings = _a.appSettings, channelData = _a.channelData, videoIdsByPageNumber = _a.videoIdsByPageNumber, currentVideosPageNumber = _a.currentVideosPageNumber, isVideosFetching = _a.isVideosFetching, mainVideo = _a.mainVideo, currentSiteUser = _a.currentSiteUser;
            var _b = appSettings.numbers, videosInRow = _b.videosInRow, numberOfRows = _b.numberOfRows;
            var noVideosLoaded = !videoIdsByPageNumber.length;
            if ((isVideosFetching && noVideosLoaded) || !mainVideo) {
                return null;
            }
            return (React.createElement(Videos, { styleName: "videos", containerWidth: this.playerSize.width, videoIdsByPageNumber: videoIdsByPageNumber, currentVideosPageNumber: currentVideosPageNumber, onSlideToNext: this.handleSlideToNext, onSlideToPrev: this.handleSlideToPrev, channelData: channelData, currentSiteUser: currentSiteUser, onPlayRequest: this.playVideo, onThumbnailClick: this.handleThumbnailClick, thumbnailSize: this.listThumbnailSize, numberOfRows: numberOfRows, numberOfColumns: videosInRow }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "closeVideosButton", {
        get: function () {
            var _a = this.props, appSettings = _a.appSettings, isVideoListAlwaysShow = _a.isVideoListAlwaysShow;
            var _b = appSettings.booleans, showChannelShare = _b.showChannelShare, showChannelInfo = _b.showChannelInfo, showSignIn = _b.showSignIn;
            var isAllActionsDisabled = !showChannelShare &&
                !showChannelInfo &&
                !this.canSubscribe &&
                !showSignIn;
            if (!this.shouldRenderVideos || isVideoListAlwaysShow) {
                return null;
            }
            return (React.createElement(LinkButton, { className: styles.action, isFocusable: this.isChannelActionsFocusable(), onClick: this.handleUserToggleVisibilityAllVideos, dataHook: "close-action-bar", ariaLabel: i18n.t('widget.accessibility.close-video-list') },
                isAllActionsDisabled && i18n.t('widget.strip-view.close'),
                React.createElement(Icon, { className: styles[isAllActionsDisabled ? 'close-icon-with-label' : 'close-icon'], name: "add" })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "categories", {
        get: function () {
            var _a;
            var _b = this.props, isCategoriesDropdownVisible = _b.isCategoriesDropdownVisible, appSettings = _b.appSettings, searchQuery = _b.searchQuery, isVideoListNeverShow = _b.isVideoListNeverShow;
            var _c = this.state, isSearchBarExpanded = _c.isSearchBarExpanded, currentCategory = _c.currentCategory, searchBarValue = _c.searchBarValue;
            var showChannelCategories = appSettings.booleans.showChannelCategories;
            if (!isCategoriesDropdownVisible ||
                isVideoListNeverShow ||
                !showChannelCategories) {
                return null;
            }
            var isHidden = isSearchBarExpanded ||
                (!currentCategory && (searchQuery || searchBarValue));
            var className = classnames(styles['categories-container'], (_a = {},
                _a[styles.hidden] = isHidden,
                _a));
            return (
            // wrapper needed for focusing when switching from search bar to categories by tab
            React.createElement("div", { className: className },
                React.createElement(Categories, { className: styles.categories, DropdownClass: StripWidgetDropdown, isFocusable: this.isChannelActionsFocusable(), height: SEARCH_LINE_HEIGHT, list: this.categoriesList, onCategorySelect: this.handleCategorySelect, selectedCategory: currentCategory })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "canSubscribe", {
        get: function () {
            var channelData = this.props.channelData;
            var subscriptionPriceInfo = dealInfoHelpers.getSubscriptionPriceInfo(channelData.dealInfo);
            return !_.get(channelData, 'dgsInfo.subscription') && subscriptionPriceInfo;
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.isChannelActionsFocusable = function () {
        return !this.props.searchQuery || this.state.currentCategory;
    };
    Object.defineProperty(StripView.prototype, "signInButton", {
        get: function () {
            var _this = this;
            var _a = this.props, isSignInVisible = _a.isSignInVisible, channelData = _a.channelData;
            if (!isSignInVisible) {
                return null;
            }
            return (React.createElement(ChannelActionsContainer, { channelId: channelData.id, onPageRefresh: this.storeDataForReload }, function (channelActions) { return (React.createElement(AccountButton, { className: classnames('qa-widget-channel-account', styles.action), infoButtonClassName: styles['account-button'], isFocusable: _this.isChannelActionsFocusable(), channelData: channelData, onClick: channelActions.showAccountInfo, onLoginClick: channelActions.logIn, onLogoutClick: channelActions.logOut, onSubscribeClick: channelActions.subscribe })); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "channelActions", {
        get: function () {
            var _a;
            var _this = this;
            var _b = this.props, channelData = _b.channelData, searchQuery = _b.searchQuery, mainVideoId = _b.mainVideoId, isChannelShareVisible = _b.isChannelShareVisible, isChannelInfoVisible = _b.isChannelInfoVisible, isSignInVisible = _b.isSignInVisible;
            var currentCategory = this.state.currentCategory;
            var closeVideoButton = this.closeVideosButton;
            if (!isChannelShareVisible &&
                !isChannelInfoVisible &&
                !isSubscriptionButtonVisible({ channel: channelData }) &&
                !isSignInVisible &&
                !closeVideoButton) {
                return null;
            }
            var _c = this.state, isSearchBarExpanded = _c.isSearchBarExpanded, searchBarValue = _c.searchBarValue;
            var submittedSearchValue = currentCategory ? '' : searchQuery;
            var isHidden = isSearchBarExpanded || submittedSearchValue || searchBarValue;
            var className = classnames(styles['channel-actions'], (_a = {},
                _a[styles.hidden] = isHidden,
                _a));
            var isFocusable = this.isChannelActionsFocusable();
            return (React.createElement(ChannelActionsContainer, { channelId: channelData.id, videoId: mainVideoId, onPageRefresh: this.storeDataForReload }, function (channelActions) { return (React.createElement("div", { className: className, "data-hook": "channel-actions" },
                isChannelShareVisible && (React.createElement(ShareButton, { className: classnames(styles.action, styles['action-share']), ariaLabel: i18n.t('widget.accessibility.share'), tabIndex: isFocusable ? 0 : -1, onClick: channelActions.showShare })),
                React.createElement(ChannelInfoButton, { className: styles.action, channelData: channelData, isFocusable: isFocusable, onClick: _this.showChannelInfo }),
                React.createElement(ChannelSubscriptionButton, { className: styles.action, channelData: channelData, isFocusable: isFocusable, onClick: channelActions.subscribe }),
                _this.signInButton,
                closeVideoButton)); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "searchBar", {
        get: function () {
            var _a;
            var _b = this.props, isSearchInputVisible = _b.isSearchInputVisible, appSettings = _b.appSettings, searchQuery = _b.searchQuery, isVideoListNeverShow = _b.isVideoListNeverShow;
            var currentCategory = this.state.currentCategory;
            if (!isSearchInputVisible) {
                return null;
            }
            var _c = this.state, isSearchBarExpanded = _c.isSearchBarExpanded, searchBarValue = _c.searchBarValue;
            var value = currentCategory ? '' : searchQuery;
            var isExpanded = isSearchBarExpanded || value || searchBarValue;
            var booleans = appSettings.booleans;
            var showSearch = booleans.showSearch;
            if (isVideoListNeverShow || !showSearch) {
                return null;
            }
            return (React.createElement(SearchBar, { layout: "strip", className: classnames('qa-widget-searchbar', styles.search, (_a = {},
                    _a[styles.expanded] = isExpanded,
                    _a)), value: value, onFocus: this.handleSearchInputFocus, onBlur: this.handleSearchInputBlur, onChange: this.handleSearchInputChange, onClose: this.clearSearch, onSearch: this.searchByQuery }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "actionsLine", {
        get: function () {
            var _a = this.props, isVideoPlaying = _a.isVideoPlaying, isSingleVideo = _a.isSingleVideo, isVideoListNeverShow = _a.isVideoListNeverShow, isPortableDevice = _a.isPortableDevice;
            if (isSingleVideo) {
                return this.renderSingleVideoActionsLine();
            }
            var searchBar = this.searchBar;
            var categories = this.categories;
            var channelActions = this.channelActions;
            if ((!this.shouldRenderVideos && !isVideoListNeverShow) ||
                (isPortableDevice && isVideoPlaying) ||
                (!searchBar && !categories && !channelActions)) {
                return null;
            }
            return (React.createElement("div", { className: styles['search-line'], "data-hook": "widget-search-line" },
                channelActions,
                searchBar,
                categories));
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.renderSingleVideoActionsLine = function () {
        if (!this.props.isSignInVisible) {
            return null;
        }
        return (React.createElement("div", { className: styles['search-line'], "data-hook": "single-video-actions-line" },
            React.createElement("div", { className: styles['channel-actions'], "data-hook": "single-video-actions" }, this.signInButton)));
    };
    Object.defineProperty(StripView.prototype, "allVideosButton", {
        get: function () {
            var _a = this.props, isVideoPlaying = _a.isVideoPlaying, isPortableDevice = _a.isPortableDevice;
            if (!this.shouldRenderAllVideosButton ||
                (isPortableDevice && isVideoPlaying)) {
                return null;
            }
            return (React.createElement("div", { styleName: "all-videos" },
                React.createElement(Button, { styleName: "button", disableTheme: true, dataHook: "all-videos-button", onClick: this.handleUserToggleVisibilityAllVideos },
                    i18n.t('widget.strip-view.all-videos'),
                    React.createElement(Icon, { name: "add", styleName: "icon" }))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "videos", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, isVideosFetching = _a.isVideosFetching, videoIdsByPageNumber = _a.videoIdsByPageNumber, searchQuery = _a.searchQuery, currentVideosPageNumber = _a.currentVideosPageNumber, isSingleVideo = _a.isSingleVideo, isSearchResultEmpty = _a.isSearchResultEmpty;
            if (!channelData || !this.shouldRenderVideos || isSingleVideo) {
                return null;
            }
            var videosContainerProps = {
                channelTitle: channelData.title,
                isChannelHasVideos: Boolean(channelData.videosCount),
                dataHook: 'video-list',
                isVideosFetching: isVideosFetching,
                videoIdsByPageNumber: videoIdsByPageNumber,
                searchQuery: searchQuery,
                currentVideosPageNumber: currentVideosPageNumber,
            };
            if (!channelData.videosCount) {
                return (React.createElement(AccessibleVideosContainer, __assign({}, videosContainerProps, { className: styles['coming-soon'], style: { minHeight: this.listThumbnailSize.height }, onRef: memoizedPartial(this.saveRef, 'videosContainerRef') }), i18n.t('widget.this-channel-is-coming-soon')));
            }
            return (React.createElement(AccessibleVideosContainer, __assign({}, videosContainerProps, { className: styles['videos-container'], style: { height: this.videosListHeight }, onRef: memoizedPartial(this.saveRef, 'videosContainerRef') }), isSearchResultEmpty ? this.noSearchResults : this.videosList));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "playerSize", {
        get: function () {
            var width = this.props.windowSize.width;
            if (!width) {
                return {};
            }
            return {
                width: Math.max(MAX_WIDGET_WIDTH, width),
                height: Math.round(Math.max(MAX_WIDGET_WIDTH, width) / 2.39),
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripView.prototype, "hasBottomContent", {
        get: function () {
            // TODO move hasBottomContent to selector|method
            var allVideosButton = this.allVideosButton;
            var searchLine = this.actionsLine;
            return Boolean(allVideosButton || searchLine);
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.renderNoVideoPlayerOverlay = function () {
        var _a;
        var _b = this.props, appSettings = _b.appSettings, channelData = _b.channelData;
        var hasBottomContent = this.hasBottomContent;
        var buttonsWrapperClassName = classnames((_a = {},
            _a[styles['buttons-wrapper-no-margin']] = !hasBottomContent,
            _a));
        var props = {
            appSettings: appSettings,
            channelData: channelData,
            buttonsWrapperClassName: buttonsWrapperClassName,
            styleName: 'player-overlay',
            showChannelCover: true,
            areAllVideosVisible: false,
            videoItem: {},
            onPlaySelectedVideo: _.noop,
        };
        return React.createElement(OverlayStrip, __assign({}, props));
    };
    Object.defineProperty(StripView.prototype, "playerOverlayNav", {
        get: function () {
            var _a = this.props, hasNextVideo = _a.hasNextVideo, hasPrevVideo = _a.hasPrevVideo, isRTL = _a.isRTL;
            return (React.createElement(Nav, { prevButtonClassName: styles['prev-button'], nextButtonClassName: styles['next-button'], prevButtonAriaLabel: i18n.t('widget.accessibility.prev-video'), nextButtonAriaLabel: i18n.t('widget.accessibility.next-video'), onPrevClick: this.selectPrevVideo, onNextClick: this.selectNextVideo, isPrevVisible: hasPrevVideo, isNextVisible: hasNextVideo, isRTL: isRTL }));
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.getCurrentlyPlayingVideo = function () {
        var _a = this.props, videoByIds = _a.videoByIds, mainVideoId = _a.mainVideoId, isVideoPlaying = _a.isVideoPlaying;
        return isVideoPlaying ? _.get(videoByIds, mainVideoId, null) : null;
    };
    StripView.prototype.renderPlayerOverlay = function () {
        var _a;
        var _b = this.props, appSettings = _b.appSettings, channelData = _b.channelData, currentSiteUser = _b.currentSiteUser, mainVideo = _b.mainVideo, canShowChannelCover = _b.canShowChannelCover;
        if (this.getCurrentlyPlayingVideo() &&
            isExternalVideo(this.getCurrentlyPlayingVideo())) {
            return null;
        }
        var hasBottomContent = this.hasBottomContent;
        var buttonsWrapperClassName = classnames((_a = {},
            _a[styles['buttons-wrapper-no-margin']] = !hasBottomContent,
            _a));
        return (React.createElement("section", { className: styles['player-overlay-container'], ref: memoizedPartial(this.saveRef, 'playerOverlayContainerRef') },
            React.createElement(OverlayStrip, { styleName: "player-overlay", buttonsWrapperClassName: buttonsWrapperClassName, currentSiteUser: currentSiteUser, channelData: channelData, appSettings: appSettings, videoItem: mainVideo, showChannelCover: canShowChannelCover, onPlaySelectedVideo: this.playVideo, areAllVideosVisible: this.shouldRenderVideos, toggleVisibilityAllVideos: this.handleUserToggleVisibilityAllVideos, showChannelInfo: this.showChannelInfo })));
    };
    Object.defineProperty(StripView.prototype, "player", {
        get: function () {
            var _this = this;
            var _a = this.props, PlayerComponent = _a.PlayerComponent, isPortableDevice = _a.isPortableDevice;
            if (!PlayerComponent) {
                return null;
            }
            var _b = this.playerSize, width = _b.width, height = _b.height;
            var _c = this.props, channelData = _c.channelData, isVideoPlaying = _c.isVideoPlaying, mainVideo = _c.mainVideo, canShowChannelCover = _c.canShowChannelCover;
            var playerProps = {
                videoItem: mainVideo,
                channelData: channelData,
                width: width,
                height: height,
                canShowChannelCover: canShowChannelCover,
                styleName: 'player',
                className: 'qa-widget-player',
                onMemberSignUp: this.openMembership,
            };
            if (isPortableDevice) {
                return (React.createElement(PlayerBlocksVisibility, null, function (_a) {
                    var canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton;
                    return (React.createElement(PlayerComponent, __assign({}, playerProps, { paused: !isVideoPlaying, canShowFullInfo: canShowVideoInfoButton, canShareVideo: canShowShareButton })));
                }));
            }
            return (React.createElement(PlayerBlocksVisibility, null, function (_a) {
                var canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton;
                return (React.createElement(StripPlayerWrapper, __assign({}, playerProps, { PlayerComponent: PlayerComponent, onMemberSignUp: _this.openMembership, canShowFullInfo: canShowVideoInfoButton, canShareVideo: canShowShareButton })));
            }));
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.renderNoVideosPlayerContent = function () {
        var _a = this.props, channelData = _a.channelData, isMainItemChannelCover = _a.isMainItemChannelCover;
        var _b = this.playerSize, width = _b.width, height = _b.height;
        return (React.createElement(NoVideosContent, { channelCoverUrl: getChannelCoverUrl(channelData), width: width, height: height, isMainItemChannelCover: isMainItemChannelCover }, this.renderNoVideoPlayerOverlay()));
    };
    Object.defineProperty(StripView.prototype, "playerContainer", {
        get: function () {
            var mainVideo = this.props.mainVideo;
            if (!mainVideo) {
                return (React.createElement("div", { styleName: "player-container" },
                    this.renderNoVideosPlayerContent(),
                    this.allVideosButton,
                    this.actionsLine));
            }
            var hasBottomContent = this.hasBottomContent;
            return (React.createElement("div", { styleName: classnames('player-container', {
                    'with-bottom-padding': hasBottomContent,
                }), "data-channel-layout": "strip" },
                React.createElement("div", { className: styles.player },
                    this.player,
                    this.renderPlayerOverlay(),
                    this.playerOverlayNav,
                    this.allVideosButton,
                    this.actionsLine),
                React.createElement(DeeplinkPopups, null)));
        },
        enumerable: true,
        configurable: true
    });
    StripView.prototype.render = function () {
        var _a;
        var _b = this.props, channelData = _b.channelData, isVideoPlaying = _b.isVideoPlaying, selectedVideoId = _b.selectedVideoId, isSingleVideo = _b.isSingleVideo;
        return (React.createElement("section", { className: classnames(styles.container, (_a = {},
                _a[styles['with-videos']] = this.shouldRenderVideos,
                _a[styles['single-video']] = isSingleVideo,
                _a)), ref: memoizedPartial(this.saveRef, 'containerRef'), "data-channel-layout": "strip", "data-hook": "widget-container", "aria-label": i18n.t('widget.accessibility.channel-videos-widget', {
                channelTitle: channelData.title,
            }), tabIndex: "0" },
            this.playerContainer,
            this.videos,
            React.createElement(PaymentEvents, { onRent: this.setCurrentVideoFromPayment, onSale: this.setCurrentVideoFromPayment, onSubscription: this.reset, onSubscriptionCancel: this.reset }),
            React.createElement(LiveStartHandler, { playVideo: this.playVideo, isVideoPlaying: isVideoPlaying, selectedVideoId: selectedVideoId }),
            React.createElement(WidgetPerformanceLoggers, null)));
    };
    StripView.displayName = 'StripView';
    StripView.propTypes = {
        currentSiteUser: PropTypes.object,
        currentChannelId: PropTypes.string,
        mainVideoId: PropTypes.string,
        mainVideo: PropTypes.object,
        isVideosFetching: PropTypes.bool,
        selectVideo: PropTypes.func.isRequired,
        canShowChannelCover: PropTypes.bool.isRequired,
        nextVideoId: PropTypes.string,
        channelData: PropTypes.object.isRequired,
        videoByIds: PropTypes.object.isRequired,
        videoIdsByPageNumber: PropTypes.array,
        appSettings: PropTypes.object.isRequired,
        windowSize: PropTypes.object.isRequired,
        searchQuery: PropTypes.string,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        isSearchInputVisible: PropTypes.bool,
        isChannelShareVisible: PropTypes.bool.isRequired,
        isCategoriesDropdownVisible: PropTypes.bool,
        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isChannelInfoVisible: PropTypes.bool.isRequired,
        isSingleVideo: PropTypes.bool,
        isVideoShareVisible: PropTypes.bool,
        goToLazyVideosPageIndex: PropTypes.func.isRequired,
        lazyLoadedVideoIds: PropTypes.array.isRequired,
        currentIdIndex: PropTypes.number.isRequired,
        hasPrevVideo: PropTypes.bool.isRequired,
        hasNextVideo: PropTypes.bool.isRequired,
        searchByString: PropTypes.func.isRequired,
        searchByCategory: PropTypes.func.isRequired,
        isSearchResultEmpty: PropTypes.bool.isRequired,
        isMainItemChannelCover: PropTypes.bool.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        selectedVideoId: PropTypes.string,
        isVideoPlaying: PropTypes.bool.isRequired,
        currentVideosPageNumber: PropTypes.number.isRequired,
        isSignInVisible: PropTypes.bool.isRequired,
        resetSelectedVideo: PropTypes.func.isRequired,
        isVideoListAlwaysShow: PropTypes.bool.isRequired,
        isVideoListExpandOnClick: PropTypes.bool.isRequired,
        isVideoListNeverShow: PropTypes.bool.isRequired,
        setWidgetHeight: PropTypes.func.isRequired,
        playSelectedVideo: PropTypes.func.isRequired,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
        isRTL: PropTypes.bool,
        isEditor: PropTypes.bool.isRequired,
    };
    StripView = __decorate([
        connect(function (state) { return ({
            windowSize: state.windowSize,
            mainVideoId: getMainVideoId(state),
            mainVideo: getMainVideo(state),
            selectedVideoId: state.selectedVideoId,
            isVideoPlaying: isVideoPlayingOptimistic(state),
            currentChannelId: getCurrentChannelId(state),
            canShowChannelCover: !state.isVideosTouched,
            appSettings: getAllSettings(state),
            isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
            firstChannelVideoId: state.firstChannelVideoId,
            isVideoShareVisible: canShowExpandedVideoShare(state),
            videoIdsByPageNumber: getVideoIdsByPageNumber(state),
            currentVideosPageNumber: getCurrentPageIndex(state),
            lazyLoadedVideoIds: getVideoIds(state),
            videoByIds: getVideosGroupedByIds(state),
            isVideosFetching: getIsFetching(state),
            currentSiteUser: getCurrentSiteUser(state),
            currentIdIndex: getCurrentVideoIndex(state),
            hasPrevVideo: hasPrevVideo(state),
            hasNextVideo: hasNextVideo(state),
            isSignInVisible: getCanShowSignIn(state),
            isSearchInputVisible: isSearchInputVisible(state),
            isCategoriesDropdownVisible: isCategoriesDropdownVisible(state),
            isChannelInfoVisible: getCanShowChannelInfo(state),
            isChannelShareVisible: canShowChannelShare(state),
            searchQuery: getSearchQuery(state),
            channelData: getChannelForWidget(state),
            isSingleVideo: isSingleVideo(state),
            isSearchResultEmpty: getIsSearchResultEmpty(state),
            isMainItemChannelCover: isMainItemChannelCover(state),
            isVideoListAlwaysShow: isVideoListAlwaysShow(state),
            isVideoListExpandOnClick: isVideoListExpandOnClick(state),
            isVideoListNeverShow: isVideoListNeverShow(state),
            isRTL: isRTL(state),
            isEditor: viewModeSelectors.isEditorMode(state),
        }); }, {
            selectVideo: selectVideo,
            resetSelectedVideo: resetSelectedVideo,
            requestPlayVideo: requestPlayVideo,
            searchByString: searchByString,
            searchByCategory: searchByCategory,
            goToLazyVideosPageIndex: goToLazyVideosPageIndex,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
            playSelectedVideo: playSelectedVideo,
            logWidgetSystem: logWidgetSystem,
            storeForReload: storeForReload,
            sendBiEvent: sendBiEvent,
            openFullScreenMemberOnlyOverlay: openFullScreenMemberOnlyOverlay,
            setWidgetHeight: setWidgetHeight,
            openFullScreenChannelOverlay: openFullScreenChannelOverlay,
        }),
        withStyles(styles),
        VODCSSModules(styles)
    ], StripView);
    return StripView;
}(React.Component));
export { StripView };
export default withPlayerModuleLoader(StripView);
