import { __assign, __read, __spread } from "tslib";
import { noop, get } from 'lodash';
import { WixSDK as _BaseSDK } from '@wix/wix-vod-shared/dist/src/common/wix-sdk';
import { getWidgetSettingsByInstanceId } from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import isDefaultADIChannel from '@wix/wix-vod-shared/dist/src/common/utils/is-default-adi-channel';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { getChannelId } from 'shared/utils/app-settings';
var WixSDKOriginal = global.Wix;
var BaseSDK = _BaseSDK || {
    addEventListener: noop,
    removeEventListener: noop,
    Events: {},
    Utils: {},
    PubSub: { subscribe: noop, unsubscribe: noop },
    Performance: {
        applicationLoadingStep: noop,
        applicationLoaded: noop,
    },
};
var wrapWithCache = function (fn) {
    var cache = null;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (cache) {
            return Promise.resolve(cache);
        }
        return fn.apply(void 0, __spread(args)).then(function (response) {
            // once channel id is received it should not be changed
            cache = response;
            return cache;
        });
    };
};
var getChannelIdFromServer = wrapWithCache(function (_a) {
    var instanceId = _a.instanceId;
    return getWidgetSettingsByInstanceId(instanceId)
        .then(function (data) { return data.items[0]; })
        .then(function (firstWidget) { return get(firstWidget, 'settings.listId'); })
        .catch(function () { return Promise.resolve(null); });
});
var WixSDK = __assign(__assign({}, BaseSDK), { resizeComponent: function (_a, onSuccess) {
        var _b = _a.width, width = _b === void 0 ? 0 : _b, _c = _a.height, height = _c === void 0 ? 0 : _c;
        if (onSuccess === void 0) { onSuccess = noop; }
        // Either width or height is mandatory
        if (width > 0 || height > 0) {
            WixSDKOriginal.resizeComponent({ width: width, height: height }, onSuccess);
        }
    }, Utils: __assign(__assign({}, BaseSDK.Utils), { getViewMode: function () {
            var viewMode = WixSDKOriginal.Utils.getViewMode();
            return viewMode === VIEW_MODES.STANDALONE ? VIEW_MODES.SITE : viewMode;
        } }), Styles: __assign(__assign({}, BaseSDK.Styles), { _getInitialChannelId: function () {
            var originInstanceId = WixSDK.Utils.getInstanceValue('originInstanceId');
            // handle case with multiple categories in ADI
            // for now they are taken from deeplinks service
            if (originInstanceId) {
                return getChannelIdFromServer({
                    instanceId: originInstanceId,
                });
            }
            return WixSDK.Data.Public.get('channelId', {
                scope: 'COMPONENT',
            }).then(function (_a) {
                var channelId = _a.channelId;
                return channelId;
            });
        },
        /**
         * This is tmp workaround to allow retrieve channel id in ADI while switching between different design presets
         * @param params
         * @returns {*}
         * @private
         */
        extendStylesWithInitialChannelId: function (params) {
            var appSettingsChannelId = getChannelId(params);
            if (!isDefaultADIChannel(appSettingsChannelId)) {
                return Promise.resolve(params);
            }
            return this._getInitialChannelId()
                .then(function (channelId) {
                return __assign(__assign({}, params), { fonts: __assign(__assign({}, params.fonts), { channelId: channelId || appSettingsChannelId }) });
            })
                .catch(function () { return params; });
        },
        getStyleParams: function () {
            return new Promise(WixSDKOriginal.Styles.getStyleParams).then(function (params) {
                return WixSDK.Styles.extendStylesWithInitialChannelId(params);
            });
        } }) });
export default WixSDK;
