import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
import styles from './button.styl';
var GenericButton = /** @class */ (function (_super) {
    __extends(GenericButton, _super);
    function GenericButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleLinkClick = function (event) {
            event.preventDefault();
            _this.props.onClick(event);
        };
        return _this;
    }
    GenericButton.prototype.render = function () {
        var _a;
        var _b = this.props, label = _b.label, ariaLabel = _b.ariaLabel, children = _b.children, roundedRect = _b.roundedRect, getRef = _b.getRef, renderAsLink = _b.renderAsLink, dataHook = _b.dataHook, isFocusable = _b.isFocusable, className = _b.className;
        var styleNames = classnames(className, styles.button, (_a = {},
            _a[styles['rounded-rect']] = roundedRect,
            _a));
        var props = _({})
            .assign(this.props)
            .assign({
            children: label || children,
            className: styleNames,
            ref: getRef,
            'aria-label': ariaLabel,
            tabIndex: isFocusable ? 0 : -1,
            'data-hook': dataHook,
        })
            .omit([
            'ariaLabel',
            'styles',
            'label',
            'roundedRect',
            'renderAsLink',
            'getRef',
            'intl',
            'settings',
            'biEvent',
            'biError',
            'dataHook',
            'isFocusable',
        ])
            .value();
        if (!renderAsLink) {
            return React.createElement("button", __assign({}, props));
        }
        return React.createElement("a", __assign({ href: "" }, props, { onClick: this.handleLinkClick }));
    };
    GenericButton.propTypes = {
        label: PropTypes.any,
        ariaLabel: PropTypes.string,
        children: PropTypes.any,
        roundedRect: PropTypes.bool,
        renderAsLink: PropTypes.bool,
        onClick: PropTypes.func,
        getRef: PropTypes.func,
        dataHook: PropTypes.string,
        isFocusable: PropTypes.bool,
        className: PropTypes.string,
    };
    GenericButton.defaultProps = {
        isFocusable: true,
        roundedRect: false,
        renderAsLink: false,
        onClick: _.noop,
        getRef: _.noop,
    };
    GenericButton = __decorate([
        withStyles(styles)
    ], GenericButton);
    return GenericButton;
}(React.Component));
export { GenericButton };
function generateButtonVariant(styleName) {
    var className = styleName.split(' ').map(function (name) { return styles[name]; });
    var ButtonVariant = /** @class */ (function (_super) {
        __extends(ButtonVariant, _super);
        function ButtonVariant() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        ButtonVariant.prototype.render = function () {
            var props = _.omit(this.props, 'styles');
            return (React.createElement(GenericButton, __assign({}, props, { className: classnames(props.className, className) })));
        };
        ButtonVariant.displayName = _.flow(_.camelCase, _.upperFirst)(styleName);
        return ButtonVariant;
    }(React.PureComponent));
    return ButtonVariant;
}
export var LinkButton = generateButtonVariant('link-button');
export var PrimaryButton = generateButtonVariant('primary-button');
export var SecondaryButton = generateButtonVariant('secondary-button');
